import React, { useContext } from "react";

import { styled } from "@mui/material";
import Badge from "@mui/material/Badge";
import { Avatar } from "@mui/material";

/* Icons */
import { MdKeyboardArrowDown } from "react-icons/md";

// Context API
import { myAccountContext } from "../../store/MyAccountInfoProvider";

// css styles
import styles from "../ProfileMenu/ProfileMenu.module.css";

// Style dot active on avatar image
const StyledBadge = styled(Badge)(({ theme }) => ({
	"& .MuiBadge-badge": {
		backgroundColor: "#44b700",
		color: "#44b700",
		boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
		"&::after": {
			position: "absolute",
			top: 0,
			left: 0,
			width: "100%",
			height: "100%",
			borderRadius: "50%",
			animation: "ripple 1.2s infinite ease-in-out",
			border: "1px solid currentColor",
			content: '""',
		},
	},
	"@keyframes ripple": {
		"0%": {
			transform: "scale(.8)",
			opacity: 1,
		},
		"100%": {
			transform: "scale(2.4)",
			opacity: 0,
		},
	},
}));

const UserBadge = ({ closeMenu }) => {
	// get profileData to context api
	const myAccountDataStore = useContext(myAccountContext);
	const { myAccountInfo } = myAccountDataStore;

	return (
		<div
			className={`flex items-center gap-2 cursor-pointer ${styles.user}`}
			style={{ color: "#1DBBBE" }}
			onClick={closeMenu}>
			<StyledBadge
				overlap='circular'
				anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
				variant='dot'>
				<Avatar
					alt='profile-icon'
					src={myAccountInfo?.users.image}
					style={{
						minWidth: "35px",
						maxWidth: "35px",
						minHeight: "35px",
						maxHeight: "35px",
						borderRadius: "50%",
						padding: "5px",
						border: "1px solid #dddddd",
						backgroundColor: "#ffffff",
					}}
					loading='lazy'
				/>
			</StyledBadge>

			<h2 className='hidden md:flex pr-2' style={{ color: "#ffffff" }}>
				{myAccountInfo?.users.name}
			</h2>
			<MdKeyboardArrowDown className='hidden text-xl md:flex' />
		</div>
	);
};

export default UserBadge;
