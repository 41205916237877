import React from "react";
import Checkbox from "@mui/material/Checkbox";
import { CheckedSquare, Delete } from "../../assets/Icons";
import Button from "../../UI/Button/Button";
import VisibilityIcon from "@mui/icons-material/Visibility";

const ReviewsItems = ({
	isItemSelected,
	handleClick,
	box,
	PlaceholderImage,
	permissions,
	publish,
	setShowDetails,
	setIdDetails,
	setActionDelete,
	setItemId,
}) => {
	return (
		<div
			style={{ boxShadow: "3px 3px 6px #00000005" }}
			className='flex flex-col flex-wrap items-start justify-between w-full gap-4 px-4 py-5 bg-white lg:flex-row lg:items-center lg:gap-2'>
			<div className='flex flex-row items-center flex-1 gap-4 md:gap-8'>
				<Checkbox
					checkedIcon={<CheckedSquare />}
					sx={{
						color: "#1DBBBE",
						"& .MuiSvgIcon-root": {
							color: "#ADB5B9",
						},
					}}
					checked={isItemSelected}
					onClick={(event) => handleClick(event, box?.id)}
				/>
				<div className='w-full flex flex-row items-center gap-[20px]'>
					<div className='rounded-full border-[2px] border-[#dddddd] w-14 h-14 p-2 flex items-center justify-center'>
						<img
							className='w-100 h-100'
							src={box?.store?.logo ? box?.store?.logo : PlaceholderImage}
							alt=''
							loading='lazy'
							style={{
								width: "100%",
								height: "100%",
								objectFit: "cover",
								borderRadius: "50%",
							}}
						/>
					</div>

					<p
						className='md:text-[18px] text-[16px]'
						style={{ color: "#011723" }}>
						{box?.store?.store_name}
					</p>
				</div>
			</div>
			<div className='lg:w-auto w-full flex flex-row items-center sm:gap-[20px] gap-[10px]'>
				{permissions?.includes("admin.comment.changeSatusall") && (
					<Button
						style={{ backgroundColor: "#02466A" }}
						textStyle={{ color: "#EFF9FF" }}
						className={
							"sm:min-w-[145px] min-w-[110px] h-[48px] sm:text-[1rem] text-[0.9rem] flex-1"
						}
						type={"normal"}
						btnType={"button"}
						onClick={() => publish(box?.id)}
						disabled={box?.status === "نشط"}>
						نشر التقييم
					</Button>
				)}
				{permissions?.includes("admin.comment.show") && (
					<Button
						style={{ borderColor: "#02466A" }}
						textStyle={{ color: "#02466A" }}
						className={
							"sm:min-w-[145px] min-w-[110px] h-[48px] sm:text-[1rem] text-[0.9rem] flex-1"
						}
						type={"outline"}
						svg={
							<VisibilityIcon
								style={{
									width: "20px",
									height: "20px",
									color: "#02466A",
								}}
							/>
						}
						btnType={"button"}
						onClick={() => {
							setShowDetails(true);
							setIdDetails(box?.id);
						}}>
						معاينة
					</Button>
				)}
				{permissions?.includes("admin.comment.deleteall") && (
					<Delete
						title='حذف'
						className='cursor-pointer'
						onClick={() => {
							setActionDelete("سيتم حذف التقييم وهذة الخطوة غير قابلة للرجوع");
							setItemId(box?.id);
						}}
					/>
				)}
			</div>
		</div>
	);
};

export default ReviewsItems;
