import React, { useEffect } from "react";

const inputStyles = {
	backgroundColor: "#fff",
	border: "1px solid rgb(236, 236, 236)",
	color: "#000",
};

const PartitionNameInput = ({
	currPartition,
	onChangeTab,
	sectionCategoriesSelect,
}) => {
	const [name, setName] = React.useState("");

	useEffect(() => {
		if (currPartition?.category_id) {
			setName(
				sectionCategoriesSelect?.find((sc) => {
					return sc.id === currPartition?.category_id;
				})?.name
			);
		} else {
			setName(currPartition?.name);
		}
	}, [currPartition?.name, currPartition?.category_id]); // Added category_id to dependencies

	return (
		<div className='w-full mb-5'>
			<h2 className='mb-1 text-lg font-normal' style={{ color: "#000" }}>
				اسم القسم{" "}
				{currPartition?.category_id && (
					<span className='text-[#4875c2] text-base'>
						(سيصبح اسم القسم هو نفس اسم النشاط بشكل تلقائي)
					</span>
				)}
			</h2>

			<label>
				<input
					type='text'
					readOnly={currPartition?.category_id}
					disabled={currPartition?.category_id}
					name='name'
					value={name}
					onChange={(e) =>
						onChangeTab({
							...currPartition,
							name: e.target.value,
						})
					}
					className='w-full px-3 h-14 rounded outline-none disabled:text-[#000] disabled:bg-gray-100 disabled:border-gray-200'
					placeholder='ادخل اسم القسم'
					style={inputStyles}
				/>
			</label>
		</div>
	);
};

export default PartitionNameInput;
