import React, { useState, useContext, useEffect } from "react";

/* Third party */
import { Helmet } from "react-helmet";

/* Context */
import { RolePermissionsContext } from "../../store/RolePermissionsProvider";

/* Components */
import PackagesHead from "../../components/PackagesPageComp/PackagesHead/PackagesHead";
import PackagesTypes from "../../components/PackagesPageComp/PackagesTypes/PackagesTypes";

// RTK query
import { useGetPackageQuery } from "../../RTK/apiSlices/subscriptionsApi";

const Packages = () => {
	const [page, setPage] = useState(1);
	const [packages, setPackages] = useState([]);
	const [rowsPerPage, setRowsPerPage] = useState(6);
	const [periodType, setPeriodType] = useState("");

	// get permissions
	const RolePermissionsStore = useContext(RolePermissionsContext);
	const { permissions } = RolePermissionsStore;

	// get all packages
	const { data: fetchedData, isLoading: loading } = useGetPackageQuery({
		page,
		number: rowsPerPage,
	});

	// handle filter on package based on period type
	const handlePackageFiltering = () => {
		if (periodType !== "") {
			const filteredData = fetchedData?.packages?.filter(
				(item) => item?.periodtype === periodType
			);
			setPackages(filteredData);
		} else {
			setPackages(fetchedData?.packages);
		}
	};

	useEffect(() => {
		if (fetchedData) {
			setPackages(fetchedData?.packages);
		}
	}, [fetchedData]);

	// Call this when periodType changes
	useEffect(() => {
		if (periodType) {
			handlePackageFiltering();
		}
	}, [periodType]);

	return (
		<>
			<Helmet>
				<title>لوحة تحكم أطلبها | الباقات</title>
			</Helmet>
			<div className='p-4 relative md:bg-[#fafafa] bg-[#ffffff]'>
				<PackagesHead
					permissions={permissions}
					periodType={periodType}
					setPeriodType={setPeriodType}
				/>

				<PackagesTypes
					loading={loading}
					pageNumber={page}
					setPageNumber={setPage}
					currentPage={rowsPerPage}
					setCurrentPage={setRowsPerPage}
					page_count={fetchedData?.page_count}
					fetchedData={packages || []}
				/>
			</div>
		</>
	);
};

export default Packages;
