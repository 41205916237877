import React, { useState, useContext } from "react";

/* Third party */
import { toast } from "react-toastify";
import Stack from "@mui/material/Stack";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker as DateRange } from "antd";

/* Context */
import { LoadingContext } from "../../../store/LoadingProvider";

/* Custom Button */
import Button from "../../../UI/Button/Button";

/* Icons */
import { IoMdCloseCircleOutline } from "react-icons/io";

// RTK Query
import { useUpdateSubscriptionsAllMutation } from "../../../RTK/apiSlices/subscriptionsApi";
import { DataIcon } from "../../../assets/Icons";

const BackDrop = ({ onClick }) => {
	return (
		<div
			onClick={onClick}
			className='fixed top-0 left-0 z-10 w-full h-full opacity-50 back_drop bg-slate-900'></div>
	);
};

const UpdateSubscriptionsDate = ({ cancel, traderPackageDetails }) => {
	const LoadingStore = useContext(LoadingContext);
	const { setLoadingTitle } = LoadingStore;
	const [dateRange, setDateRange] = useState(null);

	// handle send alert to trader
	const [updateSubscriptionsAll] = useUpdateSubscriptionsAllMutation();
	const updateSubscriptions = async () => {
		if (!dateRange || !dateRange[0] || !dateRange[1]) {
			toast.error("يرجي تحديد مدة الاشتراك", { theme: "light" });
			return;
		}
		setLoadingTitle("جاري تعديل مدة الاشتراك");

		try {
			const data = {
				body: {
					id: [traderPackageDetails?.id],
					start_at: dateRange[0].format("YYYY-MM-DD"),
					end_at: dateRange[1].format("YYYY-MM-DD"),
				},
			};

			const res = await updateSubscriptionsAll(data);
			if (res?.data?.success === true && res?.data?.data?.status === 200) {
				setLoadingTitle(null);
				cancel();
			} else {
				setLoadingTitle(null);
				toast.error(res?.data?.message?.en?.message[0], {
					theme: "light ",
				});
			}
		} catch (error) {
			setLoadingTitle(null);
		}
	};

	return (
		<>
			<BackDrop onClick={cancel} />
			<div className=' w-[51.25rem] max-w-[90%] max-h-[600px] fixed trader_alert flex flex-col top-[400px] translate-x-2/4 -translate-y-2/4 right-2/4 z-20 rounded-lg md:overflow-hidden overflow-auto'>
				<div className=' bg-[#1DBBBE] flex items-center justify-center w-full h-16 px-4 py-4 trader_alert'>
					<h2 className='flex-1 text-slate-50 text-center md:text-[22px] text-[18px] font-medium'>
						تعديل مدة الاشتراك
					</h2>
					<IoMdCloseCircleOutline
						size={"1.25rem"}
						color={"#fff"}
						className={" flex cursor-pointer mr-auto"}
						onClick={cancel}
					/>
				</div>
				<div className='bg-[rgb(246,246,246)]  overflow-y-auto max-h-[520px]  flex-1 p-4 pb-8 md:pt-10 bg-'>
					<div className='mb-36 md:w-[80%] w-full'>
						<h4 className='mb-2'>
							{" "}
							- تعديل مدة الاشتراك الخاصه بمتجر{" "}
							<span className='text-[#1DBBBE]'>
								({traderPackageDetails?.store_name})
							</span>{" "}
						</h4>
						<p>
							- مدة الاشتراك الحالية :{" "}
							{traderPackageDetails?.left <= 10 ? (
								<span className='text-[#1DBBBE] text-sm'>
									({traderPackageDetails?.left} أيام)
								</span>
							) : (
								<span className='text-[#1DBBBE] text-sm'>
									({traderPackageDetails?.left} يوم)
								</span>
							)}{" "}
						</p>
						<LocalizationProvider dateAdapter={AdapterDayjs}>
							<Stack
								spacing={3}
								sx={{ flexDirection: "row", maxWidth: "100%" }}>
								<div className='flex items-center justify-center w-20 p-2 rounded-r-md  bg-[#1DBBBE]'>
									<DataIcon />
								</div>
								<DateRange.RangePicker
									className='w-full mt-0 rounded-none rounded-l-lg main_page_date_picker  bg-[#fff] border-[1px] border-[#1DBBBE] cursor-pointer'
									placeholder={["اختر المدة من ", "إلى"]}
									allowEmpty={[true, true]}
									onChange={(dates) => setDateRange(dates)}
									disabledDate={(current) =>
										current && current < new Date().setHours(0, 0, 0, 0)
									}
									placement='bottomLeft'
									suffixIcon={""}
								/>
							</Stack>
						</LocalizationProvider>
					</div>

					<div className='flex justify-center gap-4'>
						<Button
							onClick={updateSubscriptions}
							type={"normal"}
							className={
								"text-center mt-12 w-[163px] md:h-16 h-[45px] text-xl font-normal"
							}
							style={{ backgroundColor: "#02466A" }}>
							تعديل المدة
						</Button>
						<Button
							type={"outline"}
							className={
								"text-center  mt-12 w-[163px] md:h-16 h-[45px] text-xl font-normal"
							}
							style={{ borderColor: "#02466A" }}
							textStyle={{ color: "#02466A" }}
							onClick={cancel}>
							الغاء
						</Button>
					</div>
				</div>
			</div>
		</>
	);
};

export default UpdateSubscriptionsDate;
