import React from "react";
/* MUI */
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
/* Icons */
import { Store, User, Service } from "../../../assets/Icons/index";

const QuickDetails = ({
	loading,
	count_of_stores,
	average_of_stores,
	count_of_marketers,
	average_of_marketers,
	count_of_services,
	average_of_services,
}) => {
	return (
		<Box className='w-full md:mb-[56px] mb-5'>
			<Stack
				className='md:gap-[40px] gap-[10px]'
				sx={{ flexDirection: "row", flexWrap: "wrap" }}>
				<Paper
					sx={{
						flex: "1",
						width: "340px",
						p: "1rem",
						display: "flex",
						alignItems: "center",
						gap: "3rem",
					}}
					className='md:h-[7.125rem] h-[80px] shadow-[0px_6px_12px_#0000001A] rounded-[10px]'>
					<div>
						<div
							className='flex items-center justify-center w-12 h-12 rounded'
							style={{ backgroundColor: "rgba(182, 190, 52, 0.4)" }}>
							<Store />
						</div>
					</div>
					<div className='flex flex-col justify-between flex-1 h-full'>
						<h2 className='text-xl font-normal whitespace-nowrap'>
							عدد المتاجر
						</h2>

						<div className='flex items-baseline justify-between'>
							{(loading || !count_of_stores) && !average_of_stores ? (
								<span className='text-[16px] text-red-500'>لا توجد بيانات</span>
							) : (
								<>
									<h2 className='text-2xl font-medium text-[#b6b1c4]'>
										{count_of_stores}
									</h2>
									<h6 className='font-normal text-[14px] text-green-500'>
										{`% ${average_of_stores} +`}
									</h6>
								</>
							)}
						</div>
					</div>
				</Paper>
				<Paper
					sx={{
						flex: "1",
						width: "340px",
						p: "1rem",
						display: "flex",
						alignItems: "center",
						gap: "3rem",
					}}
					className='md:h-[7.125rem] h-[80px] shadow-[0px_6px_12px_#0000001A] rounded-[10px]'>
					<div>
						<div
							className='flex items-center justify-center w-12 h-12 rounded'
							style={{ backgroundColor: "rgba(29, 187, 190, 0.4)" }}>
							<User />
						</div>
					</div>
					<div className='flex flex-col justify-between flex-1 h-full'>
						<h2 className='text-xl font-normal whitespace-nowrap'>
							عدد المندوبين
						</h2>

						<div className='flex items-baseline justify-between'>
							{(loading || !count_of_marketers) && !average_of_marketers ? (
								<span className='text-[16px] text-red-500'>لا توجد بيانات</span>
							) : (
								<>
									<h2 className='text-2xl font-medium text-[#b6b1c4]'>
										{count_of_marketers}
									</h2>
									<h6 className='font-normal text-[14px] text-green-500'>
										{`% ${average_of_marketers} +`}
									</h6>
								</>
							)}
						</div>
					</div>
				</Paper>
				<Paper
					sx={{
						flex: "1",
						width: "340px",
						p: "1rem",
						display: "flex",
						alignItems: "center",
						gap: "3rem",
					}}
					className='md:h-[7.125rem] h-[80px] shadow-[0px_6px_12px_#0000001A] rounded-[10px]'>
					<div>
						<div
							className='flex items-center justify-center w-12 h-12 rounded'
							style={{ backgroundColor: "rgba(255, 56, 56, 0.4)" }}>
							<Service />
						</div>
					</div>
					<div className='flex flex-col justify-between flex-1 h-full'>
						<h2 className='text-xl font-normal whitespace-nowrap'>
							عدد الخدمات
						</h2>

						<div className='flex items-baseline justify-between'>
							{(loading || !count_of_services) && !average_of_services ? (
								<span className='text-[16px] text-red-500'>لا توجد بيانات</span>
							) : (
								<>
									<h2 className='text-2xl font-medium text-[#b6b1c4]'>
										{count_of_services}
									</h2>
									<h6 className='font-normal text-[14px] text-green-500'>
										{`% ${average_of_services} +`}
									</h6>
								</>
							)}
						</div>
					</div>
				</Paper>
			</Stack>
		</Box>
	);
};

export default QuickDetails;
