import React from "react";

/* MUI */
import { Box } from "@mui/material";
import TabPanel from "@mui/lab/TabPanel";

/* components */
import SelectPartitionStatus from "./SelectPartitionStatus";
import SelectPartitionCategory from "./SelectPartitionCategory";
import PartitionNameInput from "./PartitionNameInput";

/* RTK Query */
import { useAdminSelectorEtlobahCategoryQuery } from "../../../RTK/apiSlices/selectorApi";

const selectStyles = {
	height: "54px",

	backgroundColor: "#fff",
	border: "1px solid #e2e2e2",
	color: "#000",
	width: "100%",
	pl: "1rem",
	"& .MuiOutlinedInput-notchedOutline": {
		border: "none",
	},
	"& .MuiOutlinedInput-input": {
		paddingRight: "0.7rem !important",
	},

	"&.Mui-focused .MuiOutlinedInput-notchedOutline": {
		border: "none",
	},
};

const selectItemStyles = {
	backgroundColor: "#fff",
	color: "#000",
	height: "3rem",

	"&:hover": {
		backgroundColor: "#dedede",
	},
	"ul:has(&)": {
		padding: "0",
	},
};

const PartitionsBox = ({
	currTab,
	permissions,
	partitions,
	onChangeTab,
	currPartition,
}) => {
	const { data: category } = useAdminSelectorEtlobahCategoryQuery();
	return (
		<Box sx={{ height: "27.5rem", mt: "2.5rem" }}>
			<TabPanel value={currTab} className='p-0 md:pr-0'>
				{permissions?.includes("admin.section.sectionupdate") && (
					<PartitionNameInput
						currPartition={currPartition}
						onChangeTab={onChangeTab}
						sectionCategoriesSelect={category?.categories}
					/>
				)}

				{currPartition?.category_id ? (
					<SelectPartitionCategory
						onChangeTab={onChangeTab}
						partitions={partitions}
						selectStyles={selectStyles}
						currPartition={currPartition}
						selectItemStyles={selectItemStyles}
						sectionCategoriesSelect={category?.categories}
					/>
				) : null}

				{permissions?.includes("admin.section.changestatus") && (
					<SelectPartitionStatus
						selectStyles={selectStyles}
						currPartition={currPartition}
						onChangeTab={onChangeTab}
						selectItemStyles={selectItemStyles}
					/>
				)}
			</TabPanel>
		</Box>
	);
};

export default PartitionsBox;
