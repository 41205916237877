import React from "react";
import styles from "./TabsComp.module.css";
/* Third party */
import { Controller } from "react-hook-form";
import ImageUploading from "react-images-uploading";
/* Custom Hook */

/* MUI */
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
/* Icons */
import { IoIosArrowDown } from "react-icons/io";
import { BsSnapchat, BsYoutube } from "react-icons/bs";
import {
	CheckMarkImageIcon,
	PasswordOpen,
	PasswordClose,
	BsWhatsapp,
	BsFacebook,
	BsTwitter,
	BsInstagram,
} from "../../../../assets/Icons";
import {
	useAdminCitiesDataQuery,
	useAdminCountriesDataQuery,
} from "../../../../RTK/apiSlices/selectorApi";

const TabsComp = ({ props, control, errors, images, onChange, DataError }) => {
	const activate = [
		{ id: 1, name: "نشط", name_en: "active" },
		{ id: 2, name: " غير نشط", name_en: "not_active" },
	];

	// to get selectors from api
	const { data: countryList } = useAdminCountriesDataQuery();
	const { data: citiesList } = useAdminCitiesDataQuery();
	/** ---------------------------------------------------------------------------------------------- */

	const [value, setValue] = React.useState("1");

	// this function to change tabs
	const handlerChangeTabs = (event, newValue) => {
		setValue(newValue);
	};

	const [showPassword, setShowPassword] = React.useState(false);
	const [showPasswordConfirm, setShowPasswordConfirm] = React.useState(false);

	return (
		<Box className='mt-6 md:mt-16'>
			<TabContext value={value}>
				<Box>
					<TabList
						sx={{
							"& .MuiTabs-scroller": {
								overflow: "auto !important",
							},
							"& .MuiTabs-flexContainer": {
								gap: "1rem",
							},
							"& .MuiButtonBase-root": {
								minWidth: "200px",
								backgroundColor: "#C0E9FF",
								py: "1.25rem",
								borderRadius: "8px",
								color: "#011723",
								transition: "0.1s",
								fontSize: "1.25rem",
								fontWeight: "500",
							},
							"& .MuiButtonBase-root.Mui-selected": {
								backgroundColor: "#02466A",
								color: "#FFFFFF",
							},
							"& .MuiTabs-indicator": { display: "none" },
						}}
						onChange={handlerChangeTabs}
						variant='fullWidth'>
						<Tab
							disableRipple={true}
							style={{ fontSize: "20px" }}
							label='البيانات الأساسية'
							value='1'
						/>
						<Tab
							disableRipple={true}
							style={{ fontSize: "20px" }}
							label='الدولة/المدينة'
							value='2'
						/>
						<Tab
							disableRipple={true}
							style={{ fontSize: "20px" }}
							label='التواصل الاجتماعى'
							value='3'
						/>
						<Tab
							disableRipple={true}
							style={{ fontSize: "20px" }}
							label='تفعيل / تعطيل'
							value='4'
						/>
					</TabList>
				</Box>
				<Box
					className={`${
						DataError?.name || errors?.name !== ""
							? "md:h-[53rem]"
							: "md:h-[43.5rem]"
					} xl:mt-[3.75rem] mt-6 xl:pl-[7.5rem] pl-0`}>
					<TabPanel value='1' className='md:pr-[18px] p-0'>
						<div className='flex flex-col gap-5'>
							<div className='flex flex-col gap-[10px]'>
								<label style={{ color: "#67747B", fontSize: "18px" }}>
									اسم المندوب<span className='text-red-500'>*</span>
								</label>
								<input
									className='bg-white outline-none w-full px-3 py-[14px] rounded-md'
									style={{ border: "1px solid #E9E9E9" }}
									type='text'
									placeholder='عبد العزيز محمد'
									name='name'
									{...props("name", {
										required: "حقل الاسم مطلوب",
										pattern: {
											value: /^[^-\s][\u0600-\u06FF-A-Za-z ]+$/i,
											message: "يجب ان يتكون الاسم من أحرف",
										},
									})}
								/>
								<span className='text-red-500 text-[16px]'>
									{DataError?.name}
									{errors?.name && errors.name.message}
								</span>
							</div>
							<div className='flex flex-col gap-[10px]'>
								<label style={{ color: "#67747B", fontSize: "18px" }}>
									اسم المستخدم<span className='text-red-500'>*</span>
								</label>
								<input
									className='bg-white outline-none w-full px-3 py-[14px] rounded-md'
									style={{ border: "1px solid #E9E9E9" }}
									type='text'
									placeholder='Abed32'
									name='user_name'
									{...props("user_name", {
										required: "حقل اسم المُستخدم مطلوب",
										pattern: {
											value: /^[^-\s][a-zA-Z0-9_]+$/,
											message:
												"حقل اسم المُستخدم يجب ان يتكون من أحرف انجليزية",
										},
									})}
								/>
								<span className='text-red-500 text-[16px]'>
									{DataError?.user_name}
									{errors?.user_name && errors.user_name.message}
								</span>
							</div>
							<div className='flex md:flex-row flex-col gap-[18px]'>
								<div className='w-full flex flex-col gap-[10px]'>
									<label style={{ color: "#67747B", fontSize: "18px" }}>
										كلمة المرور<span className='text-red-500'>*</span>
									</label>
									<div
										className='flex flex-row items-center justify-between bg-white rounded-md px-3 py-[14px]'
										style={{ border: "1px solid #E9E9E9" }}>
										<input
											className='w-full bg-transparent rounded-md outline-none'
											type={showPassword ? "text" : "password"}
											placeholder='•••••••'
											name='password'
											{...props("password", {
												required: "حقل كلمة المرور مطلوب",
												minLength: {
													value: 6,
													message:
														"حقل كلمة المرور يجب ان يتكون على الأقل من ستة أحرف",
												},
											})}
										/>
										{showPassword ? (
											<PasswordOpen
												className={styles.password}
												onClick={() => setShowPassword(!showPassword)}
											/>
										) : (
											<PasswordClose
												className={styles.password}
												onClick={() => setShowPassword(!showPassword)}
											/>
										)}
									</div>
									<span className='text-red-500 text-[16px]'>
										{DataError?.password}
										{errors?.password && errors.password.message}
									</span>
								</div>
								<div className='w-full flex flex-col gap-[10px]'>
									<label style={{ color: "#67747B", fontSize: "18px" }}>
										تأكيد كلمة المرور<span className='text-red-500'>*</span>
									</label>
									<div
										className='flex flex-row items-center justify-between bg-white rounded-md px-3 py-[14px]'
										style={{ border: "1px solid #E9E9E9" }}>
										<input
											className='w-full bg-transparent rounded-md outline-none'
											type={showPasswordConfirm ? "text" : "password"}
											placeholder='•••••••'
											name='password_confirm'
											{...props("password_confirm", {
												required: "حقل تأكيد كلمة المرور مطلوب",
												minLength: {
													value: 6,
													message:
														"حقل تأكيد كلمة المرور يجب ان يتكون على الأقل من ستة أحرف",
												},
											})}
										/>
										{showPasswordConfirm ? (
											<PasswordOpen
												className={styles.password}
												onClick={() =>
													setShowPasswordConfirm(!showPasswordConfirm)
												}
											/>
										) : (
											<PasswordClose
												className={styles.password}
												onClick={() =>
													setShowPasswordConfirm(!showPasswordConfirm)
												}
											/>
										)}
									</div>
									<span className='text-red-500 text-[16px]'>
										{DataError?.password_confirm}
										{errors?.password_confirm &&
											errors.password_confirm.message}
									</span>
								</div>
							</div>
							<div className='flex md:flex-row flex-col gap-[18px]'>
								<div className='w-full flex flex-col gap-[10px]'>
									<label style={{ color: "#67747B", fontSize: "18px" }}>
										البريد الالكترونى<span className='text-red-500'>*</span>
									</label>
									<input
										className='bg-white outline-none w-full px-3 py-[14px] rounded-md'
										style={{ border: "1px solid #E9E9E9" }}
										placeholder='Abed@gmail.com'
										type='email'
										name='email'
										{...props("email", {
											required: "حقل البريد الالكتروني مطلوب",
											pattern: {
												value: /\S+@\S+\.\S+/,
												message:
													"يجب أن يكون البريد الالكتروني عنوان بريد إلكتروني صحيح البُنية",
											},
										})}
									/>
									<span className='text-red-500 text-[16px]'>
										{DataError?.email}
										{errors?.email && errors.email.message}
									</span>
								</div>
								<div className='w-full flex flex-col gap-[10px]'>
									<label style={{ color: "#67747B", fontSize: "18px" }}>
										رقم التواصل<span className='text-red-500'>*</span>
									</label>
									<div
										className='flex flex-row items-center gap-[1px] bg-white w-full px-3 py-[14px] rounded-md'
										style={{ border: "1px solid #E9E9E9", direction: "ltr" }}>
										<span>+966</span>
										<input
											className='w-full outline-none'
											style={{
												backgroundColor: "transparent",
												direction: "ltr",
											}}
											type='tel'
											placeholder={500000000}
											name='phonenumber'
											maxLength={9}
											{...props("phonenumber", {
												required: "حقل  رقم الجوال مطلوب",
												pattern: {
													value: /^(5\d{8})$/,
													message:
														"تأكد ان رقم الجوال يبدأ برقم 5 ولا يقل عن 9 أرقام",
												},
											})}
										/>
									</div>
									<span className='text-red-500 text-[16px]'>
										{DataError?.phonenumber}
										{errors?.phonenumber && errors.phonenumber.message}
									</span>
								</div>
							</div>
							<div className={`mb-5 flex gap-3 items-end `}>
								<div
									className='flex items-center justify-center rounded-md'
									style={{
										backgroundColor: "#D3D3D3",
										height: "161px",
										minWidth: "130px",
									}}>
									{images[0] ? (
										<img
											className='w-full h-full rounded-md'
											src={images[0]?.data_url}
											alt='img'
											loading='lazy'
										/>
									) : (
										<CheckMarkImageIcon
											style={{ width: "32px", height: "32px" }}
										/>
									)}
								</div>
								<div className='flex flex-col w-full'>
									<div className='w-full'>
										<ImageUploading
											value={images}
											onChange={onChange}
											maxNumber={2}
											dataURLKey='data_url'
											acceptType={["jpg", "png", "jpeg", "webp", "svg"]}
											className='w-full'>
											{({ onImageUpload, dragProps }) => (
												// write your building UI
												<div>
													<div
														className='relative overflow-hidden upload__image-wrapper'
														style={{
															width: "100%",
															border: images[0]
																? "1px solid #E9E9E9"
																: "1px solid #E9E9E9",
															borderRadius: "4px",
														}}
														onClick={() => {
															onImageUpload();
														}}
														{...dragProps}>
														<div
															className='flex w-full cursor-pointer image-item'
															style={{ height: "56px" }}>
															{!images[0] && (
																<div className='flex flex-1'>
																	<div className='flex-1 bg-white'></div>
																	<div
																		className='flex flex-col items-center justify-center px-10 rounded-lg'
																		style={{
																			width: "180px",
																			height: "56px",
																			backgroundColor: "#237EAE",
																			color: "#ffffff",
																		}}>
																		استعراض
																	</div>
																</div>
															)}
															{images[0] && (
																<div className='flex flex-col gap-2'>
																	<div className='flex flex-1'>
																		<div className='flex flex-col items-center justify-center flex-1'>
																			<h6
																				style={{
																					fontSize: "18px",
																					color: "#000000",
																					fontWeight: "500",
																				}}>
																				{images[0].file.name}
																			</h6>
																		</div>
																		<div
																			className='flex flex-col items-center justify-center px-10 rounded-lg'
																			style={{
																				width: "180px",
																				height: "56px",
																				backgroundColor: "#237EAE",
																				color: "#ffffff",
																			}}>
																			استعراض
																		</div>
																	</div>
																	<span className='text-gray-400 font-light text-[14px]'>
																		(الحد الأقصى للصورة 1MB)
																	</span>
																</div>
															)}
														</div>
													</div>
												</div>
											)}
										</ImageUploading>
										<span className='text-gray-400 font-light text-[14px]'>
											(الحد الأقصى للصورة 1MB)
										</span>
									</div>
									<span className='text-red-500 text-[16px]'>
										{DataError?.image}
									</span>
								</div>
							</div>
						</div>
					</TabPanel>
					<TabPanel value='2' className='md:pr-[18px] p-0'>
						<div className='flex flex-col gap-5'>
							<div className='flex flex-col gap-[10px]'>
								<label style={{ color: "#000000", fontSize: "18px" }}>
									الدولة<span className='text-red-500'>*</span>
								</label>
								<Controller
									name={"country_id"}
									control={control}
									rules={{ required: "حقل الدولة مطلوب" }}
									render={({ field: { onChange, value } }) => (
										<Select
											displayEmpty
											style={{ fontSize: "18px", border: "1px solid #E9E9E9" }}
											name='country_id'
											value={value}
											onChange={onChange}
											IconComponent={() => {
												return <IoIosArrowDown size={"1rem"} />;
											}}
											inputProps={{ "aria-label": "Without label" }}
											renderValue={(selected) => {
												if (!selected) {
													return <h2>اختر الدولة</h2>;
												}
												const result = countryList?.countries?.filter(
													(item) => item?.id === parseInt(selected)
												);
												return result[0]?.name;
											}}
											className='bg-white outline-none w-full py-[14px] rounded-md'
											sx={{
												height: "3.5rem",
												pl: "1rem",
												"& .MuiOutlinedInput-notchedOutline": {
													border: "1px solid #E9E9E9",
												},
												"&.Mui-focused .MuiOutlinedInput-notchedOutline": {
													border: "1px solid #03787A",
												},
											}}>
											{countryList?.countries?.map((item) => {
												return (
													<MenuItem
														key={item.id}
														className='w-full'
														sx={{
															width: "100%",
															backgroundColor: "#fff",
															height: "3rem",
														}}
														value={`${item?.id}`}>
														{item?.name}
													</MenuItem>
												);
											})}
										</Select>
									)}
								/>
								<span className='text-red-500 text-[16px]'>
									{DataError?.country_id}
									{errors?.country_id && errors.country_id.message}
								</span>
							</div>
							<div className='flex flex-col gap-[10px]'>
								<label style={{ color: "#000000", fontSize: "18px" }}>
									المدينة<span className='text-red-500'>*</span>
								</label>
								<Controller
									name={"city_id"}
									control={control}
									rules={{ required: "The city field is required" }}
									render={({ field: { onChange, value } }) => (
										<Select
											displayEmpty
											style={{ fontSize: "18px", border: "1px solid #E9E9E9" }}
											name='city_id'
											value={value}
											onChange={onChange}
											IconComponent={() => {
												return <IoIosArrowDown size={"1rem"} />;
											}}
											inputProps={{ "aria-label": "Without label" }}
											renderValue={(selected) => {
												if (!selected) {
													return <h2>اختر المدينة</h2>;
												}
												const result = citiesList?.cities?.filter(
													(item) => item?.id === parseInt(selected)
												);
												return result[0]?.name;
											}}
											className='bg-white outline-none w-full py-[14px] rounded-md'
											sx={{
												height: "3.5rem",
												pl: "1rem",
												"& .MuiOutlinedInput-notchedOutline": {
													border: "1px solid #E9E9E9",
												},
												"&.Mui-focused .MuiOutlinedInput-notchedOutline": {
													border: "1px solid #03787A",
												},
											}}>
											{citiesList?.cities?.map((item) => {
												return (
													<MenuItem
														key={item.id}
														className='w-full'
														sx={{
															width: "100%",
															backgroundColor: "#fff",
															height: "3rem",
														}}
														value={`${item?.id}`}>
														{item?.name}
													</MenuItem>
												);
											})}
										</Select>
									)}
								/>
								<span className='text-red-500 text-[16px]'>
									{DataError?.city_id}
									{errors?.city_id && errors.city_id.message}
								</span>
							</div>
						</div>
					</TabPanel>
					<TabPanel value='3' className='md:pr-[18px] p-0'>
						<div className='flex flex-col gap-5'>
							<div className='flex flex-col gap-[10px]'>
								<label style={{ color: "#000000", fontSize: "18px" }}>
									رقم واتساب
								</label>
								<div className={` flex-1 relative`}>
									<span
										style={{ direction: "ltr", left: "7px" }}
										className={`absolute top-1/2 -translate-y-2/4`}>
										+966
									</span>
									<input
										className='bg-white outline-none w-full px-5 py-[14px] rounded-md'
										type='tel'
										placeholder='512345678'
										maxLength={9}
										style={{
											border: "1px solid #E9E9E9",
											direction: "ltr",
										}}
										name='whatsapp'
										{...props("whatsapp", {
											required: "حقل واتساب مطلوب",
											pattern: {
												value: /^(5\d{8})$/,
												message:
													"تأكد ان رقم الجوال يبدأ برقم 5 ولا يقل عن 9 أرقام",
											},
										})}
									/>
									<div className={`absolute top-1/2 right-4 -translate-y-2/4`}>
										<BsWhatsapp
											className={styles.icons}
											width='20px'
											height='20px'></BsWhatsapp>
									</div>
								</div>
								<span className='text-red-500 text-[16px]'>
									{DataError?.whatsapp}
									{errors?.whatsapp && errors.whatsapp.message}
								</span>
							</div>
							<div className='flex flex-col gap-[10px]'>
								<label style={{ color: "#000000", fontSize: "18px" }}>
									سناب شات
								</label>
								<div className={` flex-1 relative   `}>
									<input
										className='bg-white outline-none w-full px-5 py-[14px] rounded-md'
										type='text'
										style={{
											border: "1px solid #E9E9E9",
											direction: "ltr",
										}}
										name='snapchat'
										{...props("snapchat", {
											required: "حقل سناب شات مطلوب",
											pattern: {
												value: /^((ftp|http|https):\/\/).([A-z]+)\.([A-z]{2,})/,
												message: "صيغة رابط سناب شات غير صحيحة",
											},
										})}
									/>
									<div className={`absolute top-1/2 right-4 -translate-y-2/4`}>
										<BsSnapchat
											className={styles.icons}
											width='20px'
											height='20px'></BsSnapchat>
									</div>
								</div>
								<span className='text-red-500 text-[16px]'>
									{DataError?.snapchat}
									{errors?.snapchat && errors.snapchat.message}
								</span>
							</div>
							<div className='flex flex-col gap-[10px]'>
								<label style={{ color: "#000000", fontSize: "18px" }}>
									فيسبوك
								</label>
								<div className={` flex-1 relative   `}>
									<input
										className='bg-white outline-none w-full px-5 py-[14px] rounded-md'
										placeholder=''
										type='text'
										style={{
											border: "1px solid #E9E9E9",
											direction: "ltr",
										}}
										name='facebook'
										{...props("facebook", {
											required: "حقل فيسبوك مطلوب",
											pattern: {
												value: /^((ftp|http|https):\/\/).([A-z]+)\.([A-z]{2,})/,
												message: "صيغة رابط فيسبوك غير صحيحة",
											},
										})}
									/>
									<div className={`absolute top-1/2 right-4 -translate-y-2/4`}>
										<BsFacebook
											className={styles.icons}
											width='20px'
											height='20px'></BsFacebook>
									</div>
								</div>
								<span className='text-red-500 text-[16px]'>
									{DataError?.facebook}
									{errors?.facebook && errors.facebook.message}
								</span>
							</div>
							<div className='flex flex-col gap-[10px]'>
								<label style={{ color: "#000000", fontSize: "18px" }}>
									تويتر
								</label>
								<div className={` flex-1 relative   `}>
									<input
										className='bg-white outline-none w-full px-5 py-[14px] rounded-md'
										type='text'
										style={{
											border: "1px solid #E9E9E9",
											direction: "ltr",
										}}
										name='twiter'
										{...props("twiter", {
											required: "حقل تويتر مطلوب",
											pattern: {
												value: /^((ftp|http|https):\/\/).([A-z]+)\.([A-z]{2,})/,
												message: "صيغة رابط تويتر غير صحيحة",
											},
										})}
									/>
									<div className={`absolute top-1/2 right-4 -translate-y-2/4`}>
										<BsTwitter
											className={styles.icons}
											width='20px'
											height='20px'></BsTwitter>
									</div>
								</div>
								<span className='text-red-500 text-[16px]'>
									{DataError?.twiter}
									{errors?.twiter && errors.twiter.message}
								</span>
							</div>
							<div className='flex flex-col gap-[10px]'>
								<label style={{ color: "#000000", fontSize: "18px" }}>
									انستجرام
								</label>
								<div className={` flex-1 relative`}>
									<input
										className='bg-white outline-none w-full px-5 py-[14px] rounded-md'
										type='text'
										style={{
											border: "1px solid #E9E9E9",
											direction: "ltr",
										}}
										name='instegram'
										{...props("instegram", {
											required: "حقل انستجرام مطلوب",
											pattern: {
												value: /^((ftp|http|https):\/\/).([A-z]+)\.([A-z]{2,})/,
												message: "صيغة رابط انستجرام غير صحيحة",
											},
										})}
									/>
									<div className={`absolute top-1/2 right-4 -translate-y-2/4`}>
										<BsInstagram
											className={styles.icons}
											width='20px'
											height='20px'></BsInstagram>
									</div>
								</div>
								<span className='text-red-500 text-[16px]'>
									{DataError?.instegram}
									{errors?.instegram && errors.instegram.message}
								</span>
							</div>
							<div className='flex flex-col gap-[10px]'>
								<label style={{ color: "#000000", fontSize: "18px" }}>
									يوتيوب
								</label>
								<div className={` flex-1 relative`}>
									<input
										className='bg-white outline-none w-full px-5 py-[14px] rounded-md'
										type='text'
										style={{
											border: "1px solid #E9E9E9",
											direction: "ltr",
										}}
										name='youtube'
										{...props("youtube", {
											required: "حقل يوتيوب مطلوب",
											pattern: {
												value: /^((ftp|http|https):\/\/).([A-z]+)\.([A-z]{2,})/,
												message: "صيغة رابط يوتيوب غير صحيحة",
											},
										})}
									/>
									<div className={`absolute top-1/2 right-4 -translate-y-2/4`}>
										<BsYoutube
											className={styles.icons}
											width='20px'
											height='20px'></BsYoutube>
									</div>
								</div>
								<span className='text-red-500 text-[16px]'>
									{DataError?.youtube}
									{errors?.youtube && errors.youtube.message}
								</span>
							</div>
						</div>
					</TabPanel>
					<TabPanel value='4' className='md:pr-[18px] p-0'>
						<div className='flex flex-col gap-[10px]'>
							<label style={{ color: "#000000", fontSize: "18px" }}>
								الحالة<span className='text-red-500'>*</span>
							</label>
							<Controller
								name={"status"}
								control={control}
								rules={{ required: "The status field is required" }}
								render={({ field: { onChange, value } }) => (
									<Select
										name='status'
										value={value}
										onChange={onChange}
										style={{ fontSize: "18px", border: "1px solid #E9E9E9" }}
										IconComponent={() => {
											return <IoIosArrowDown size={"1rem"} />;
										}}
										displayEmpty
										inputProps={{ "aria-label": "Without label" }}
										renderValue={(selected) => {
											if (!selected) {
												return <h2>اختر الحالة</h2>;
											}
											const result = activate?.filter(
												(item) => item?.name_en === selected
											);
											return result[0]?.name;
										}}
										className='bg-white outline-none w-full px-3 py-[14px] rounded-md'
										sx={{
											height: "3.5rem",
											pl: "1rem",
											backgroundColor: "#fff",
											width: "100%",
											"& .MuiOutlinedInput-notchedOutline": {
												border: "1px solid #E9E9E9",
											},
											"&.Mui-focused .MuiOutlinedInput-notchedOutline": {
												border: "1px solid #03787A",
											},
										}}>
										{activate?.map((item, idx) => {
											return (
												<MenuItem
													key={idx}
													className=''
													sx={{
														backgroundColor: "#fff",
														height: "3rem",

														"&:hover": {},
														"ul:has(&)": {
															padding: "0",
														},
													}}
													value={`${item?.name_en}`}>
													{item?.name}
												</MenuItem>
											);
										})}
									</Select>
								)}
							/>
							<span className='text-red-500 text-[16px]'>
								{DataError?.status}
								{errors?.status && errors.status.message}
							</span>
						</div>
					</TabPanel>
				</Box>
			</TabContext>
		</Box>
	);
};

export default TabsComp;
