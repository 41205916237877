import React, { useState, useContext } from "react";
/* Third party */
import { useNavigate } from "react-router-dom";
/* Context */
import { RolePermissionsContext } from "../../../store/RolePermissionsProvider";
/* Components */
import CircularLoading from "../../../UI/CircularLoading/CircularLoading";

import { Delete, Copy, EditButton, TrueIcon } from "../../../assets/Icons";
import NewPagination from "../../PaginationComp/NewPagination";
import {
	useDeleteExplainVideosMutation,
	useGetExplainVideosQuery,
} from "../../../RTK/apiSlices/coursesApi";
import { DeleteContext } from "../../../store/DeleteProvider";
import DeleteOneModalComp from "../../DeleteOneModal/DeleteOneModal";

import PlaceholderImage from "../../../assets/images/placeholderImage.png";
import { toast } from "react-toastify";

const AcademyLessons = () => {
	// videos data
	const [page, setPage] = useState(1);
	const [rowsPerPage, setRowsPerPage] = useState(8);
	const { data: lessons, isLoading: lessonsLoading } = useGetExplainVideosQuery(
		{
			page: page,
			number: rowsPerPage,
		}
	);
	const navigate = useNavigate();
	const [id, setId] = useState("");

	const RolePermissionsStore = useContext(RolePermissionsContext);
	const { permissions } = RolePermissionsStore;

	const [copy, setCopy] = useState(false);
	const DeleteStore = useContext(DeleteContext);
	const { setItemId, setActionDelete, actionDelete } = DeleteStore;
	// --------------------------------------------------------
	const [deleteExplainVideos] = useDeleteExplainVideosMutation();

	// change status for single item
	const deleteExplainVideosHandel = async (id) => {
		try {
			await deleteExplainVideos({ id })
				.unwrap()
				.then((data) => {
					if (data.success) {
					}
				});
		} catch (err) {
			console.error("Failed to delete the category", err);
		}
	};

	const handelCopy = (lessonId) => {
		const lesson = lessons?.explainvideos?.filter(
			(lesson) => lesson?.id === lessonId
		);
		navigator.clipboard.writeText(lesson[0]?.url);
		setCopy(true);
		toast.success("تم نسخ الرابط بنجاح");
		setTimeout(() => {
			setCopy(false);
		}, 1000);
		setId("");
	};

	return (
		<div className='pb-6 mt-6 md:mt-12'>
			{lessonsLoading ? (
				<div className='flex flex-col items-center justify-center w-full'>
					<CircularLoading />
				</div>
			) : (
				<>
					{lessons?.explainvideos?.length > 0 ? (
						<div className='grid grid-cols-1 gap-6 mb-12 md:grid-cols-2 lg:grid-cols-4 '>
							{lessons?.explainvideos?.map((lesson, idx) => (
								<div
									key={idx}
									className='shadow-lg bg-white w-[95%] md:w-auto md:m-0  m-auto rounded-ss-lg rounded-t-lg'>
									<div className='flex flex-col justify-between'>
										<div className=' h-[268px] rounded-t-lg rounded-ss-lg'>
											<img
												src={
													lesson?.thumbnail
														? lesson?.thumbnail
														: PlaceholderImage
												}
												className='rounded-t-lg rounded-ss-lg w-full  w-full h-full object-cover'
												alt={lesson?.name}
												loading='lazy'
											/>
										</div>

										<div className='flex flex-col'>
											<h2 className='mt-1  text-md font-medium text-center min-h-[60px] flex justify-center items-center '>
												{lesson?.title}
											</h2>

											<div
												className='flex flex-row items-center justify-center gap-5'
												style={{
													backgroundColor: "#FF38380A",
													height: "52px",
												}}>
												{id === lesson?.id && copy ? (
													<TrueIcon />
												) : (
													<Copy
														title='نسخ الرابط'
														className='cursor-pointer'
														onClick={() => {
															handelCopy(lesson?.id);
															setId(lesson?.id);
														}}
													/>
												)}
												{permissions?.includes("admin.explainvideo.update") && (
													<EditButton
														title='تعديل'
														className='cursor-pointer'
														onClick={() => {
															navigate(`/edit-lesson/${lesson?.id}`);
														}}
													/>
												)}
												{permissions?.includes(
													"admin.explainvideo.destroy"
												) && (
													<Delete
														title='حذف'
														className='cursor-pointer'
														onClick={() => {
															setActionDelete(
																"سيتم حذف الدرس  وهذة الخطوة غير قابلة للرجوع"
															);
															setItemId(lesson?.id);
														}}></Delete>
												)}
											</div>
										</div>
									</div>
								</div>
							))}
						</div>
					) : (
						<div className='flex flex-col items-center justify-center mt-20 text-lg'>
							لاتوجد شروحات
						</div>
					)}
					{!lessonsLoading && (
						<div dir='ltr'>
							<NewPagination
								pageNumber={page}
								setPageNumber={setPage}
								currentPage={rowsPerPage}
								setCurrentPage={setRowsPerPage}
								page_count={lessons?.page_count}
							/>
						</div>
					)}
				</>
			)}
			{actionDelete && (
				<DeleteOneModalComp
					handleDeleteSingleItem={deleteExplainVideosHandel}
				/>
			)}
		</div>
	);
};

export default AcademyLessons;
