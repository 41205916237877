import React, { useContext, Fragment } from "react";
import PropTypes from "prop-types";
/* Third party */
import { useNavigate } from "react-router-dom";
/* Contexts */
import Context from "../../../../store/context";
import { DeleteContext } from "../../../../store/DeleteProvider";
import { NotificationContext } from "../../../../store/NotificationProvider";
import { RolePermissionsContext } from "../../../../store/RolePermissionsProvider";
/* Components */
import CircularLoading from "../../../../UI/CircularLoading/CircularLoading";
import NewPagination from "../../../PaginationComp/NewPagination";
/* MUI */
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { visuallyHidden } from "@mui/utils";

import PlaceholderImage from "../../../../assets/images/placeholderImage.png";

/* Icons */
import {
	CheckedSquare,
	EditButton,
	Delete,
	SortIcon,
	InfoIcon,
} from "../../../../assets/Icons";
import {
	useMarketerDeleteAllMutation,
	useMarketerDeleteItemMutation,
} from "../../../../RTK/apiSlices/marketersApi";
import DeleteModalComp from "../../../DeleteModal/DeleteModal";
import DeleteOneModalComp from "../../../DeleteOneModal/DeleteOneModal";
import { toast } from "react-toastify";

function descendingComparator(a, b, orderBy) {
	if (b[orderBy] < a[orderBy]) {
		return -1;
	}
	if (b[orderBy] > a[orderBy]) {
		return 1;
	}
	return 0;
}

function getComparator(order, orderBy) {
	return order === "desc"
		? (a, b) => descendingComparator(a, b, orderBy)
		: (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
	const stabilizedThis = array?.map((el, index) => [el, index]);
	stabilizedThis?.sort((a, b) => {
		const order = comparator(a[0], b[0]);
		if (order !== 0) {
			return order;
		}
		return a[1] - b[1];
	});
	return stabilizedThis?.map((el) => el[0]);
}

const headCells = [
	{
		id: "situation",
		numeric: false,
		disablePadding: false,
		label: "الإجراء",
		width: "5rem",
	},
	{
		id: "active",
		numeric: true,
		disablePadding: false,
		label: "الحالة",
		sort: true,
		textAlign: "center",
		sortLabel: "status",
	},
	{
		id: "daysLeft",
		numeric: true,
		disablePadding: false,
		label: "المدينة",
	},
	{
		id: "rate",
		numeric: true,
		disablePadding: false,
		sort: true,
		label: "اسم المستخدم",
		sortLabel: "user_name",
	},

	{
		id: "circle",
		numeric: true,
		disablePadding: false,
		label: "اسم المندوب",
	},

	{
		id: "number",
		numeric: true,
		disablePadding: false,
		label: "م",
	},
];

function EnhancedTableHead(props) {
	const { order, orderBy, onRequestSort } = props;
	const createSortHandler = (property) => (event) => {
		onRequestSort(event, property);
	};

	return (
		<TableHead sx={{ backgroundColor: "#ebebebd9" }}>
			<TableRow>
				{headCells?.map((headCell, index) => (
					<TableCell
						className='md:text-lg text-[16px] font-medium'
						key={index}
						align={headCell.numeric ? "right" : "center"}
						padding={headCell.disablePadding ? "none" : "normal"}
						sortDirection={orderBy === headCell.id ? order : false}
						sx={{
							width: headCell.width ? headCell.width : "auto",
							color: "#02466A",
							whiteSpace: "nowrap",
							textAlign: headCell?.textAlign,
						}}>
						{headCell.sort && (
							<TableSortLabel
								IconComponent={() => {
									return <SortIcon style={{ marginRight: "8px" }} />;
								}}
								active={orderBy === headCell.id}
								direction={orderBy === headCell.id ? order : "asc"}
								onClick={createSortHandler(headCell?.sortLabel)}>
								{headCell.label}
								{!orderBy === headCell.id ? (
									<Box component='span' sx={visuallyHidden}>
										{order === "desc"
											? "sorted descending"
											: "sorted ascending"}
									</Box>
								) : null}
							</TableSortLabel>
						)}
						{!headCell.sort && headCell.label}
					</TableCell>
				))}
				<TableCell padding={"none"}></TableCell>
			</TableRow>
		</TableHead>
	);
}

EnhancedTableHead.propTypes = {
	numSelected: PropTypes.number.isRequired,
	onRequestSort: PropTypes.func.isRequired,
	onSelectAllClick: PropTypes.func.isRequired,
	order: PropTypes.oneOf(["asc", "desc"]).isRequired,
	orderBy: PropTypes.string.isRequired,
	rowCount: PropTypes.number,
};

function EnhancedTableToolbar(props) {
	const { numSelected, rowCount, onSelectAllClick, itemsSelected } = props;
	const NotificationStore = useContext(NotificationContext);
	const { setNotificationTitle, setActionType, setItems } = NotificationStore;
	const RolePermissionsStore = useContext(RolePermissionsContext);
	const { permissions } = RolePermissionsStore;

	return (
		<Toolbar
			sx={{
				pl: { sm: 2 },
				pr: { xs: 1, sm: 1 },
				...(numSelected > 0 && {
					bgcolor: (theme) =>
						alpha(
							theme.palette.primary.main,
							theme.palette.action.activatedOpacity
						),
				}),
				display: "flex",
				gap: "2rem",
				justifyContent: "flex-end",
			}}>
			<div className='flex items-center gap-2'>
				{permissions?.includes("admin.marketer.deleteall") &&
					numSelected > 0 && (
						<Tooltip
							onClick={() => {
								setNotificationTitle(
									"سيتم حذف جميع المناديب التي قمت بتحديدهم"
								);
								setItems(itemsSelected);
								setActionType("deleteAll");
							}}>
							<div
								className='rounded-full fcc'
								style={{
									width: "130px",
									backgroundColor: "rgba(255, 56, 56, 0.1)",
									cursor: "pointer",
								}}>
								<h2
									className={"font-medium md:text-lg text-[16px]"}
									style={{ color: "#FF3838" }}>
									حذف الكل
								</h2>
								<IconButton>
									<Delete
										style={{
											cursor: "pointer",
											color: "red",
											fontSize: "1rem",
										}}
									/>
								</IconButton>
							</div>
						</Tooltip>
					)}
			</div>

			<div className='flex items-center'>
				<h2 className='font-medium md:text-lg text-[16px] whitespace-nowrap'>
					تحديد الكل
				</h2>
				<Checkbox
					checkedIcon={<CheckedSquare />}
					sx={{
						pr: "0",
						color: "#011723",
						"& .MuiSvgIcon-root": {
							color: "#011723",
						},
					}}
					indeterminate={numSelected > 0 && numSelected < rowCount}
					checked={rowCount > 0 && numSelected === rowCount}
					onChange={onSelectAllClick}
					inputProps={{
						"aria-label": "select all desserts",
					}}
				/>
			</div>
		</Toolbar>
	);
}

EnhancedTableToolbar.propTypes = {
	numSelected: PropTypes.number.isRequired,
};

export default function EnhancedTable({
	fetchedData,
	loading,
	pageNumber,
	setPageNumber,
	currentPage,
	setCurrentPage,
	page_count,
	search,
}) {
	const NotificationStore = useContext(NotificationContext);
	const { notificationTitle } = NotificationStore;
	const DeleteStore = useContext(DeleteContext);
	const { setItemId, setActionDelete, actionDelete } = DeleteStore;
	const RolePermissionsStore = useContext(RolePermissionsContext);
	const { permissions } = RolePermissionsStore;
	const contextStore = useContext(Context);
	const { setEndActionTitle } = contextStore;
	const [order, setOrder] = React.useState("asc");
	const [orderBy, setOrderBy] = React.useState("calories");
	const [selected, setSelected] = React.useState([]);
	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(10);
	const navigate = useNavigate();

	// handle sorting
	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === "asc";
		setOrder(isAsc ? "desc" : "asc");
		setOrderBy(property);
	};

	// handle select all
	const handleSelectAllClick = (event) => {
		if (event.target.checked) {
			const newSelected = fetchedData?.map((n) => n.id);
			setSelected(newSelected);
			return;
		}
		setSelected([]);
	};

	const handleClick = (event, id) => {
		const selectedIndex = selected.indexOf(id);
		let newSelected = [];

		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selected, id);
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(selected.slice(1));
		} else if (selectedIndex === selected.length - 1) {
			newSelected = newSelected.concat(selected.slice(0, -1));
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(
				selected.slice(0, selectedIndex),
				selected.slice(selectedIndex + 1)
			);
		}

		setSelected(newSelected);
	};
	const isSelected = (id) => selected.indexOf(id) !== -1;

	// Avoid a layout jump when reaching the last page with empty rows.
	const emptyRows =
		page > 0 ? Math.max(0, (1 + page) * rowsPerPage - fetchedData?.length) : 0;

	// delete
	const [marketerDeleteItem] = useMarketerDeleteItemMutation();
	const [marketerDeleteAll] = useMarketerDeleteAllMutation();

	// delete all
	const handleDeleteAllItems = async (selected) => {
		const queryParams = selected?.map((id) => `id[]=${id}`).join("&");
		try {
			await marketerDeleteAll({ queryParams })
				.unwrap()
				.then((data) => {
					if (!data?.success) {
						toast.error(data?.message?.ar, {
							theme: "light",
						});
					} else {
						setEndActionTitle(data?.message?.ar);
					}
				});
		} catch (err) {
			console.error("Failed to delete the etlobhaDeleteAll", err);
		}
	};

	// delete item
	const handleDeleteSingleItem = async (id) => {
		try {
			await marketerDeleteItem({ id })
				.unwrap()
				.then((data) => {
					if (!data?.success) {
						toast.error(data?.message?.ar, {
							theme: "light",
						});
					} else {
						setEndActionTitle(data?.message?.ar);
					}
				});
		} catch (err) {
			console.error("Failed to delete the etlbha Product", err);
		}
	};

	return (
		<Box sx={{ width: "100%" }}>
			<Paper
				sx={{
					width: "100%",
					mb: 8,
					backgroundColor: "transparent",
					boxShadow: "none",
				}}>
				<EnhancedTableToolbar
					itemsSelected={selected}
					numSelected={selected.length || 0}
					rowCount={fetchedData?.length || 0}
					onSelectAllClick={handleSelectAllClick}
				/>
				<TableContainer>
					<Table
						sx={{ minWidth: 750, backgroundColor: "#fff" }}
						aria-labelledby='tableTitle'
						size={"medium"}>
						<EnhancedTableHead
							itemsSelected={selected}
							numSelected={selected.length || 0}
							order={order}
							orderBy={orderBy}
							onSelectAllClick={handleSelectAllClick}
							onRequestSort={handleRequestSort}
							rowCount={fetchedData?.length || 0}
						/>
						<TableBody>
							{loading ? (
								<TableRow>
									<TableCell colSpan={6}>
										<CircularLoading />
									</TableCell>
								</TableRow>
							) : (
								<Fragment>
									{fetchedData?.length === 0 ? (
										<TableRow>
											<TableCell colSpan={6}>
												<p className='text-center text-[18px]'>لاتوجد بيانات</p>
											</TableCell>
										</TableRow>
									) : (
										stableSort(fetchedData, getComparator(order, orderBy))?.map(
											(row, index) => {
												const isItemSelected = isSelected(row?.id);
												const labelId = `enhanced-table-checkbox-${index}`;

												return (
													<TableRow
														hover
														role='checkbox'
														tabIndex={-1}
														aria-checked={isItemSelected}
														key={index}
														selected={isItemSelected}>
														<TableCell component='th' id={labelId} scope='row'>
															<div className='flex items-center gap-2'>
																{permissions?.includes(
																	"admin.marketer.deleteall"
																) && (
																	<Delete
																		title='حذف'
																		onClick={() => {
																			setActionDelete(
																				"سيتم حذف المندوب وهذة الخطوة غير قابلة للرجوع"
																			);
																			setItemId(row.id);
																		}}
																		style={{
																			cursor: "pointer",
																			color: "red",
																			fontSize: "1rem",
																		}}
																	/>
																)}
																{permissions?.includes(
																	"admin.marketer.update"
																) && (
																	<EditButton
																		title=' تعديل بيانات المندوب'
																		className='w-6 h-6 cursor-pointer'
																		onClick={() => {
																			navigate(`/edit_marketer/${row?.id}`);
																		}}
																		width={"20px"}
																	/>
																)}
																{permissions?.includes(
																	"admin.marketer.show"
																) && (
																	<InfoIcon
																		title='تفاصيل المندوب'
																		className='w-6 h-6 cursor-pointer'
																		onClick={() => {
																			navigate(`/show_marketer/${row?.id}`);
																		}}
																	/>
																)}
															</div>
														</TableCell>
														<TableCell
															align='center'
															className='text-base font-normal'>
															<div
																className='w-20 h-full py-[2px] rounded-2xl'
																style={{
																	backgroundColor:
																		row?.status === "نشط"
																			? "#3AE37466"
																			: "#ECECEC",
																	margin: "auto",
																}}>
																<h2
																	className=' text-[16px]'
																	style={{
																		color:
																			row?.status === "نشط"
																				? "#011723"
																				: "#67747B",
																	}}>
																	{row?.status === "نشط" ? "نشط" : "غير نشط"}
																</h2>
															</div>
														</TableCell>

														<TableCell align='right'>
															<div>
																<h2 className='font-normal md:text-lg text-[16px] whitespace-normal'>
																	{row?.city?.name}
																</h2>
															</div>
														</TableCell>

														<TableCell align='right'>
															<div>
																<h2 className='font-normal md:text-lg text-[16px] whitespace-normal'>
																	{row?.user_name}
																</h2>
															</div>
														</TableCell>
														<TableCell align='right'>
															<div className='flex flex-row items-center justify-end gap-3 min-h-[35px] max-h-[35px]'>
																<h2 className='inline font-normal md:text-lg text-[16px] whitespace-normal'>
																	{row?.name}
																</h2>
																<div
																	className='img_icons'
																	style={{
																		minWidth: "35px",
																		minHeight: "35px",
																		maxWidth: "35px",
																		maxHeight: "35px",
																	}}>
																	<img
																		className='img_icons'
																		src={
																			row?.image ? row?.image : PlaceholderImage
																		}
																		alt=''
																		loading='lazy'
																		style={{
																			width: "100%",
																			height: "100%",
																			objectFit: "cover",
																			borderRadius: "50%",
																		}}
																	/>
																</div>
															</div>
														</TableCell>
														<TableCell
															align='right'
															className='font-normal md:text-lg text-[16px] whitespace-normal'>
															{(index + 1).toLocaleString("en-US", {
																minimumIntegerDigits: 2,
																useGrouping: false,
															})}
														</TableCell>
														<TableCell padding='none' align={"right"}>
															<Checkbox
																checkedIcon={<CheckedSquare />}
																sx={{
																	color: "#011723",
																	"& .MuiSvgIcon-root": {
																		color: "#011723",
																	},
																}}
																checked={isItemSelected}
																onClick={(event) => handleClick(event, row?.id)}
																inputProps={{
																	"aria-labelledby": labelId,
																}}
															/>
														</TableCell>
													</TableRow>
												);
											}
										)
									)}
									{emptyRows > 0 && (
										<TableRow
											style={{
												height: 53 * emptyRows,
											}}>
											<TableCell colSpan={6} />
										</TableRow>
									)}
								</Fragment>
							)}
						</TableBody>
					</Table>
				</TableContainer>
			</Paper>

			{search ? null : (
				<NewPagination
					pageNumber={pageNumber}
					setPageNumber={setPageNumber}
					currentPage={currentPage}
					setCurrentPage={setCurrentPage}
					page_count={page_count}
				/>
			)}

			{actionDelete && (
				<DeleteOneModalComp handleDeleteSingleItem={handleDeleteSingleItem} />
			)}

			{notificationTitle && (
				<DeleteModalComp handleDeleteAllItems={handleDeleteAllItems} />
			)}
		</Box>
	);
}
