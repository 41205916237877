import React from "react";
import { MyAccountIcon } from "../../assets/Icons";
import { useNavigate } from "react-router-dom";

const ProfileBtn = ({ closeMenu }) => {
	const navigate = useNavigate();

	return (
		<div className='flex flex-row gap-4 px-6 '>
			<MyAccountIcon />
			<button
				onClick={() => {
					navigate("profile");
					// close profile menu
					closeMenu();
				}}
				className='md:text[16px] text-[18px] md:text-[#F7FCFF] text-[#02466A] whitespace-nowrap cursor-pointer'>
				حسابي
			</button>
		</div>
	);
};

export default ProfileBtn;
