import React, { useContext, Fragment } from "react";
import PropTypes from "prop-types";
import PlaceholderImage from "../../../assets/images/placeholderImage.png";

/* Third party */
import { useNavigate } from "react-router-dom";
/* Context */
import Context from "../../../store/context";
import { DeleteContext } from "../../../store/DeleteProvider";
import { NotificationContext } from "../../../store/NotificationProvider";
import { RolePermissionsContext } from "../../../store/RolePermissionsProvider";
/* Components */
import CircularLoading from "../../../UI/CircularLoading/CircularLoading";
/* MUI */
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Zoom from "@mui/material/Zoom";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
/* Icons */
import { MdOutlineAddBox } from "react-icons/md";
import {
	SortIcon,
	Delete,
	CheckedSquare,
	EditButton,
} from "../../../assets/Icons";
import NewPagination from "../../PaginationComp/NewPagination";
import {
	useAddToStoreCartMutation,
	useStockDeleteAllMutation,
	useStockDeleteItemMutation,
} from "../../../RTK/apiSlices/stockApi";
import { toast } from "react-toastify";
import DeleteOneModalComp from "../../DeleteOneModal/DeleteOneModal";
import DeleteModalComp from "../../DeleteModal/DeleteModal";

const BootstrapTooltip = styled(({ className, ...props }) => (
	<Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
	[`& .${tooltipClasses.arrow}`]: {
		color: "#3AE374",
	},
	[`& .${tooltipClasses.tooltip}`]: {
		backgroundColor: "#3AE374",
	},
}));

function descendingComparator(a, b, orderBy) {
	if (b[orderBy] < a[orderBy]) {
		return -1;
	}
	if (b[orderBy] > a[orderBy]) {
		return 1;
	}
	return 0;
}

function getComparator(order, orderBy) {
	return order === "desc"
		? (a, b) => descendingComparator(a, b, orderBy)
		: (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
	const stabilizedThis = array?.map((el, index) => [el, index]);
	stabilizedThis?.sort((a, b) => {
		const order = comparator(a[0], b[0]);
		if (order !== 0) {
			return order;
		}
		return a[1] - b[1];
	});
	return stabilizedThis?.map((el) => el[0]);
}

const headCells = [
	{
		id: "action",
		numeric: false,
		disablePadding: false,
		label: "الإجراء",
		width: "5rem",
	},
	{
		id: "price",
		numeric: false,
		disablePadding: false,
		label: "سعر الوحدة",
		sort: false,
	},
	{
		id: "quantity",
		numeric: true,
		disablePadding: false,
		label: "الكمية",
		sort: true,
	},
	{
		id: "activity",
		numeric: true,
		disablePadding: false,
		label: "الأنشطة",
		sort: true,
	},
	{
		id: "name",
		numeric: true,
		disablePadding: false,
		label: "اسم المنتج",
	},
	{
		id: "number",
		numeric: true,
		disablePadding: false,
		label: "م",
	},
];

function EnhancedTableHead(props) {
	const { order, orderBy, onRequestSort } = props;
	const createSortHandler = (property) => (event) => {
		onRequestSort(event, property);
	};

	return (
		<TableHead sx={{ backgroundColor: "rgba(209,243,221)" }}>
			<TableRow>
				{headCells?.map((headCell) => (
					<TableCell
						className='md:text-[18px] text-[16px] font-medium'
						key={headCell.id}
						align={headCell.numeric ? "right" : "center"}
						padding={headCell.disablePadding ? "none" : "normal"}
						sortDirection={orderBy === headCell.id ? order : false}
						sx={{
							width: headCell.width ? headCell.width : "auto",
							color: "#02466A",
							whiteSpace: "nowrap",
						}}>
						{headCell.sort && (
							<TableSortLabel
								IconComponent={() => {
									return <SortIcon />;
								}}
								active={orderBy === headCell.id}
								direction={orderBy === headCell.id ? order : "asc"}
								onClick={createSortHandler(headCell.id)}>
								{headCell.label}
								{!orderBy === headCell.id ? (
									<Box component='span'>
										{order === "desc"
											? "sorted descending"
											: "sorted ascending"}
									</Box>
								) : null}
							</TableSortLabel>
						)}
						{!headCell.sort && headCell.label}
					</TableCell>
				))}
				<TableCell padding={"none"}></TableCell>
			</TableRow>
		</TableHead>
	);
}

EnhancedTableHead.propTypes = {
	numSelected: PropTypes.number.isRequired,
	onRequestSort: PropTypes.func.isRequired,
	onSelectAllClick: PropTypes.func.isRequired,
	order: PropTypes.oneOf(["asc", "desc"]).isRequired,
	orderBy: PropTypes.string.isRequired,
	rowCount: PropTypes.number,
};

function EnhancedTableToolbar(props) {
	const { numSelected, rowCount, onSelectAllClick, itemsSelected } = props;
	const NotificationStore = useContext(NotificationContext);
	const { setNotificationTitle, setActionType, setItems } = NotificationStore;

	const RolePermissionsStore = useContext(RolePermissionsContext);
	const { permissions } = RolePermissionsStore;
	return (
		<Toolbar
			sx={{
				pl: { sm: 2 },
				pr: { xs: 1, sm: 1 },
				...(numSelected > 0 && {
					bgcolor: (theme) =>
						alpha(
							theme.palette.primary.main,
							theme.palette.action.activatedOpacity
						),
				}),
				display: "flex",
				gap: "2rem",
				justifyContent: "flex-end",
			}}>
			{permissions?.includes("admin.stock.deleteall") && (
				<div className='flex items-center gap-2'>
					{numSelected > 0 && (
						<Tooltip
							onClick={() => {
								setNotificationTitle(
									"سيتم حذف جميع المنتجات التي قمت بتحديدها"
								);
								setItems(itemsSelected);
								setActionType("deleteAll");
							}}
							title='Delete'>
							<div
								className='rounded-full fcc'
								style={{
									width: "130px",
									backgroundColor: "rgba(255, 56, 56, 0.1)",
									cursor: "pointer",
								}}>
								<h2
									className={"font-medium md:text-[18px] text-[16px]"}
									style={{ color: "#FF3838" }}>
									حذف الكل
								</h2>
								<IconButton>
									<Delete
										style={{
											cursor: "pointer",
											color: "red",
											fontSize: "0.8rem",
										}}></Delete>
								</IconButton>
							</div>
						</Tooltip>
					)}
				</div>
			)}

			<div className='flex items-center'>
				<h2 className='font-medium md:text-[18px] text-[16px]'>تحديد الكل</h2>
				<Checkbox
					checkedIcon={<CheckedSquare />}
					sx={{
						pr: "0",
						color: "#011723",
						"& .MuiSvgIcon-root": {
							color: "#011723",
						},
					}}
					indeterminate={numSelected > 0 && numSelected < rowCount}
					checked={rowCount > 0 && numSelected === rowCount}
					onChange={onSelectAllClick}
					inputProps={{
						"aria-label": "select all desserts",
					}}
				/>
			</div>
		</Toolbar>
	);
}

EnhancedTableToolbar.propTypes = {
	numSelected: PropTypes.number.isRequired,
};

export default function EnhancedTable({
	data,
	loading,
	pageNumber,
	setPageNumber,
	currentPage,
	setCurrentPage,
	page_count,
	search,
}) {
	const navigate = useNavigate();
	const NotificationStore = useContext(NotificationContext);
	const DeleteStore = useContext(DeleteContext);
	const { setItemId, setActionDelete, actionDelete } = DeleteStore;
	const { notificationTitle } = NotificationStore;

	const RolePermissionsStore = useContext(RolePermissionsContext);
	const { permissions } = RolePermissionsStore;
	const contextStore = useContext(Context);
	const { setEndActionTitle } = contextStore;
	const [order, setOrder] = React.useState("asc");
	const [orderBy, setOrderBy] = React.useState("calories");
	const [selected, setSelected] = React.useState([]);
	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(10);

	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === "asc";
		setOrder(isAsc ? "desc" : "asc");
		setOrderBy(property);
	};

	const handleSelectAllClick = (event) => {
		if (event.target.checked) {
			const newSelected = data?.map((n) => n.id);
			setSelected(newSelected);
			return;
		}
		setSelected([]);
	};

	const handleClick = (event, id) => {
		const selectedIndex = selected.indexOf(id);
		let newSelected = [];

		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selected, id);
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(selected.slice(1));
		} else if (selectedIndex === selected.length - 1) {
			newSelected = newSelected.concat(selected.slice(0, -1));
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(
				selected.slice(0, selectedIndex),
				selected.slice(selectedIndex + 1)
			);
		}
		setSelected(newSelected);
	};
	const isSelected = (id) => selected.indexOf(id) !== -1;

	const emptyRows =
		page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data?.length) : 0;

	const [addToStoreCart] = useAddToStoreCartMutation();

	const addToStoreCartHandler = async (id) => {
		try {
			await addToStoreCart({ id })
				.unwrap()
				.then((data) => {
					if (data.success) {
						setEndActionTitle(data.message.ar);
					}
				});
		} catch (err) {
			console.error("Failed to delete the category", err);
		}
	};

	const addToStore = (id) => {
		addToStoreCartHandler(id);
	};

	const [stockDeleteAll] = useStockDeleteAllMutation();
	const handleDeleteAllItems = async (selected) => {
		const queryParams = selected?.map((id) => `id[]=${id}`).join("&");

		try {
			await stockDeleteAll({ queryParams })
				.unwrap()
				.then((data) => {
					if (data.success) {
						setEndActionTitle(data.message.ar);
					}
				});
		} catch (err) {
			console.error("Failed to delete the category", err);
		}
	};

	const [stockDeleteItem] = useStockDeleteItemMutation();
	// delete item
	const handleDeleteSingleItem = async (id) => {
		try {
			await stockDeleteItem({ id })
				.unwrap()
				.then((data) => {
					if (!data?.success) {
						toast.error(data?.message?.ar, {
							theme: "light",
						});
					} else {
						setEndActionTitle(data?.message?.ar);
					}
				});
		} catch (err) {
			console.error("Failed to delete", err);
		}
	};

	return (
		<Box sx={{ width: "100%" }}>
			<Paper sx={{ width: "100%", mb: 2 }}>
				<EnhancedTableToolbar
					itemsSelected={selected}
					numSelected={selected.length || 0}
					rowCount={data?.length || 0}
					onSelectAllClick={handleSelectAllClick}
				/>
				<TableContainer>
					<Table
						sx={{ minWidth: 750 }}
						aria-labelledby='tableTitle'
						size={"medium"}>
						<EnhancedTableHead
							numSelected={selected.length || 0}
							order={order}
							orderBy={orderBy}
							onSelectAllClick={handleSelectAllClick}
							onRequestSort={handleRequestSort}
							rowCount={data?.length || 0}
						/>
						<TableBody>
							{loading ? (
								<TableRow>
									<TableCell colSpan={9}>
										<CircularLoading />
									</TableCell>
								</TableRow>
							) : (
								<Fragment>
									{data?.length === 0 ? (
										<TableRow>
											<TableCell colSpan={9}>
												<p className='text-center text-[18px]'>لاتوجد بيانات</p>
											</TableCell>
										</TableRow>
									) : (
										stableSort(data, getComparator(order, orderBy))?.map(
											(row, index) => {
												const isItemSelected = isSelected(row?.id);
												const labelId = `enhanced-table-checkbox-${index}`;

												return (
													<TableRow
														hover
														role='checkbox'
														aria-checked={isItemSelected}
														tabIndex={-1}
														key={row.id}
														selected={isItemSelected}>
														<TableCell component='th' id={labelId} scope='row'>
															<div className='flex items-center gap-1'>
																{permissions?.includes(
																	"admin.stock.deleteall"
																) && (
																	<Delete
																		title='حذف'
																		onClick={() => {
																			setActionDelete(
																				"سيتم حذف المنتج وهذة الخطوة غير قابلة للرجوع"
																			);
																			setItemId(row?.id);
																		}}
																		style={{
																			cursor: "pointer",
																			color: "red",
																			fontSize: "1.2rem",
																		}}
																	/>
																)}
																{permissions?.includes(
																	"admin.stock.update"
																) && (
																	<EditButton
																		title='تعديل'
																		className='cursor-pointer'
																		onClick={() => {
																			navigate(
																				`/storage/edit-product/${row?.id}`
																			);
																		}}
																		width={"20px"}
																	/>
																)}
																{permissions?.includes(
																	"admin.stock.addToStore"
																) && (
																	<BootstrapTooltip
																		// style={{ backgroundColor: "#3AE374" }}
																		className={"p-0"}
																		TransitionProps={{ timeout: 300 }}
																		TransitionComponent={Zoom}
																		title='اضف للسوق'
																		placement='top-start'
																		onClick={() => {
																			addToStore(row?.id);
																		}}>
																		<IconButton>
																			<MdOutlineAddBox
																				color='#1DBBBE'
																				size={"20px"}
																			/>
																		</IconButton>
																	</BootstrapTooltip>
																)}
															</div>
														</TableCell>
														<TableCell align='center'>
															<div className=''>
																<h2
																	dir='rtl'
																	className='md:text-[18px] text-[16px] font-normal whitespace-nowrap'>
																	<span className='ml-1'>
																		{row?.selling_price}
																	</span>
																	<span>ر.س</span>
																</h2>
															</div>
														</TableCell>
														<TableCell align='right'>
															<div>
																<h2 className='md:text-[18px] text-[16px] font-normal whitespace-nowrap'>
																	{row?.stock}
																</h2>
															</div>
														</TableCell>
														<TableCell
															align='right'
															sx={{
																display: "flex",
																flexDirection: "row-reverse",
																alignItems: "center",
																gap: "0.5rem",
																p: "24px 0",
															}}>
															<div>
																{row?.category?.icon && (
																	<div className='img_icons'>
																		<img
																			src={row.category?.icon}
																			alt={row?.name}
																			loading='lazy'
																		/>
																	</div>
																)}
															</div>
															<h2 className='md:text-[18px] text-[16px] font-normal whitespace-nowrap'>
																{row.category?.name}
															</h2>
														</TableCell>
														<TableCell
															align='right'
															className='min-w-[240px] max-w-[240px]'>
															<div className='flex items-center justify-end gap-3'>
																<h2
																	style={{
																		textOverflow: "ellipsis",
																		direction: "rtl",
																	}}
																	className='w-full md:text-[18px] text-[16px] font-normal whitespace-nowrap overflow-hidden'>
																	{row?.name}
																</h2>

																<div className='img_icons'>
																	<img
																		src={
																			row?.cover ? row?.cover : PlaceholderImage
																		}
																		alt={row?.name}
																		loading='lazy'
																	/>
																</div>
															</div>
														</TableCell>

														<TableCell
															align='right'
															className='md:text-[18px] text-[16px] font-normal whitespace-nowrap'>
															{(index + 1).toLocaleString("en-US", {
																minimumIntegerDigits: 2,
																useGrouping: false,
															})}
														</TableCell>
														<TableCell padding='none' align={"right"}>
															<Checkbox
																checkedIcon={<CheckedSquare />}
																sx={{
																	color: "#011723",
																	"& .MuiSvgIcon-root": {
																		color: "#011723",
																	},
																}}
																checked={isItemSelected}
																onClick={(event) => handleClick(event, row.id)}
																inputProps={{
																	"aria-labelledby": labelId,
																}}
															/>
														</TableCell>
													</TableRow>
												);
											}
										)
									)}
									{emptyRows > 0 && (
										<TableRow
											style={{
												height: 53 * emptyRows,
											}}>
											<TableCell colSpan={6} />
										</TableRow>
									)}
								</Fragment>
							)}
						</TableBody>
					</Table>
				</TableContainer>
			</Paper>
			{search ? null : (
				<NewPagination
					pageNumber={pageNumber}
					setPageNumber={setPageNumber}
					currentPage={currentPage}
					setCurrentPage={setCurrentPage}
					page_count={page_count}
				/>
			)}
			{actionDelete && (
				<DeleteOneModalComp handleDeleteSingleItem={handleDeleteSingleItem} />
			)}
			{notificationTitle && (
				<DeleteModalComp handleDeleteAllItems={handleDeleteAllItems} />
			)}
		</Box>
	);
}
