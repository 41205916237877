import React, { useState } from "react";

// login styles
import styles from "./Login.module.css";

/* Third party */
import { Navigate, useNavigate } from "react-router-dom";

/* Custom Button */
import Button from "../../UI/Button/Button";

/* Icons */
import Logo from "../../assets/images/Login_Logo.svg";
import { PasswordClose, PasswordOpen } from "../../assets/Icons";
import { useLoginMutation } from "../../RTK/apiSlices/loginApi";

const MainPage = () => {
	const navigate = useNavigate();
	const token = localStorage.getItem("token");
	const [username, setUserName] = useState("");
	const [password, setPassword] = useState("");
	const [usernameError, setUsernameError] = useState("");
	const [passwordError, setPasswordError] = useState("");
	const [error, setError] = useState("");
	const [showPassword, setShowPassword] = React.useState(false);

	// handle login
	const [login, { isLoading }] = useLoginMutation();
	const handleLogin = async () => {
		setError("");
		setUsernameError("");
		setPasswordError("");

		try {
			// Call the login mutation with the provided credentials
			const res = await login({ user_name: username, password }).unwrap();

			if (res?.success === true && res?.data?.status === 200) {
				localStorage.setItem("token", res?.data?.token);

				localStorage?.setItem(
					"permissions",
					JSON.stringify(
						res?.data?.user?.role?.permissions?.map(
							(permissions) => permissions?.name
						)
					)
				);
				navigate("/");
			} else {
				setUsernameError(res?.message?.en?.user_name?.[0]);
				setPasswordError(res?.message?.en?.password?.[0]);
				setError(res?.message?.ar);
			}
		} catch (error) {
			console.error("Login error:", error);
			// Handle login error
			setError("An error occurred during login.");
		}
	};

	const handleKeyDown = (event) => {
		if (event.key === "Enter") {
			event.preventDefault();
			handleLogin();
		}
	};

	return token ? (
		<Navigate to='/' />
	) : (
		<div className={`${styles.login_page}`}>
			<div className='md:w-[550px] w-full max-w-full flex flex-col items-center md:p-16 p-8 bg-white rounded-xl'>
				<div className='flex flex-col items-center justify-center w-[200px]'>
					<img className='w-full' src={Logo} alt='logo' loading='lazy' />
				</div>
				<div className='w-full'>
					<div className='flex flex-col'>
						<input
							className={`${styles.input} md:h-[55px] h-[45px] md:text-[18px] text-[16px] mb-4`}
							placeholder='ادخل اسم المستخدم'
							type='text'
							name='username'
							value={username}
							onKeyDown={handleKeyDown}
							onChange={(e) => {
								setUserName(e.target.value);
							}}
						/>
						{usernameError && (
							<span className='text-red-500'>{usernameError}</span>
						)}
					</div>
					<div className='flex flex-col'>
						<div
							className={`${styles.input} flex flex-row items-center justify-between md:h-[55px] h-[45px] md:text-[18px] text-[16px] mb-4`}>
							<input
								className={`w-full h-full md:text-[18px] text-[16px] outline-none border-none`}
								placeholder='ادخل كلمة المرور'
								type={showPassword ? "text" : "password"}
								name='password'
								value={password}
								onKeyDown={handleKeyDown}
								onChange={(e) => {
									setPassword(e.target.value);
								}}
							/>
							{showPassword ? (
								<PasswordOpen
									className={styles.password}
									onClick={() => setShowPassword(!showPassword)}
								/>
							) : (
								<PasswordClose
									className={styles.password}
									onClick={() => setShowPassword(!showPassword)}
								/>
							)}
						</div>

						{passwordError && (
							<span className='text-red-500'>{passwordError}</span>
						)}

						{error && <span className='text-red-500'>{error}</span>}
					</div>
					<Button
						type={"normal"}
						disabled={isLoading || (!username && !password)}
						className={"text-center w-full mt-12 md:h-14 h-[44px] text-xl"}
						style={{ backgroundColor: "#02466A" }}
						onClick={handleLogin}>
						تسجيل الدخول
					</Button>
				</div>
			</div>
		</div>
	);
};

export default MainPage;
