import React, { useState, useEffect, useContext } from "react";

/* Contexts */
import { LoadingContext } from "../../../store/LoadingProvider";
import { RolePermissionsContext } from "../../../store/RolePermissionsProvider";

/* MUI */
import Box from "@mui/material/Box";
import TabContext from "@mui/lab/TabContext";

// RTK Query
import { useSectionUpdateMutation } from "../../../RTK/apiSlices/templateApi";

// Components
import PartitionsTabs from "./PartitionsTabs";
import PartitionsBox from "./PartitionsBox";
import Buttons from "./Buttons";

const PartitionsSections = ({ fetchedData }) => {
	const LoadingStore = useContext(LoadingContext);
	const { setLoadingTitle } = LoadingStore;
	const RolePermissionsStore = useContext(RolePermissionsContext);
	const { permissions } = RolePermissionsStore;

	const [currTab, setCurrTab] = useState("0");
	const [partitions, setPartitions] = useState([]);

	// to get all partitions data
	useEffect(() => {
		if (fetchedData?.Sections) {
			setPartitions(
				fetchedData?.Sections?.map((pa) => {
					return {
						id: pa?.id,
						name: pa?.name,
						status: pa?.status || "",
						category_id: pa?.category?.id || pa?.category_id || "",
					};
				})
			);
		}
	}, [fetchedData?.Sections]);

	// Handle Tab Change
	const handleSelectCurrPartition = (e, id) => {
		setCurrTab(id);
	};

	const handleOnchangePartition = (partition) => {
		const updatedPartitions = partitions.map((pa) => {
			if (pa.id === partition.id) {
				return partition;
			}

			return pa;
		});
		setPartitions(updatedPartitions);
	};

	//  send data to api
	const [sectionUpdate] = useSectionUpdateMutation();
	const sectionUpdateHandel = async (formData) => {
		try {
			const res = await sectionUpdate({
				body: formData,
			});
			if (res?.data?.data?.status === 200) {
				setLoadingTitle(null);
			} else {
				setLoadingTitle(null);
			}
		} catch (error) {
			console.error(error);
		}
	};

	const updatePartitions = () => {
		setLoadingTitle("جاري تعديل التقسيم");

		const formData = new FormData();

		partitions.forEach((pa, i) => {
			formData.append(`data[${i}][id]`, pa?.id);

			if (!pa?.category_id) {
				formData.append(`data[${i}][name]`, pa?.name);
			}
			formData.append(
				`data[${i}][status]`,
				pa?.status === "نشط" ? "active" : "not_active"
			);

			if (pa?.category_id)
				formData.append(`data[${i}][category_id]`, pa?.category_id);
		});

		sectionUpdateHandel(formData);
	};

	return (
		<Box
			className='p-4 lg:pr-20 lg:pt-5 lg:pl-24'
			style={{ backgroundColor: "#fff" }}>
			<TabContext value={currTab.toString()}>
				<PartitionsTabs
					onChangeTab={handleSelectCurrPartition}
					sections={fetchedData?.Sections}
				/>

				<PartitionsBox
					currTab={currTab.toString()}
					partitions={partitions}
					permissions={permissions}
					onChangeTab={handleOnchangePartition}
					currPartition={partitions[currTab]}
				/>
			</TabContext>

			{(permissions?.includes("admin.section.sectionupdate") ||
				permissions?.includes("admin.section.changestatus")) && (
				<Buttons updatePartitions={updatePartitions} />
			)}
		</Box>
	);
};

export default PartitionsSections;
