import React, { useState, useContext, useEffect } from "react";
/* Third party */
import ImageUploading from "react-images-uploading";
import { toast } from "react-toastify";
/* Contexts */

import { LoadingContext } from "../../../../store/LoadingProvider";
/* Custom Button */
import Button from "../../../../UI/Button/Button";
/* MUI */
import Switch from "@mui/material/Switch";
import CircularProgress from "@mui/material/CircularProgress";
/* Icons */
import { MdFileUpload } from "react-icons/md";
import { useSliderUpdateMutation } from "../../../../RTK/apiSlices/templateApi";

// change sliders and banners Switch styles
const switchStyles = {
	width: "35px",
	padding: 0,
	height: "20px",
	borderRadius: "0.75rem",
	"& .MuiSwitch-thumb": {
		width: "12px",
		height: "12px",
	},
	"& .MuiSwitch-switchBase": {
		padding: "0",
		top: "4px",
		left: "4px",
	},
	"& .MuiSwitch-switchBase.Mui-checked": {
		left: "-4px",
	},
	"& .Mui-checked .MuiSwitch-thumb": {
		backgroundColor: "#FFFFFF",
	},
	"& .MuiSwitch-track": {
		height: "100%",
	},
	"&.MuiSwitch-root .Mui-checked+.MuiSwitch-track": {
		backgroundColor: "#3AE374",
		opacity: 1,
	},
};

const ChangeSliderSec = ({ fetchedData, loading }) => {
	const LoadingStore = useContext(LoadingContext);
	const { setLoadingTitle } = LoadingStore;

	const [sliderstatus1, setSliderStatus1] = useState(true);
	const [sliderstatus2, setSliderStatus2] = useState(true);
	const [sliderstatus3, setSliderStatus3] = useState(true);
	const [slider1, setSlider1] = useState("");
	const [slider2, setSlider2] = useState("");
	const [slider3, setSlider3] = useState("");

	useEffect(() => {
		setSlider1(fetchedData?.Homepages?.slider1);
		setSliderStatus1(
			fetchedData?.Homepages?.sliderstatus1 === "active" ? true : false
		);
		setSlider2(fetchedData?.Homepages?.slider2);
		setSliderStatus2(
			fetchedData?.Homepages?.sliderstatus2 === "active" ? true : false
		);
		setSlider3(fetchedData?.Homepages?.slider3);
		setSliderStatus3(
			fetchedData?.Homepages?.sliderstatus3 === "active" ? true : false
		);
	}, [
		fetchedData?.Homepages?.sliderstatus1,
		fetchedData?.Homepages?.slider1,
		fetchedData?.Homepages?.sliderstatus2,
		fetchedData?.Homepages?.slider2,
		fetchedData?.Homepages?.sliderstatus3,
		fetchedData?.Homepages?.slider3,
	]);

	// TO UPLOAD BANNERS
	const [firstimage, setFirstImage] = useState([]);
	const [secondimage, setSecondImage] = useState([]);
	const [thirdimage, setThirdImage] = useState([]);
	const [previewImage, setPreviewImage] = useState("");
	const maxFileSize = 1 * 1024 * 1024; // 1 MB;

	// to handle width and height of banners
	const handleImageUpload =
		(bannerIndex, bannerState, setBannerState, setPreviewBannerState) =>
		async (imageList) => {
			// Check if the image size is valid
			const isSizeValid = imageList?.every(
				(image) => image?.file?.size <= maxFileSize
			);

			// If the image size is not valid
			if (!isSizeValid) {
				// Display a warning message and reset the logo state
				toast.warning("حجم البانر يجب أن لا يزيد عن 1 ميجابايت.", {
					theme: "light",
				});
				return;
			}

			const dimensionsErrorMessage =
				"مقاس البنر يجب أن يكون 1920 بكسل عرض و530 بكسل ارتفاع.";

			const checkImageDimensions = (image) =>
				new Promise((resolve) => {
					const img = new Image();
					img.onload = () => {
						if (img?.width !== 1920 && img?.height !== 530) {
							toast.warning(dimensionsErrorMessage, {
								theme: "light",
							});
							//  if the image dimensions is not valid
							resolve(false);
						} else {
							resolve(true);
						}
					};
					img.src = image?.data_url;
				});

			const isValidDimensions = await Promise?.all(
				imageList?.map(checkImageDimensions)
			).then((results) => results?.every((result) => result));

			// if the isValidDimensions and  imageSize >= maxFileSize return
			if (!isValidDimensions) {
				return;
			}

			if (!isValidDimensions) {
				toast.warning(dimensionsErrorMessage, {
					theme: "light",
				});
			} else {
				const updatedSliderState = [...bannerState];
				updatedSliderState[bannerIndex] = imageList;
				setBannerState(updatedSliderState);
				const updatedNameState = updatedSliderState[bannerIndex];
				const bannerNames = [setFirstImage, setSecondImage, setThirdImage];

				if (bannerNames[bannerIndex]) {
					bannerNames[bannerIndex](updatedNameState);
				}
			}
			setPreviewBannerState(imageList);
		};

	const [sliderUpdate] = useSliderUpdateMutation();
	const sliderUpdateHandel = async (formData) => {
		try {
			const res = await sliderUpdate({
				body: formData,
			});
			if (res?.data?.data?.status === 200) {
				setLoadingTitle(null);
			} else {
				setLoadingTitle(null);
			}
		} catch (error) {
			console.error(error);
		}
	};

	const updateSliders = () => {
		setLoadingTitle("جاري تعديل السلايدر");
		const formData = new FormData();
		formData.append("slider1", firstimage[0]?.file || slider1 || null);
		formData.append("slider2", secondimage[0]?.file || slider2 || null);
		formData.append("slider3", thirdimage[0]?.file || slider3 || null);
		formData.append("sliderstatus1", sliderstatus1 ? "active" : "not_active");
		formData.append("sliderstatus2", sliderstatus2 ? "active" : "not_active");
		formData.append("sliderstatus3", sliderstatus3 ? "active" : "not_active");
		sliderUpdateHandel(formData);
	};

	return (
		<div
			className='mt-8 rounded-lg shadow-md '
			style={{ backgroundColor: "#FFFFFF" }}>
			<div
				className='px-3 py-2 rounded-t-lg md:p-5'
				style={{ backgroundColor: "#F7FCFF", border: "1px solid #EFEFEF" }}>
				<h2 className='md:text-xl text-[16px] font-medium flex items-center whitespace-nowrap flex-wrap gap-3'>
					السلايدر المتحرك (530 * 1920)
					<span className='md:text-base text-[14px] font-normal text-[#67747B] whitespace-pre-line'>
						(تستطيع تغيير الصورة التي تظهر في السلايدر المتحرك أعلى الموقع)
					</span>
				</h2>
			</div>
			<div className='flex flex-col max-w-full px-4 xl:pt-10 xl:pb-28 xl:pr-24 py-7'>
				<ImageUploading
					value={firstimage}
					onChange={handleImageUpload(
						0,
						firstimage,
						setFirstImage,
						setPreviewImage
					)}
					maxNumber={2}
					dataURLKey='data_url'
					acceptType={["jpg", "png", "jpeg", "webp"]}>
					{({ onImageUpload }) => (
						// write your building UI
						<div className='relative upload__image-wrapper ' style={{}}>
							<div className='w-full image-item '>
								<div className='flex'>
									<div className='xl:w-[274px] w-0'></div>
									<div
										style={{ height: "166px", width: "572px" }}
										className='flex flex-col items-center justify-center gap-6 '>
										{loading ? (
											<CircularProgress />
										) : (
											<>
												{(previewImage[0] || slider1) && (
													<img
														src={
															previewImage[0]?.data_url ||
															slider1 ||
															"https://placehold.co/1920x480"
														}
														alt='preview-img'
														className='object-contain w-full h-full'
														loading='lazy'
													/>
												)}
											</>
										)}
									</div>
								</div>
								<div className='flex flex-col gap-4 mt-4'>
									<div className='flex flex-col items-start lg:flex-row lg:items-center gap-y-2'>
										<h2 className='lg:text-center font-normal text-lg text-[#67747B] lg:w-[274px] w-full'>
											سلايدر متحرك رقم 1
										</h2>
										<div className='flex flex-col max-w-full gap-2'>
											<span className='text-gray-400 font-light text-[14px]'>
												الحد الأقصى للبانر 1MB
											</span>
											<div className='flex flex-row items-center max-w-full'>
												<div
													onClick={() => {
														onImageUpload();
													}}
													className='flex items-center justify-between p-4 rounded cursor-pointer h-11'
													style={{
														backgroundColor: "#FAFAFA",
														border: "1px dashed #E0E0E0",
														width: "572px",
														overflow: "hidden",
													}}>
													{firstimage?.[0]?.file ? (
														<div className='' style={{ width: "55px" }}>
															<img
																src={URL.createObjectURL(firstimage?.[0]?.file)}
																alt=''
																className='img-fluid'
																loading='lazy'
															/>
														</div>
													) : slider1 ? (
														<div className='' style={{ width: "55px" }}>
															<img
																src={slider1}
																alt=''
																className=' img-fluid'
																loading='lazy'
															/>
														</div>
													) : (
														<h2
															className='text-base font-medium'
															style={{ color: "#67747B" }}>
															تحديث السلايدر
														</h2>
													)}
													<MdFileUpload
														color='#02466A'
														size={"1.25rem"}></MdFileUpload>
												</div>
												<div className='mr-4'>
													<Switch
														sx={switchStyles}
														checked={sliderstatus1}
														onChange={() => {
															if (
																sliderstatus2 === false &&
																sliderstatus3 === false
															) {
																toast.warn(
																	"يجب أن يكون على الأقل سلايدر واحد مفعل"
																);
															} else {
																setSliderStatus1(!sliderstatus1);
															}
														}}
													/>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					)}
				</ImageUploading>
				<ImageUploading
					value={secondimage}
					onChange={handleImageUpload(
						1,
						secondimage,
						setSecondImage,
						setPreviewImage
					)}
					maxNumber={2}
					dataURLKey='data_url'
					acceptType={["jpg", "png", "jpeg", "webp"]}>
					{({ onImageUpload }) => (
						// write your building UI
						<div className='relative max-w-full upload__image-wrapper '>
							<div className='w-full image-item'>
								<div className='flex flex-col gap-4 mt-4'>
									<div className='flex flex-col items-start lg:flex-row lg:items-center gap-y-2'>
										<h2 className='lg:text-center font-normal text-lg text-[#67747B] lg:w-[274px] w-full'>
											سلايدر متحرك رقم 2
										</h2>
										<div className='flex flex-row items-center max-w-full'>
											<div
												onClick={() => {
													onImageUpload();
												}}
												className='flex items-center justify-between p-4 rounded cursor-pointer h-11'
												style={{
													backgroundColor: "#FAFAFA",
													border: "1px dashed #E0E0E0",
													width: "572px",
													overflow: "hidden",
												}}>
												{secondimage?.[0]?.file ? (
													<div className='' style={{ width: "55px" }}>
														<img
															src={URL.createObjectURL(secondimage?.[0]?.file)}
															alt=''
															className='img-fluid'
															loading='lazy'
														/>
													</div>
												) : slider2 ? (
													<div className='' style={{ width: "55px" }}>
														<img
															src={slider2}
															alt=''
															className='img-fluid'
															loading='lazy'
														/>
													</div>
												) : (
													<h2
														className='text-base font-medium'
														style={{ color: "#67747B" }}>
														تحديث السلايدر
													</h2>
												)}
												<MdFileUpload
													color='#02466A'
													size={"1.25rem"}></MdFileUpload>
											</div>
											<div className='mr-4'>
												<Switch
													sx={switchStyles}
													checked={sliderstatus2}
													onChange={() => {
														if (
															sliderstatus1 === false &&
															sliderstatus3 === false
														) {
															toast.warn(
																"يجب أن يكون على الأقل سلايدر واحد مفعل"
															);
														} else {
															setSliderStatus2(!sliderstatus2);
														}
													}}
												/>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					)}
				</ImageUploading>
				<ImageUploading
					value={thirdimage}
					onChange={handleImageUpload(
						2,
						thirdimage,
						setThirdImage,
						setPreviewImage
					)}
					maxNumber={2}
					dataURLKey='data_url'
					acceptType={["jpg", "png", "jpeg", "webp"]}>
					{({ onImageUpload }) => (
						// write your building UI
						<div className='relative max-w-full upload__image-wrapper '>
							<div className='w-full image-item'>
								<div className='flex flex-col gap-4 mt-4'>
									<div className='flex flex-col items-start lg:flex-row lg:items-center gap-y-2'>
										<h2 className='lg:text-center font-normal text-lg text-[#67747B] lg:w-[274px] w-full'>
											سلايدر متحرك رقم 3
										</h2>
										<div className='flex flex-row items-center max-w-full'>
											<div
												onClick={() => {
													onImageUpload();
												}}
												className='flex items-center justify-between p-4 rounded cursor-pointer h-11'
												style={{
													backgroundColor: "#FAFAFA",
													border: "1px dashed #E0E0E0",
													width: "572px",
													overflow: "hidden",
												}}>
												{thirdimage?.[0]?.file ? (
													<div className='' style={{ width: "55px" }}>
														<img
															src={URL.createObjectURL(thirdimage?.[0]?.file)}
															alt=''
															className='img-fluid'
															loading='lazy'
														/>
													</div>
												) : slider3 ? (
													<div className='' style={{ width: "55px" }}>
														<img
															src={slider3}
															alt=''
															className=' img-fluid'
															loading='lazy'
														/>
													</div>
												) : (
													<h2
														className='text-base font-medium'
														style={{ color: "#67747B" }}>
														تحديث السلايدر
													</h2>
												)}
												<MdFileUpload
													color='#02466A'
													size={"1.25rem"}></MdFileUpload>
											</div>
											<div className='mr-4'>
												<Switch
													sx={switchStyles}
													checked={sliderstatus3}
													onChange={() => {
														if (
															sliderstatus1 === false &&
															sliderstatus2 === false
														) {
															toast.warn(
																"يجب أن يكون على الأقل سلايدر واحد مفعل"
															);
														} else {
															setSliderStatus3(!sliderstatus3);
														}
													}}
												/>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					)}
				</ImageUploading>
				<Button
					onClick={updateSliders}
					className={
						"mx-auto mt-9 md:w-1/4 w-11/12 md:h-14 h-[45px] md:text-2xl text-xl"
					}
					fontSize={"text-2xl font-thin"}
					style={{ backgroundColor: "#02466A" }}
					type={"normal"}>
					حفظ
				</Button>
			</div>
		</div>
	);
};

export default ChangeSliderSec;
