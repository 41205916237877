import React, { useState, useEffect } from "react";
import styles from "./EditVerificationData.module.css";
/* Third party */
import { toast } from "react-toastify";
import ImageUploading from "react-images-uploading";
import { useNavigate, useParams } from "react-router-dom";

/* Components */
import CircularLoading from "../../../../UI/CircularLoading/CircularLoading";
import VerificationModal from "../VerificationModal/VerificationModal";
/* Custom Button */
import Button from "../../../../UI/Button/Button";
/* MUI */
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
/* Icons */
import { IoMdCloseCircleOutline } from "react-icons/io";
import { PDF, Arrow, PhoneIcon } from "../../../../assets/Icons/index";
import {
	useGetVerificationDataQuery,
	useVerificationUpdateMutation,
} from "../../../../RTK/apiSlices/verificationApi";
import {
	useAdminSelectorEtlobahCategoryQuery,
	useSubCategoriesDataQuery,
} from "../../../../RTK/apiSlices/selectorApi";

const BackDrop = ({ onClick }) => {
	return (
		<div
			onClick={onClick}
			className='fixed top-0 left-0 z-10 w-full h-full back_drop bg-slate-900 opacity-70'></div>
	);
};

const VerificationData = () => {
	const { id } = useParams();
	const navigate = useNavigate();

	const { data: fetchedData, isLoading: loading } = useGetVerificationDataQuery(
		{ verificationId: id }
	);
	const [verification, setVerification] = useState(null);
	const [files, setFiles] = useState([]);
	const [btnLoading, setBtnLoading] = useState(false);
	const onChangeLogoImage = (fileList, addUpdateIndex) => {
		setFiles(fileList);
	};
	const [data, setData] = useState({
		store_name: "",
		store_activity: [],
		store_sub_activity: [],
		owner_name: "",
		verification_code: "",

		email: "",
		phonenumber: "",
		file: "",
		verification_type: "",
	});

	// --------------------------------------------
	const [DataError, setDataError] = useState({
		store_name: "",
		email: "",
		phonenumber: "",
		owner_name: "",
		verification_code: "",

		file: "",
		activity_id: "",
		sub_activity_id: "",
	});
	const resetData = () => {
		setDataError({
			store_name: "",
			email: "",
			phonenumber: "",
			owner_name: "",
			verification_code: "",
			domain: "",

			file: "",
			activity_id: "",
			sub_activity_id: "",
		});
	};
	// ------------------------------------------

	useEffect(() => {
		setData({
			...data,
			email: fetchedData?.store?.user?.email,
			store_name: fetchedData?.store?.store_name,
			verification_code: fetchedData?.store?.verification_code,
			owner_name:
				fetchedData?.store?.verification_type === "maeruf"
					? fetchedData?.store?.owner_name
					: fetchedData?.store?.commercial_name,
			store_activity: fetchedData?.store?.activity?.map((cat) => cat?.id) || [],
			store_sub_activity:
				fetchedData?.store?.subcategory?.map((sub) => sub?.id) || [],
			phonenumber: fetchedData?.store?.user?.phonenumber?.startsWith("+966")
				? fetchedData?.store?.user?.phonenumber?.slice(4)
				: fetchedData?.store?.user?.phonenumber?.startsWith("00966")
				? fetchedData?.store?.user?.phonenumber?.slice(5)
				: fetchedData?.store?.user?.phonenumber,
			verification_type: fetchedData?.store?.verification_type || "",

			file: fetchedData?.store?.file,
		});
	}, [fetchedData?.store]);

	const { data: categories } = useAdminSelectorEtlobahCategoryQuery();

	const queryParams = data?.store_activity
		?.map((id) => `category_id[]=${id}`)
		.join("&");
	const { data: sub_categories } = useSubCategoriesDataQuery({ queryParams });

	const [verificationUpdate] = useVerificationUpdateMutation();
	const verificationUpdateHandel = async (formData) => {
		try {
			const res = await verificationUpdate({
				body: formData,
			});
			if (res?.data?.success === true && res?.data?.data?.status === 200) {
				setVerification("edit");

				setBtnLoading(false);
			} else {
				setBtnLoading(false);
				setDataError({
					email: res?.data?.message?.en?.store_email?.[0],
					store_name: res?.data?.message?.en?.store_name?.[0],
					phonenumber: res?.data?.message?.en?.phonenumber?.[0],
					owner_name:
						res?.data?.message?.en?.owner_name?.[0] ||
						res?.data?.message?.en?.commercial_name?.[0],
					domain: res?.data?.message?.en?.domain?.[0],
					verification_code: res?.data?.message?.en?.verification_code?.[0],
					file: res?.data?.message?.en?.file?.[0],
					activity_id: res?.data?.message?.en?.activity_id?.[0],
					subcategory_id: res?.data?.message?.en?.subcategory_id?.[0],
				});

				toast.error(res?.data?.message?.ar, {
					theme: "light",
				});
				toast.error(res?.data?.message?.en?.store_name?.[0], {
					theme: "light",
				});
				toast.error(res?.data?.message?.en?.store_email?.[0], {
					theme: "light",
				});
				toast.error(res?.data?.message?.en?.phonenumber?.[0], {
					theme: "light",
				});
				toast.error(
					res?.data?.message?.en?.owner_name?.[0] ||
						res?.data?.message?.en?.commercial_name?.[0],
					{
						theme: "light",
					}
				);
				toast.error(res?.data?.message?.en?.verification_code?.[0], {
					theme: "light",
				});
				toast.error(res?.data?.message?.en?.domain?.[0], {
					theme: "light",
				});
				toast.error(res?.data?.message?.en?.file?.[0], {
					theme: "light",
				});
				toast.error(res?.data?.message?.en?.activity_id?.[0], {
					theme: "light",
				});
				toast.error(res?.data?.message?.en?.subcategory_id?.[0], {
					theme: "light",
				});
			}
		} catch (error) {
			console.error(error);
		}
	};

	const updateVerification = () => {
		setBtnLoading(true);
		resetData();
		let formData = new FormData();
		formData.append("store_id", id);
		formData.append("email", data?.email);
		formData.append("store_name", data?.store_name);
		formData.append("phonenumber", `+966${data?.phonenumber}`);

		formData.append("verification_code", data?.verification_code);
		if (data?.verification_type === "maeruf") {
			formData.append("owner_name", data?.owner_name);
		} else {
			formData.append("commercial_name", data?.owner_name);
		}
		if (files.length !== 0) {
			formData.append("file", files[0]?.file || "");
		}
		for (let i = 0; i < data?.store_activity?.length; i++) {
			formData.append([`activity_id[${i}]`], data?.store_activity[i]);
		}
		for (let i = 0; i < data?.store_sub_activity?.length; i++) {
			formData.append([`subcategory_id[${i}]`], data?.store_sub_activity[i]);
		}
		verificationUpdateHandel(formData);
	};

	if (verification === "edit") {
		return (
			<VerificationModal
				title={"سيتم إبلاغ المالك بتعديل توثيق متجره"}
				type={"edit"}
				cancel={() => {
					setVerification(null);
					navigate("/verification");
				}}
			/>
		);
	}

	return (
		<>
			<BackDrop
				onClick={() => {
					navigate("/verification");
				}}
			/>
			<div
				className='fixed flex flex-col top-[15%] translate-x-2/4  right-2/4 z-20 rounded-lg overflow-hidden'
				style={{ width: "60.25rem", maxWidth: "90%" }}>
				<div
					className='flex items-center justify-between w-full h-16 px-4 py-4 trader_alert'
					style={{
						backgroundColor: "#FF9F1A",
					}}>
					<h2
						style={{ color: "#ECFEFF" }}
						className='md:text-[22px] text-[18px] font-medium text-center flex-1'>
						تعديل طلب توثيق متجر
					</h2>
					<IoMdCloseCircleOutline
						width='20px'
						height='20px'
						size={"1.25rem"}
						color={"#fff"}
						className={"cursor-pointer"}
						onClick={() => {
							navigate("/verification");
						}}
					/>
				</div>
				<div className='max-h-[450px] flex-1 flex flex-col md:px-[98px] md:py-[48px] px-4 py-[30px] gap-[24px] bg-white text-right overflow-y-auto'>
					{loading ? (
						<div className='w-full flex flex-col items-center justify-center min-h-[50vh]'>
							<CircularLoading />
						</div>
					) : (
						<>
							<div className='flex flex-col gap-[10px]'>
								<label
									style={{ color: "#011723" }}
									className='md:text-[20px] text-[16px]'
									htmlFor='activity_store'>
									نشاط أو تصنيف المتجر الرئيسي
								</label>
								<FormControl className='w-full md:h-[56px] h-[44px] max-w-full'>
									<Select
										className={styles.select}
										IconComponent={(props) => (
											<Arrow fill='#242424' {...props} />
										)}
										multiple
										displayEmpty
										value={data?.store_activity}
										onChange={(e) => {
											setData({
												...data,
												store_activity: e.target.value,
												sub_activity_ids: [],
											});
										}}
										renderValue={(selected) => {
											if (data?.store_activity?.length === 0) {
												return "اختر نشاط أو تصنيف المتجر الرئيسي";
											}
											return selected?.map((item) => {
												const result = categories?.categories?.filter(
													(cat) => cat?.id === parseInt(item)
												);
												return `${result?.[0]?.name} , `;
											});
										}}
										sx={{
											height: "3.5rem",
											backgroundColor: "#EFF0F0",
											color: "#011723 !important",
											border: "1px solid #F0F0F0",
											borderRadius: "8px",
											"& .MuiOutlinedInput-notchedOutline": {
												border: "none",
											},
										}}>
										{categories?.categories?.map((item, index) => (
											<MenuItem
												className='souq_storge_category_filter_items multiple_select'
												key={index}
												value={item?.id}>
												<Checkbox
													checked={data?.store_activity?.indexOf(item?.id) > -1}
												/>
												<ListItemText primary={item?.name} />
											</MenuItem>
										))}
									</Select>
								</FormControl>
								<span className='text-red-500 text-[16px]'>
									{DataError?.activity_id}
								</span>
							</div>
							<div className='flex flex-col gap-[10px]'>
								<label
									style={{ color: "#011723" }}
									className='md:text-[20px] text-[16px]'
									htmlFor='activity_store'>
									نشاط أو تصنيف المتجر الفرعي
								</label>
								<FormControl className='w-full md:h-[56px] h-[44px] max-w-full'>
									<Select
										className={styles.select}
										IconComponent={(props) => (
											<Arrow fill='#242424' {...props} />
										)}
										multiple
										displayEmpty
										value={data?.store_sub_activity}
										onChange={(e) => {
											setData({
												...data,
												store_sub_activity: e.target.value,
											});
										}}
										renderValue={(selected) => {
											if (data?.store_sub_activity?.length === 0) {
												return "اختر نشاط أو تصنيف المتجر الفرعي";
											}
											return selected?.map((item) => {
												const result = sub_categories?.categories?.filter(
													(cat) => cat?.id === parseInt(item)
												);
												return `${result?.[0]?.name} , `;
											});
										}}
										sx={{
											height: "3.5rem",
											backgroundColor: "#EFF0F0",
											color: "#011723 !important",
											border: "1px solid #F0F0F0",
											borderRadius: "8px",
											"& .MuiOutlinedInput-notchedOutline": {
												border: "none",
											},
										}}>
										{sub_categories?.categories?.map((item, index) => (
											<MenuItem
												className='souq_storge_category_filter_items multiple_select'
												key={index}
												value={item?.id}>
												<Checkbox
													checked={
														data?.store_sub_activity?.indexOf(item?.id) > -1
													}
												/>
												<ListItemText primary={item?.name} />
											</MenuItem>
										))}
									</Select>
								</FormControl>
								<span className='text-red-500 text-[16px]'>
									{DataError?.sub_activity_id}
								</span>
							</div>

							<div className='flex flex-col gap-[10px]'>
								<label
									style={{ color: "#011723" }}
									className='md:text-[20px] text-[16px]'
									htmlFor='store_name'>
									اسم المتجر
								</label>
								<input
									id='store_name'
									className='md:text-[20px] text-[16px] py-[14px] px-[24px] rounded-md outline-none'
									style={{ backgroundColor: "#FAFAFA", color: "#011723" }}
									type='text'
									placeholder='أمازون'
									value={data.store_name}
									onChange={(e) =>
										setData({ ...data, store_name: e.target.value })
									}
								/>
								<span className='text-red-500 text-[16px]'>
									{DataError?.store_name}
								</span>
							</div>

							<div className='flex flex-col gap-[10px]'>
								<label
									style={{ color: "#011723" }}
									className='md:text-[20px] text-[16px]'
									htmlFor='store_email'>
									البريد الالكتروني
								</label>
								<input
									id='store_name'
									className='md:text-[20px] text-[16px] py-[14px] px-[24px] rounded-md outline-none'
									style={{ backgroundColor: "#FAFAFA", color: "#011723" }}
									type='email'
									placeholder='sample@gmail.com'
									value={data.email}
									onChange={(e) => setData({ ...data, email: e.target.value })}
								/>
								<span className='text-red-500 text-[16px]'>
									{DataError?.email}
								</span>
							</div>

							<div className='flex flex-col gap-[10px]'>
								<label
									style={{ color: "#011723" }}
									className='md:text-[20px] text-[16px]'
									htmlFor='email'>
									رقم الجوال
								</label>

								<div className='w-full flex flex-row items-center bg-[#FAFAFA] rounded-md pr-3'>
									<PhoneIcon className={styles.icon} />
									<div
										className='w-full flex flex-row items-center gap-[1px] md:text-[20px] text-[16px] py-[14px] pl-3  bg-transparent '
										style={{
											color: "#011723",
											direction: "ltr",
										}}>
										<span>+966</span>
										<input
											type='tel'
											name='phonenumber'
											value={data.phonenumber}
											onChange={(e) =>
												setData({ ...data, phonenumber: e.target.value })
											}
											placeholder={500000000}
											className='w-full h-full bg-transparent text-[#011723]  outline-none '
											style={{
												direction: "ltr",
											}}
										/>
									</div>
								</div>

								<span className='text-red-500 text-[16px]'>
									{DataError?.phonenumber}
								</span>
							</div>

							<div className='flex flex-col gap-[10px]'>
								<label
									style={{ color: "#011723" }}
									className='md:text-[20px] text-[16px]'
									htmlFor='owner_name'>
									{data?.verification_type === "maeruf"
										? "اسم مالك الوثيقة"
										: "اسم السجل التجاري"}
								</label>
								<input
									id='owner_name'
									className='md:text-[20px] text-[16px] py-[14px] px-[24px] rounded-md outline-none'
									style={{ backgroundColor: "#FAFAFA", color: "#011723" }}
									type='text'
									placeholder='اسم المتجر'
									value={data?.owner_name}
									onChange={(e) =>
										setData({ ...data, owner_name: e.target.value })
									}
								/>
								<span className='text-red-500 text-[16px]'>
									{DataError?.owner_name}
								</span>
							</div>

							<div className='flex flex-col gap-[10px]'>
								<label
									style={{ color: "#011723" }}
									className='md:text-[20px] text-[16px]'
									htmlFor='verification_code'>
									{data?.verification_type === "maeruf"
										? "رقم الوثيقة"
										: "رقم السجل التجاري"}
								</label>
								<input
									id='verification_code'
									className='md:text-[20px] text-[16px] py-[14px] px-[24px] rounded-md outline-none'
									style={{ backgroundColor: "#FAFAFA", color: "#011723" }}
									type='text'
									placeholder='001203310'
									value={data?.verification_code}
									onChange={(e) =>
										setData({ ...data, verification_code: e.target.value })
									}
								/>
								<span className='text-red-500 text-[16px]'>
									{DataError?.verification_code}
								</span>
							</div>
							<div className='flex flex-col gap-[10px]'>
								<label
									style={{ color: "#011723" }}
									className='md:text-[20px] text-[16px]'>
									{data?.verification_type === "maeruf"
										? "وثيقة العمل الحر "
										: "السجل التجاري"}
								</label>
								<ImageUploading
									value={files}
									onChange={onChangeLogoImage}
									maxNumber={2}
									dataURLKey='data_url'
									acceptType={["pdf"]}
									disabled={true}
									allowNonImageType={true}>
									{({ fileList, onImageUpload }) => (
										<div className='relative upload__image-wrapper'>
											<div className='w-full image-item '>
												<div
													onClick={() => {
														onImageUpload();
													}}
													className='flex items-center justify-between p-4 mt-2 cursor-pointer'
													style={{
														backgroundColor: "#FAFAFA",
													}}>
													<div className='flex flex-row items-center justify-between gap-2 md:justify-start'>
														<h2
															className='md:text-[20px] text-[16px] whitespace-nowrap'
															style={{ color: "#011723" }}>
															{data?.verification_type === "maeruf"
																? "وثيقة العمل الحر "
																: "السجل التجاري"}
														</h2>
														<PDF />
													</div>
													<div
														className='flex-1 overflow-hidden text-left'
														style={{ direction: "ltr" }}>
														<span
															className='md:text-[16px] text-[14px] md:flex '
															style={{
																color: "#0099FB",
																overflow: "hidden",
																textOverflow: "ellipsis",
																whiteSpace: "nowrap",
															}}>
															{files[0]?.file?.name ||
																data?.file ||
																(data?.verification_type === "maeruf"
																	? "وثيقة العمل الحر "
																	: "السجل التجاري")}
														</span>
													</div>
												</div>
											</div>
										</div>
									)}
								</ImageUploading>

								<span className='text-red-500 text-[16px]'>
									{DataError?.file}
								</span>
							</div>
						</>
					)}
				</div>
				<div className='flex flex-row items-center'>
					<Button
						disabled={loading || btnLoading}
						onClick={updateVerification}
						type={"normal"}
						style={{ backgroundColor: "#1DBBBE", color: "#F7FCFF" }}
						textStyle={{ color: "#F7FCFF" }}
						className='md:h-[60px] h-[45px] md:text-[18px] text-[16px] text-center w-full py-4 rounded-none font-medium'>
						تعديل الطلب
					</Button>
					<Button
						disabled={loading || btnLoading}
						onClick={() => {
							navigate("/verification");
						}}
						type={"outline"}
						style={{
							backgroundColor: "#FFFFFF",
						}}
						textStyle={{ color: "#1DBBBE" }}
						className='md:h-[60px] h-[45px] md:text-[18px] text-[16px] text-center w-full py-4 rounded-none font-medium'>
						الغاء التعديل
					</Button>
				</div>
			</div>
		</>
	);
};

export default VerificationData;
