import React from "react";

// MUI

// date picker
import { DatePicker } from "rsuite";

// import css style
import "rsuite/dist/rsuite-no-reset.min.css";
import "./SelectPickupDate.css";

const SelectPickupDate = ({
	currentOrder,
	value,
	error,
	resetError,
	setValue,
}) => {
	return currentOrder?.orders?.shippingtypes?.name !== "اخرى" ? (
		<div className='w-full mb-3 row mb-md-5'>
			<div className='col-lg-3 col-md-3 col-12'>
				<div className='flex items-center justify-start gap-1 mb-1'>
					<span className='text-lg font-normal'>
						تاريخ تسليم الشحنة للمندوب <span className=' text-red-500'>*</span>
					</span>
				</div>
			</div>
			<div className='col-lg-9 col-md-9 col-12'>
				<DatePicker
					block
					size='lg'
					readOnly={false}
					showMeridiem
					format='yyyy-MM-dd HH:mm:aa'
					placeholder='حدد تاريخ ووقت تسليم الشحنة'
					className='select_pickup_date_picker'
					value={value}
					onChange={(e) => {
						setValue(e);
						resetError();
					}}
					shouldDisableDate={(date) => {
						const today = new Date();
						today.setHours(0, 0, 0, 0);
						return date < today || date?.getTime() === today?.getTime();
					}}
					disabled={
						currentOrder?.orders?.status === "ملغي" ||
						currentOrder?.orders?.status === "مكتمل" ||
						currentOrder?.orders?.status === "طلب مندوب لتسليم الشحنة"
							? true
							: false
					}
				/>
			</div>
			<div className='col-lg-3 col-md-3 col-12'></div>
			<div className='col-lg-9 col-md-9 col-12'>
				<span className='text-sm text-red-500'>{error?.pickup_date}</span>
			</div>
		</div>
	) : null;
};

export default SelectPickupDate;
