import React, { useContext, useState } from "react";
import styles from "../../FaqsComp/FaqsTable/FaqsTable.module.css";
import PropTypes from "prop-types";

/* Third party */
import { useNavigate } from "react-router-dom";

/* Contexts */
import Context from "../../../store/context";
import { DeleteContext } from "../../../store/DeleteProvider";
import { NotificationContext } from "../../../store/NotificationProvider";
import { RolePermissionsContext } from "../../../store/RolePermissionsProvider";

/* Components */
import { toast } from "react-toastify";
import DeleteOneModalComp from "../../DeleteOneModal/DeleteOneModal";
import CircularLoading from "../../../UI/CircularLoading/CircularLoading";

/* MUI */
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import Paper from "@mui/material/Paper";
import Toolbar from "@mui/material/Toolbar";
import { alpha } from "@mui/material/styles";
import TableRow from "@mui/material/TableRow";
import Checkbox from "@mui/material/Checkbox";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import IconButton from "@mui/material/IconButton";
import TableContainer from "@mui/material/TableContainer";

/* Icons */

import { Delete, EditButton, CheckedSquare } from "../../../assets/Icons/index";
import NewPagination from "../../PaginationComp/NewPagination";

import { useBusinessTripsDeleteItemMutation } from "../../../RTK/apiSlices/subscriptionsApi";

const headCells = [
	{
		id: "situation",
		disablePadding: false,
		label: "الإجراء",
		width: "5rem",
	},

	{
		id: "question",
		align: "right",
		disablePadding: false,
		label: "عنوان الرحلة",
	},

	{
		id: "number",
		align: "right",
		disablePadding: false,
		label: "م",
	},
];

function EnhancedTableHead(props) {
	return (
		<TableHead sx={{ backgroundColor: "#ebebebd9" }}>
			<TableRow>
				{headCells?.map((headCell, index) => (
					<TableCell
						className='font-medium md:text-[18px] text-[16px]'
						key={index}
						align={`${headCell.align ?? "inherit"}`}
						padding={headCell.disablePadding ? "none" : "normal"}
						sx={{
							width: headCell.width ? headCell.width : "auto",
							color: "#011723",
							whiteSpace: "nowrap",
						}}>
						{headCell.label}
					</TableCell>
				))}
				<TableCell padding={"none"}></TableCell>
			</TableRow>
		</TableHead>
	);
}

EnhancedTableHead.propTypes = {
	numSelected: PropTypes.number.isRequired,
	onSelectAllClick: PropTypes.func.isRequired,
};

function EnhancedTableToolbar(props) {
	const { numSelected, rowCount, onSelectAllClick, itemsSelected } = props;
	const NotificationStore = useContext(NotificationContext);
	const RolePermissionsStore = useContext(RolePermissionsContext);
	const { permissions } = RolePermissionsStore;
	const { setNotificationTitle, setActionType, setItems } = NotificationStore;

	return (
		<Toolbar
			className='flex flex-row flex-wrap items-center justify-end gap-2 p-0 px-2 pt-2 mb-0 md:mb-4 lg:pt-0'
			sx={{
				pl: { sm: 2 },
				pr: { xs: 1, sm: 1 },
				...(numSelected > 0 && {
					bgcolor: (theme) =>
						alpha(
							theme.palette.primary.main,
							theme.palette.action.activatedOpacity
						),
				}),
			}}>
			<div className='flex flex-col-reverse flex-wrap items-end justify-end flex-1 gap-1 p-0 mb-2 sm:flex-row md:gap-4 md:flex-nowrap'>
				<div className='flex items-center gap-2'>
					{numSelected > 0 && (
						<>
							{permissions?.includes("admin.trip.delete") && (
								<div
									className='rounded-full fcc'
									style={{
										width: "130px",
										backgroundColor: "rgba(255, 56, 56, 0.1)",
										cursor: "pointer",
									}}
									onClick={() => {
										setNotificationTitle(
											"سيتم حذف جميع الرحلات التي قمت بتحديدها"
										);
										setItems(itemsSelected);
										setActionType("deleteAll");
									}}>
									<h2
										className={
											"font-medium md:text-[18px] text-[16px] whitespace-nowrap"
										}
										style={{ color: "#FF3838" }}>
										حذف الكل
									</h2>
									<IconButton>
										<Delete
											style={{
												cursor: "pointer",
												color: "red",
												fontSize: "0.8rem",
											}}
										/>
									</IconButton>
								</div>
							)}
						</>
					)}
				</div>
				<div className='flex items-center'>
					<h2 className='font-medium md:text-[18px] text-[16px] whitespace-nowrap'>
						تحديد الكل
					</h2>
					<Checkbox
						checkedIcon={<CheckedSquare />}
						sx={{
							pr: "0",
							color: "#011723",
							"& .MuiSvgIcon-root": {
								color: "#011723",
							},
						}}
						indeterminate={numSelected > 0 && numSelected < rowCount}
						checked={rowCount > 0 && numSelected === rowCount}
						onChange={onSelectAllClick}
						inputProps={{
							"aria-label": "select all desserts",
						}}
					/>
				</div>
			</div>
		</Toolbar>
	);
}

EnhancedTableToolbar.propTypes = {
	numSelected: PropTypes.number.isRequired,
};

export default function BusinessTripsTable({
	fetchedData,
	loading,
	pageNumber,
	setPageNumber,
	currentPage,
	setCurrentPage,
	page_count,
}) {
	const DeleteStore = useContext(DeleteContext);
	const RolePermissionsStore = useContext(RolePermissionsContext);
	const { permissions } = RolePermissionsStore;
	const contextStore = useContext(Context);
	const { setEndActionTitle } = contextStore;
	const navigate = useNavigate();

	const { setItemId, setActionDelete, actionDelete } = DeleteStore;
	const [selected, setSelected] = React.useState([]);

	const [search, setSearch] = useState("");
	const [searchResults, setSearchResults] = useState([]);
	let packagesFeatures = fetchedData || [];

	if (search !== "") {
		packagesFeatures = searchResults;
	} else {
		packagesFeatures = fetchedData;
	}

	const handleSelectAllClick = (event) => {
		if (event.target.checked) {
			const newSelected = packagesFeatures?.map((n) => n.id);
			setSelected(newSelected);
			return;
		}
		setSelected([]);
	};

	// delete item from table
	const [businessTripsDeleteItem] = useBusinessTripsDeleteItemMutation();
	const handleDeleteSingleItem = async (id) => {
		try {
			await businessTripsDeleteItem({ id })
				.unwrap()
				.then((data) => {
					if (!data?.success) {
						toast.error(data?.message?.ar, {
							theme: "light",
						});
					} else {
						setEndActionTitle(data?.message?.ar);
					}
				});
		} catch (err) {
			console.error("Failed to delete the etlbha Product", err);
		}
	};

	const handleClick = (event, id) => {
		const selectedIndex = selected.indexOf(id);
		let newSelected = [];

		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selected, id);
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(selected.slice(1));
		} else if (selectedIndex === selected.length - 1) {
			newSelected = newSelected.concat(selected.slice(0, -1));
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(
				selected.slice(0, selectedIndex),
				selected.slice(selectedIndex + 1)
			);
		}

		setSelected(newSelected);
	};

	const isSelected = (id) => selected.indexOf(id) !== -1;

	return (
		<Box sx={{ width: "100%", direction: "ltr" }}>
			<Paper sx={{ width: "100%", mb: 2 }}>
				<EnhancedTableToolbar
					itemsSelected={selected}
					numSelected={selected?.length || 0}
					rowCount={packagesFeatures?.length || 0}
					onSelectAllClick={handleSelectAllClick}
					search={search}
					setSearch={setSearch}
					searchResults={searchResults}
					setSearchResults={setSearchResults}
				/>
				<TableContainer>
					<Table size={"medium"}>
						<EnhancedTableHead
							numSelected={selected?.length || 0}
							onSelectAllClick={handleSelectAllClick}
						/>
						<TableBody>
							{loading ? (
								<TableRow>
									<TableCell colSpan={4}>
										<CircularLoading />
									</TableCell>
								</TableRow>
							) : (
								<>
									{packagesFeatures?.length === 0 ? (
										<TableRow>
											<TableCell colSpan={4}>
												<p className='text-center'>لاتوجد بيانات</p>
											</TableCell>
										</TableRow>
									) : (
										packagesFeatures?.map((row, index) => {
											const isItemSelected = isSelected(row.id);
											const labelId = `enhanced-table-checkbox-${index}`;

											return (
												<TableRow
													hover
													role='checkbox'
													aria-checked={isItemSelected}
													tabIndex={-1}
													key={row.id}
													selected={isItemSelected}>
													<TableCell component='th' id={labelId} scope='row'>
														<div className='flex items-center gap-2'>
															{permissions?.includes("admin.trip.destroy") && (
																<Delete
																	title='حذف'
																	onClick={() => {
																		setActionDelete(
																			"سيتم حذف الرحلة التجارية وهذة الخطوة غير قابلة للرجوع"
																		);
																		setItemId(row?.id);
																	}}
																	style={{
																		cursor: "pointer",
																		color: "red",
																		fontSize: "1rem",
																	}}
																/>
															)}

															{permissions?.includes("admin.trip.update") && (
																<EditButton
																	title='تعديل'
																	className={styles.info_icon}
																	onClick={() => {
																		navigate(
																			`/business-trips/edit-business-trip/${row.id}`
																		);
																	}}
																/>
															)}
														</div>
													</TableCell>

													<TableCell align='right'>
														<h2
															className={`font-normal md:text-[18px] text-[16px] `}>
															{row?.title}
														</h2>
													</TableCell>

													<TableCell
														className='font-normal md:text-[18px] text-[16px] '
														align='right'>
														{(index + 1).toLocaleString("en-US", {
															minimumIntegerDigits: 2,
															useGrouping: false,
														})}
													</TableCell>
													<TableCell padding='none' align={"right"}>
														<Checkbox
															checkedIcon={<CheckedSquare />}
															sx={{
																color: "#011723",
																"& .MuiSvgIcon-root": {
																	color: "#011723",
																},
															}}
															checked={isItemSelected}
															onClick={(event) => handleClick(event, row.id)}
															inputProps={{
																"aria-labelledby": labelId,
															}}
														/>
													</TableCell>
												</TableRow>
											);
										})
									)}
								</>
							)}
						</TableBody>
					</Table>
				</TableContainer>
			</Paper>

			{!search && (
				<>
					<NewPagination
						pageNumber={pageNumber}
						page_count={page_count}
						currentPage={currentPage}
						setPageNumber={setPageNumber}
						setCurrentPage={setCurrentPage}
					/>
				</>
			)}

			{actionDelete && (
				<DeleteOneModalComp handleDeleteSingleItem={handleDeleteSingleItem} />
			)}
		</Box>
	);
}
