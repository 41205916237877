import React, { useState, useEffect, useContext } from "react";
/* Third party */
import { Helmet } from "react-helmet";
/* Custom Hook */

/* Contexts */

import { LoadingContext } from "../../../store/LoadingProvider";
import { RolePermissionsContext } from "../../../store/RolePermissionsProvider";
/* Components */
import PageNavigate from "../../../components/PageNavigate/PageNavigate";
import CircularLoading from "../../../UI/CircularLoading/CircularLoading";
/* Custom Button */
import Button from "../../../UI/Button/Button";
/* MUI */
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
/* Icons */
import { IoIosArrowDown } from "react-icons/io";
import {
	useAddNewRegistrationMarketerMutation,
	useGetRegistrationMarketerShowQuery,
} from "../../../RTK/apiSlices/marketersApi";

const activate = [
	{ id: 1, name: "مفعل", name_en: "active" },
	{ id: 2, name: "غير مفعل", name_en: "not_active" },
];

const StatusPage = () => {
	const { data: fetchedData, isLoading: loading } =
		useGetRegistrationMarketerShowQuery();

	const token = localStorage.getItem("token");

	const LoadingStore = useContext(LoadingContext);
	const { setLoadingTitle } = LoadingStore;
	const RolePermissionsStore = useContext(RolePermissionsContext);
	const { permissions } = RolePermissionsStore;
	// to set all value to api
	const [status, setStatus] = useState({
		registration_marketer: "",
		status_marketer: "",
	});

	// to set onchange function to all inputs
	const handleStatus = (event) => {
		const { name, value } = event.target;

		setStatus((prevState) => {
			return { ...prevState, [name]: value };
		});
	};

	const [DataError, setDataError] = useState({
		registration_marketer: "",
		status_marketer: "",
	});
	const resetData = () => {
		setDataError({
			registration_marketer: "",
			status_marketer: "",
		});
	};

	const [addNewRegistrationMarketer] = useAddNewRegistrationMarketerMutation();

	const addNewRegistrationMarketerHandel = async (formData) => {
		try {
			const res = await addNewRegistrationMarketer({
				body: formData,
			});
			if (res?.data?.success === true && res?.data?.data?.status === 200) {
				setLoadingTitle(null);
			} else {
				setDataError({
					registration_marketer:
						res?.data?.message?.en?.registration_marketer?.[0],
					status_marketer: res?.data?.message?.en?.status_marketer?.[0],
				});
			}
		} catch (error) {
			console.error(error);
		}
	};

	// define this functions to post all add market data to server
	const changeStatus = () => {
		resetData("");
		setLoadingTitle("جاري تعديل حالة التسجيل");
		let formData = new FormData();
		formData.append("registration_marketer", status?.registration_marketer);
		formData.append("status_marketer", status?.status_marketer);

		addNewRegistrationMarketerHandel(formData);
	};

	useEffect(() => {
		setStatus({
			...status,
			registration_marketer: fetchedData?.registration_marketer || "",
			status_marketer: fetchedData?.status_marketer || "",
		});
	}, [fetchedData?.status_marketer]);

	return (
		<>
			<Helmet>
				<title>لوحة تحكم أطلبها | حالة التسجيل</title>
			</Helmet>
			<div
				className={` 2xl:pl-36 pl-4 h-full md:px-5 md:py-10 p-4 pt-6 relative md:top-0 -top-[72px] `}
				style={{ backgroundColor: "#F7F7F7" }}>
				<div>
					<PageNavigate currentPage={"حالة التسجيل"} />
				</div>
				{loading ? (
					<div className='flex flex-col items-center justify-center h-full'>
						<CircularLoading />
					</div>
				) : (
					<>
						<div className='mt-16 md:px-6'>
							<div className='mb-5 '>
								<h2
									className='mb-2 font-medium md:text-lg text-[16px]'
									style={{ color: "#011723" }}>
									التسجيل
								</h2>
								<Select
									className='md:h-14 h-[45px] font-normal md:text-lg text-[16px] rounded'
									IconComponent={() => {
										return <IoIosArrowDown size={"1rem"} />;
									}}
									name='registration_marketer'
									value={status?.registration_marketer}
									onChange={handleStatus}
									displayEmpty
									inputProps={{ "aria-label": "Without label" }}
									renderValue={(selected) => {
										if (status?.registration_marketer === "") {
											return <h2> تفعيل</h2>;
										}
										const result = activate?.filter(
											(item) => item?.name_en === selected
										);
										return result[0]?.name;
									}}
									sx={{
										height: "100%",
										backgroundColor: "#fff",
										width: "100%",
										pl: "1rem",
										"& .MuiOutlinedInput-notchedOutline": {
											border: "1px solid #E9E9E9",
										},
										"&.Mui-focused .MuiOutlinedInput-notchedOutline": {
											border: "1px solid #E9E9E9",
										},
									}}>
									{activate?.map((item, idx) => {
										return (
											<MenuItem
												key={idx}
												className=''
												sx={{
													backgroundColor: "#fff",
													height: "3rem",

													"&:hover": {},
													"ul:has(&)": {
														padding: "0",
													},
												}}
												value={`${item?.name_en}`}>
												{item?.name}
											</MenuItem>
										);
									})}
								</Select>
								<span className='text-red-500 text-[16px]'>
									{DataError?.registration_marketer}
								</span>
							</div>
							<div className='mb-5 '>
								<h2
									className='mb-2 font-medium md:text-lg text-[16px]'
									style={{ color: "#011723" }}>
									التفعيل التلقائي
								</h2>
								<Select
									className='md:h-14 h-[45px] font-normal md:text-lg text-[16px] rounded'
									IconComponent={() => {
										return <IoIosArrowDown size={"1rem"} />;
									}}
									name='status_marketer'
									value={status?.status_marketer}
									onChange={handleStatus}
									displayEmpty
									inputProps={{ "aria-label": "Without label" }}
									renderValue={(selected) => {
										if (status?.status_marketer === "") {
											return <h2> تفعيل</h2>;
										}
										const result = activate?.filter(
											(item) => item?.name_en === selected
										);
										return result[0]?.name;
									}}
									sx={{
										height: "100%",
										backgroundColor: "#fff",
										width: "100%",
										pl: "1rem",
										"& .MuiOutlinedInput-notchedOutline": {
											border: "1px solid #E9E9E9",
										},
										"&.Mui-focused .MuiOutlinedInput-notchedOutline": {
											border: "1px solid #E9E9E9",
										},
									}}>
									{activate?.map((item, idx) => {
										return (
											<MenuItem
												key={idx}
												className=''
												sx={{
													backgroundColor: "#fff",
													height: "3rem",

													"&:hover": {},
													"ul:has(&)": {
														padding: "0",
													},
												}}
												value={`${item?.name_en}`}>
												{item?.name}
											</MenuItem>
										);
									})}
								</Select>
								<span className='text-red-500 text-[16px]'>
									{DataError?.status_marketer}
								</span>
							</div>
						</div>
						<Button
							type={"normal"}
							className={"w-full h-14 text-xl font-medium mt-14 py-4"}
							onClick={changeStatus}>
							حفظ
						</Button>
					</>
				)}
			</div>
		</>
	);
};

export default StatusPage;
