import React, { useState, useContext, useRef, useEffect } from "react";
import styles from "./AddNewCourse.module.css";
/* Third party */
import { Helmet } from "react-helmet";
import ImageUploading from "react-images-uploading";
import { TagsInput } from "react-tag-input-component";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

/* Context */
import Context from "../../../../store/context";
import { LoadingContext } from "../../../../store/LoadingProvider";
import { TextEditorContext } from "../../../../store/TextEditorProvider";

/* Components */
import ShowVideo from "./ShowVideo";
import AddUnit from "./AddUnit/AddUnit";
import { TextEditor } from "../../../TextEditor";
import Button from "../../../../UI/Button/Button";

/* Icons */
import { GoArrowRight } from "react-icons/go";
import { IoMdCloudUpload } from "react-icons/io";
import { IoAddCircleSharp } from "react-icons/io5";
import { Delete, PDF } from "../../../../assets/Icons";

import { useAddNewCourseMutation } from "../../../../RTK/apiSlices/coursesApi";

// MUI
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";

const AddNewCourse = () => {
	const ref = useRef(null);
	const navigate = useNavigate();
	const contextStore = useContext(Context);
	const { setSelectedId } = contextStore;

	const handleClosePage = () => {
		navigate("/atlbha_academy");
		setSelectedId(1);
	};

	const editorContent = useContext(TextEditorContext);
	const { editorValue, setEditorValue } = editorContent;
	const [data, setData] = useState({
		minute: "",
		hour: "",
		image: "",
		link: "",
		couresDuration: "",
	});
	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
	} = useForm({
		mode: "onBlur",
		defaultValues: {
			name: "",
		},
	});
	const [tagsSelected, setTagsSelected] = useState([]);
	const [showAddUnit, setShowAddUnit] = useState(false);
	const [unitDetails, setUnitDetails] = useState([]);

	const LoadingStore = useContext(LoadingContext);
	const { setLoadingTitle } = LoadingStore;
	const maxFileSize = 1 * 1024 * 1024; // 1 MB;
	const [images, setImages] = useState([]);
	const [url, setUrl] = useState("");

	const [DataError, setDataError] = useState({
		name: "",
		description: "",
		tags: "",
		file: "",
		video: "",
		image: "",
	});
	const resetData = () => {
		setDataError({
			name: "",
			description: "",
			tags: "",
			file: "",
			video: "",
			image: "",
		});
	};

	const onChange = (imageList, addUpdateIndex) => {
		// Check image size before updating state
		const isSizeValid = imageList.every(
			(image) => image.file.size <= maxFileSize
		);

		if (!isSizeValid) {
			setDataError({
				...DataError,
				image: " حجم الصورة يجب أن لا يزيد عن 1 ميجابايت.",
			});
			toast.warning(" حجم الصورة يجب أن لا يزيد عن 1 ميجابايت.", {
				theme: "light",
			});
		} else {
			setImages(imageList);
			setDataError({ ...DataError, image: null });
		}
	};

	const [addNewCourse] = useAddNewCourseMutation();
	const addNewCourseHandel = async (formData) => {
		try {
			const res = await addNewCourse({
				body: formData,
			});
			if (res?.data?.success === true && res?.data?.data?.status === 200) {
				setLoadingTitle(null);
				handleClosePage();
				setEditorValue("");
			} else {
				setLoadingTitle(null);
				setDataError({
					name: res?.data?.message?.en?.name?.[0],
					description: res?.data?.message?.en?.description?.[0],
					tags: res?.data?.message?.en?.tags?.[0],
					file: res?.data?.message?.en?.["data.0.file.0"]?.[0],
					video: res?.data?.message?.en?.["data.0.video.0"]?.[0],
				});
				toast.error(res?.data?.message?.en?.name?.[0], { theme: "light" });
				toast.error(res?.data?.message?.en?.description?.[0], {
					theme: "light",
				});
				toast.error(res?.data?.message?.en?.tags?.[0], { theme: "light" });
				toast.error(res?.data?.message?.en?.["data.0.file.0"]?.[0], {
					theme: "light",
				});
				toast.error(res?.data?.message?.en?.["data.0.video.0"]?.[0], {
					theme: "light",
				});
			}
		} catch (error) {
			console.error(error);
		}
	};

	const handleCourse = (dataCourse) => {
		resetData();
		const formData = new FormData();
		formData.append("name", dataCourse?.name);
		formData.append("description", editorValue);
		formData.append("duration", data?.minute);
		formData.append("tags", tagsSelected.join(","));
		if (images.length !== 0) {
			formData.append("image", images[0]?.file || "");
		}
		for (let i = 0; i < unitDetails?.length; i++) {
			formData.append([`data[${i}][title]`], unitDetails[i]?.title);
			if (unitDetails[i]?.documents?.length === 0) {
				formData.append([`data[${i}][file][${0}]`], "");
			} else {
				for (let d = 0; d < unitDetails[i]?.documents?.length; d++) {
					formData.append(
						[`data[${i}][file][${d}]`],
						unitDetails[i]?.documents[d]
					);
				}
			}

			for (let v = 0; v < unitDetails[i]?.videos?.length; v++) {
				formData.append([`data[${i}][video][${v}]`], unitDetails[i]?.videos[v]);
			}
		}
		setLoadingTitle("جاري حفظ الدورة");
		addNewCourseHandel(formData);
	};

	const scrollToElement = () => {
		ref.current?.scrollIntoView({ behavior: "smooth" });
	};

	useEffect(() => {
		scrollToElement();
	}, []);

	// Only allow non-empty keywords
	const beforeAddValidate = (keyword) => {
		return keyword.trim() !== "";
	};

	return (
		<>
			{url !== "" && <ShowVideo url={url} setUrl={setUrl} />}
			<Helmet>
				<title>لوحة تحكم أطلبها | اضافة دورة</title>
			</Helmet>
			<form className='' onSubmit={handleSubmit(handleCourse)}>
				<div className=' absolute lg:pl-[140px] md:pr-5 md:py-[43px] md:pt-4 top-0 right-0 z-30 md:pb-36 w-full md:bg-[#fafafa] bg-[#FFFFFF] otlobha_acadmic'>
					{showAddUnit && (
						<AddUnit
							unitDetails={(details) =>
								setUnitDetails([...unitDetails, details])
							}
							cancel={() => {
								setShowAddUnit(false);
							}}
						/>
					)}
					<div
						ref={ref}
						className='flex items-center justify-between px-4 pt-6 mb-2 md:p-0 md:pt-0'>
						<div className='flex'>
							<div className={`flex items-center gap-2 `}>
								<div
									onClick={handleClosePage}
									className={`flex items-center gap-2 cursor-pointer ${styles.arrow_con}`}>
									<GoArrowRight
										style={{ color: "#02466A", fontSize: "1.2rem" }}
									/>
								</div>

								<Link
									to={"/"}
									className='md:text-[18px] text-[15px] font-medium md:ml-4 ml-2'>
									الرئيسية
								</Link>
							</div>

							<h2
								onClick={handleClosePage}
								className='md:text-[18px] text-[15px] font-medium md:ml-4 ml-2 cursor-pointer'>
								/ أكاديمية اطلبها
							</h2>

							<h3
								className='md:text-[18px] text-[15px] font-medium'
								style={{ color: "#67747B" }}>
								/ اضافة دورة تدريبية
							</h3>
						</div>
					</div>
					<div className='flex flex-col gap-5 mt-[42px] md:pr-[78px] p-4 h-full'>
						<div className='flex flex-col gap-[10px]'>
							<label
								className='md:text-[18px] text-[16px]'
								style={{ color: "#011723" }}>
								اسم الدورة التدريبية<span className='text-red-500'>*</span>
							</label>
							<input
								className='w-full md:h-14 h-[45px] outline-none shadow-sm rounded-lg p-4'
								style={{
									backgroundColor: "#F4F5F7",
									border: "1px solid #67747B33",
								}}
								type='text'
								name='name'
								{...register("name", {
									required: "حقل الاسم مطلوب",
									pattern: {
										value: /^[^-\s][\u0600-\u06FF-A-Za-z0-9 ]+$/i,
										message: "يجب ان يتكون الاسم من أحرف",
									},
								})}
							/>
							<span className='text-red-500 text-[16px]'>
								{DataError?.name}
								{errors?.name && errors.name.message}
							</span>
						</div>
						<div className='flex flex-col gap-[10px]'>
							<label
								className='md:text-[18px] text-[16px]'
								style={{ color: "#011723" }}>
								الوصف<span className='text-red-500'>*</span>
							</label>
							<div className={styles.editor}>
								<TextEditor
									ToolBar={"createOrEditPages"}
									placeholder={"وصف الدورة..."}
								/>
							</div>
							<span className='text-red-500 text-[16px]'>
								{DataError?.description}
							</span>
						</div>
						<div className='flex flex-col gap-[10px]'>
							<h2
								className='md:text-[18px] text-[16px]'
								style={{ color: "#011723" }}>
								محاور الدورة TAGS<span className='text-red-500'>*</span>
							</h2>
							<div
								style={{
									backgroundColor: "#F4F5F7",
									border: "1px solid #67747B33",
								}}
								className={`${styles.tags} flex flex-row items-center flex-wrap rounded-lg`}>
								<TagsInput
									value={tagsSelected}
									onChange={setTagsSelected}
									beforeAddValidate={beforeAddValidate}
									name='tags'
									placeHolder='ضع الكلمة ثم اضغط enter'
								/>
							</div>
							<span className='text-red-500 text-[16px]'>
								{DataError?.tags}
							</span>
						</div>

						<div className='mb-[80px] mt[33px] flex flex-col gap-4'>
							<h6
								className='md:text-[24px] text-[20px]'
								style={{ fontWeight: "500", color: "#000000" }}>
								دروس الدورة
							</h6>
							{unitDetails?.length !== 0 ? (
								<div>
									{unitDetails?.map((item, index) => (
										<Accordion
											sx={{
												".Mui-expanded": {
													margin: 0,
												},
											}}
											key={index}
											style={{
												width: "100%",
												backgroundColor: "#F4F5F7",
												border: "1px solid #67747B33",
												boxShadow: "none",
											}}
											className='relative md:h-[56px] h-[45px]'>
											<AccordionSummary
												expandIcon={<ExpandMoreIcon />}
												aria-controls='panel1a-content'
												id='panel1a-header'
												sx={{
													"&.Mui-expanded": {
														minHeight: "48px",
													},
												}}>
												<div className='flex flex-row items-center flex-1 gap-2'>
													<h6
														className='md:text-[18px] sm:text-[16px] text-[14px] whitespace-nowrap sm:w-auto w-full'
														style={{
															fontWeight: "500",
															color: "#000000",
															overflow: "hidden",
															textOverflow: "ellipsis",
														}}>
														{item?.title}
													</h6>
													<span
														className='hidden md:flex md:text-[18px] sm:text-[16px] text-[14px] whitespace-nowrap min-w-fit md:w-fit w-auto'
														style={{
															color: "#67747B",
															overflow: "hidden",
															textOverflow: "ellipsis",
														}}>
														(
														{item?.videos?.length === 1
															? " درس واحد "
															: item?.videos?.length === 2
															? "درسين"
															: `${item?.videos?.length} دروس`}
														)
													</span>
													<span
														className='md:hidden flex md:text-[18px] sm:text-[16px] text-[14px] whitespace-nowrap min-w-fit md:w-fit w-auto'
														style={{
															color: "#67747B",
															overflow: "hidden",
															textOverflow: "ellipsis",
														}}>
														({item?.videos?.length})
													</span>
												</div>
											</AccordionSummary>
											<AccordionDetails
												style={{ backgroundColor: "#F4F5F7" }}
												className='flex flex-col gap-5 absolute z-10 left-0 top-[55px] w-full p-5'>
												{item?.videos?.map(
													(video, index) =>
														video !== "" && (
															<div
																key={index}
																className='flex flex-row items-center justify-between'>
																<div className='flex flex-row items-center'>
																	<PlayCircleOutlineIcon
																		onClick={() => setUrl(video)}
																		className='cursor-pointer'
																	/>

																	<h6
																		style={{
																			fontWeight: "500",
																			color: "#011723",
																		}}
																		className='md:text-[20px] text-[18px] mr-[20px] ml-[30px]'>
																		مقطع فيديو {index + 1}
																	</h6>
																</div>
																<Delete className='' />
															</div>
														)
												)}

												{Array.from(item?.documents || [])?.map(
													(file, index) => (
														<div
															key={index}
															className='flex flex-row items-center justify-between overflow-hidden'>
															<div className='flex flex-row items-center overflow-hidden'>
																<PDF />
																<h6
																	style={{
																		fontWeight: "500",
																		color: "#0077FF",
																		overflow: "hidden",
																		textOverflow: "ellipsis",
																		whiteSpace: "nowrap",
																	}}
																	className='w-[70%] md:w-full md:text-[20px] text-[18px] mr-[20px] ml-[30px]'>
																	{file?.name}
																</h6>
															</div>
															<a
																href={file}
																download={file}
																target='_blank'
																rel='noreferrer'>
																<h6
																	className='md:text-[18px] text-[16px]'
																	style={{
																		color: "#0077FF",
																		cursor: "pointer",
																	}}>
																	تحميل
																</h6>
															</a>
														</div>
													)
												)}
											</AccordionDetails>
										</Accordion>
									))}
								</div>
							) : (
								<div
									className='w-full md:h-14 h-[45px] outline-none shadow-sm rounded-lg p-4'
									style={{
										backgroundColor: "#F4F5F7",
										border: "1px solid #67747B33",
									}}>
									<h6 className='text-sm md:text-lg'>لايوجد دروس حتى الان</h6>
								</div>
							)}
							<span className='text-red-500 text-[16px]'>
								{DataError?.file}
							</span>
							<span className='text-red-500 text-[16px]'>
								{DataError?.video}
							</span>
						</div>

						<div className='flex flex-col items-start md:flex-row md:items-center md:gap-16 gap-y-4'>
							<label
								className='md:text-[18px] text-[16px] font-medium whitespace-nowrap'
								style={{ color: "#011723" }}>
								الصورة التعريفية<span className='text-red-500'>*</span>
							</label>
							<div className='flex flex-col w-full gap-2'>
								<div className='text-gray-400 font-light text-[16px]'>
									المقاس المقترح : 440 * 440
								</div>
								<ImageUploading
									value={images}
									onChange={onChange}
									maxNumber={2}
									dataURLKey='data_url'
									acceptType={["jpg", "png", "jpeg", "webp", "svg"]}>
									{({ onImageUpload, dragProps }) => (
										// write your building UI
										<div
											className='relative mx-auto overflow-hidden upload__image-wrapper'
											style={{
												width: "100%",
												padding: "8px",
												border: "1px dashed #02466A",
												borderRadius: "10px",
												strokeDasharray: "'6%2c5'",
											}}
											onClick={() => {
												onImageUpload();
											}}
											{...dragProps}>
											<div className='w-full h-full cursor-pointer image-item'>
												<div className='flex flex-col justify-center items-center gap-[5px] h-full w-full'>
													<IoMdCloudUpload size={"2em"}></IoMdCloudUpload>
													<h2 style={{ color: "#011723", fontSize: "16px" }}>
														اسحب الصورة هنا
													</h2>
													<div className='flex flex-col items-center gap-1'>
														<h2 className='mb-0'>
															(سيتم قبول الصور png & jpg & jpeg & webp)
														</h2>
														<span className='text-gray-400 font-light text-[14px]'>
															(الحد الأقصى للصورة 1MB)
														</span>
													</div>
												</div>
											</div>
										</div>
									)}
								</ImageUploading>
								{DataError?.image && (
									<span className='text-red-500 text-[16px]'>
										{DataError?.image}
									</span>
								)}
							</div>
						</div>

						<div className='flex flex-col items-start justify-center md:flex-row md:gap-16 gap-y-4'>
							{images[0] && (
								<div className='md:w-[400px] w-[100%] md:h-[400px] h-[100%] mt-4'>
									<img
										className='mx-auto my-0'
										src={images[0]?.data_url}
										alt='preview-img'
										loading='lazy'
									/>
								</div>
							)}
						</div>

						<>
							<div className='flex gap-4 mt-10'>
								<Button
									style={{ backgroundColor: "#02466A" }}
									textStyle={{ color: "#EFF9FF" }}
									className={
										"md:w-[474px] w-full md:h-[64px] max-h-[64px] h-[45px] md:text-[20px] text-[18px] flex-1"
									}
									type={"normal"}
									svg={<IoAddCircleSharp fontSize='1.5rem' color={"#fff"} />}
									onClick={() => {
										setShowAddUnit(true);
									}}
									btnType={"button"}>
									اضافة درس
								</Button>
								<Button
									style={{ borderColor: "#02466A" }}
									textStyle={{ color: "#02466A" }}
									className={
										"md:w-[474px] w-full md:h-[64px] max-h-[64px] h-[45px] md:text-[20px] text-[18px] flex-1"
									}
									type={"outline"}
									onClick={handleClosePage}
									btnType={"button"}>
									إلغاء
								</Button>
							</div>

							<Button
								btnType={"submit"}
								style={{ width: "100%", backgroundColor: "#1DBBBE" }}
								textStyle={{ color: "#EFF9FF" }}
								className={
									"md:h-[64px] max-h-[64px] h-[45px] md:text-[20px] text-[18px] flex-1"
								}
								type={"normal"}>
								حفظ الدورة
							</Button>
						</>
					</div>
				</div>
			</form>
		</>
	);
};
export default AddNewCourse;
