import React, { useState, useContext, useEffect } from "react";
/* Third party */
import ImageUploading from "react-images-uploading";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
/* Custom Hook */

/* Contexts */
import { LoadingContext } from "../../../../store/LoadingProvider";
import { RolePermissionsContext } from "../../../../store/RolePermissionsProvider";
/* Components */
import CircularLoading from "../../../../UI/CircularLoading/CircularLoading";
/* Custom Button */
import Button from "../../../../UI/Button/Button";
/* Icons */
import { UploadIcon } from "../../../../assets/Icons";
import {
	useGetSettingsQuery,
	useUpdateSettingsMutation,
} from "../../../../RTK/apiSlices/settingsApi";

const InputsComps = () => {
	const { data: fetchedData, isLoading: loading } = useGetSettingsQuery();
	const LoadingStore = useContext(LoadingContext);
	const { setLoadingTitle } = LoadingStore;
	const RolePermissionsStore = useContext(RolePermissionsContext);
	const { permissions } = RolePermissionsStore;
	const {
		register,
		handleSubmit,
		reset,
		formState: { errors },
	} = useForm({
		mode: "onBlur",
		defaultValues: {
			name: "",
			desc: "",
			link: "",
			email: "",
			address: "",
			phone: "",
		},
	});
	const [settingData, setSettingData] = useState({
		id: "",
		name: "",
		desc: "",
		link: "",
		email: "",
		address: "",
		phone: "",
	});
	const [DataError, setDataError] = useState({
		name: "",
		desc: "",
		link: "",
		email: "",
		address: "",
		phone: "",
		logo: "",
	});
	const resetData = () => {
		setDataError({
			name: "",
			desc: "",
			link: "",
			email: "",
			address: "",
			phone: "",
			logo: "",
		});
	};

	const [images, setImages] = useState([]);
	// handle images size
	const maxFileSize = 1 * 1024 * 1024; // 1 MB;

	// Function to handle changes in the logo
	const onChangeImage = (imageList, addUpdateIndex) => {
		// Check if the image size is valid
		const isSizeValid = imageList?.every(
			(image) => image?.file?.size <= maxFileSize
		);

		// If the image size is not valid
		if (!isSizeValid) {
			// Display a warning message and reset the logo state
			setDataError({
				...DataError,
				logo: "حجم الشعار يجب أن لا يزيد عن 1 ميجابايت.",
			});
			toast.warning("حجم الشعار يجب أن لا يزيد عن 1 ميجابايت.", {
				theme: "light",
			});
			setImages([]);
			return;
		}

		// Check if the image dimensions are valid
		imageList?.every((image) => {
			const img = new Image();

			// Set the event listener to check dimensions once the image is loaded
			img.onload = () => {
				if (img.width !== 160 && img.height !== 115) {
					// If dimensions are not valid, display a warning and reset the logo state
					setDataError({
						...DataError,
						logo: "مقاس الشعار يجب أن يكون  160 بكسل عرض 115 بكسل ارتفاع.",
					});
					toast.warning(
						"مقاس الشعار يجب أن يكون  160 بكسل عرض 115 بكسل ارتفاع.",
						{ theme: "light" }
					);
					setImages([]);
				}
			};
			img.src = image?.data_url;
			return true; // Returning true because the actual check is done in the onload event
		});

		// If all checks are valid, update the state
		setImages(imageList);
		setDataError({ ...DataError, logo: null });
	};

	useEffect(() => {
		setSettingData({
			...settingData,
			id: fetchedData?.settings?.id,
			name: fetchedData?.settings?.name,
			desc: fetchedData?.settings?.description,
			link: fetchedData?.settings?.link,
			email: fetchedData?.settings?.email,
			address: fetchedData?.settings?.address,
			phone: fetchedData?.settings?.phonenumber?.startsWith("+966")
				? fetchedData?.settings?.phonenumber?.slice(4)
				: fetchedData?.settings?.phonenumber?.startsWith("00966")
				? fetchedData?.settings?.phonenumber.slice(5)
				: fetchedData?.settings?.phonenumber,
		});
	}, [fetchedData?.settings]);

	useEffect(() => {
		reset(settingData);
	}, [settingData, reset]);

	const [updateSettings] = useUpdateSettingsMutation();

	const updateSettingsHandel = async (formData) => {
		try {
			const res = await updateSettings({
				id: settingData?.id,
				body: formData,
			});
			if (res?.data?.success === true && res?.data?.data?.status === 200) {
				setLoadingTitle(null);
			} else {
				setLoadingTitle(null);
				setDataError({
					name: res?.data?.message?.en?.name?.[0],
					desc: res?.data?.message?.en?.desc?.[0],
					link: res?.data?.message?.en?.link?.[0],
					email: res?.data?.message?.en?.email?.[0],
					address: res?.data?.message?.en?.address?.[0],
					phone: res?.data?.message?.en?.phonenumber?.[0],
					logo: res?.data?.message?.en?.logo?.[0],
				});
				toast.error(res?.data?.message?.en?.name?.[0], { theme: "light" });
				toast.error(res?.data?.message?.en?.desc?.[0], { theme: "light" });
				toast.error(res?.data?.message?.en?.link?.[0], { theme: "light" });
				toast.error(res?.data?.message?.en?.email?.[0], { theme: "light" });
				toast.error(res?.data?.message?.en?.address?.[0], { theme: "light" });
				toast.error(res?.data?.message?.en?.phonenumber?.[0], {
					theme: "light",
				});
				toast.error(res?.data?.message?.en?.logo?.[0], { theme: "light" });
			}
		} catch (error) {
			console.error(error);
		}
	};

	const updateSetting = (data) => {
		setLoadingTitle("جاري تعديل الاعدادات الاساسية");
		resetData();
		const formData = new FormData();
		formData.append("_method", "PUT");
		formData.append("name", data?.name);
		formData.append("description", data?.desc);
		formData.append(
			"phonenumber",
			data?.phone?.startsWith("+966") || data?.phone?.startsWith("00966")
				? data?.phone
				: `+966${data?.phone}`
		);
		formData.append("link", data?.link);
		formData.append("email", data?.email);
		formData.append("address", data?.address);
		if (images.length !== 0) {
			formData.append("logo", images[0]?.file || null);
		}

		updateSettingsHandel(formData);
	};

	// handle paste phone number to handle remove space
	const handlePastePhoneNumber = (e) => {
		e.preventDefault();
		let pastedData = (e.clipboardData || window.clipboardData).getData("text");
		pastedData = pastedData.replace(/\s+/g, "");
		e.target.value = pastedData;
	};
	return (
		<form onSubmit={handleSubmit(updateSetting)}>
			<div className='p-4 mt-6 xl:mt-10 2xl:pr-24 x2l:pl-40 xl:pb-24'>
				{loading ? (
					<div className='flex flex-col items-center'>
						<CircularLoading />
					</div>
				) : (
					<div>
						<div className='mb-3 md:mb-5'>
							<label className='md:text-[18px] text-[16px] font-medium'>
								اسم المنصة<span className='text-red-500'>*</span>
							</label>
							<input
								className='outline-none w-full md:h-14 h-[45px] p-3 mt-3 rounded'
								style={{ border: "none" }}
								type='text'
								placeholder='ادخل اسم المنصة'
								{...register("name", {
									required: "حقل الاسم مطلوب",
									pattern: {
										value: /^[^-\s][\u0600-\u06FF-A-Za-z0-9 ]+$/i,
										message: "حقل الاسم يجب ان يتكون من أحرف",
									},
								})}
							/>
							<span className='text-red-500 text-[16px]'>
								{DataError?.name}
								{errors?.name && errors.name.message}
							</span>
						</div>
						<div className='mb-3 md:mb-5'>
							<label className='md:text-[18px] text-[16px] font-medium'>
								وصف المنصة<span className='text-red-500'>*</span>
							</label>
							<textarea
								className='outline-none w-full p-3 mt-3 rounded min-h-[180px] overflow-auto'
								style={{ border: "none" }}
								placeholder='اكتب وصف للمنصة'
								{...register("desc", {
									required: "حقل وصف المنصة مطلوب",
									pattern: {
										value: /^[^-\s][\u0600-\u06FF-A-Za-z0-9 ]+$/i,
										message: "حقل وصف المنصة يجب ان يتكون من أحرف",
									},
								})}
								rows={1}></textarea>
							<span className='text-red-500 text-[16px]'>
								{DataError?.desc}
								{errors?.desc && errors.desc.message}
							</span>
						</div>
						<div className='mb-3 md:mb-5'>
							<label className='md:text-[18px] text-[16px] font-medium'>
								رابط المنصة<span className='text-red-500'>*</span>
							</label>
							<input
								className='outline-none w-full md:h-14 h-[45px] p-3 mt-3 rounded text-left'
								style={{ border: "none", direction: "ltr" }}
								type='text'
								placeholder='www.sample.com'
								{...register("link", {
									required: "حقل رابط المنصة مطلوب",
									pattern: {
										value: /^((ftp|http|https):\/\/).([A-z]+)\.([A-z]{2,})/,
										message: "حقل الرابط يجب ان يكون صيغة صحيحة",
									},
								})}
							/>
							<span className='text-red-500 text-[16px]'>
								{DataError?.link}
								{errors?.link && errors.link.message}
							</span>
						</div>
						<div className='mb-3 md:mb-5'>
							<label className='md:text-[18px] text-[16px] font-medium'>
								البريد الالكتروني<span className='text-red-500'>*</span>
							</label>
							<input
								className='outline-none w-full md:h-14 h-[45px] p-3 mt-3 rounded text-left'
								style={{ border: "none", direction: "ltr" }}
								type='email'
								placeholder='www.sample.com'
								{...register("email", {
									required: "حقل البريد الالكتروني مطلوب",
									pattern: {
										value: /\S+@\S+\.\S+/,
										message: "حقل البريد الالكتروني يجب ان يكون صيغة صحيحة",
									},
								})}
							/>
							<span className='text-red-500 text-[16px]'>
								{DataError?.email}
								{errors?.email && errors.email.message}
							</span>
						</div>
						<div className='mb-3 md:mb-5'>
							<label className='md:text-[18px] text-[16px] font-medium'>
								العنوان<span className='text-red-500'>*</span>
							</label>
							<input
								className='outline-none w-full md:h-14 h-[45px] p-3 mt-3 rounded'
								style={{ border: "none" }}
								type='text'
								placeholder='ادخل العنوان والاقامة'
								{...register("address", {
									required: "حقل العنوان مطلوب",
									pattern: {
										value: /^[^-\s][\u0600-\u06FF-A-Za-z0-9 ]+$/i,
										message: "حقل العنوان يجب ان يتكون من أحرف",
									},
								})}
							/>
							<span className='text-red-500 text-[16px]'>
								{DataError?.address}
								{errors?.address && errors.address.message}
							</span>
						</div>
						<div className='mb-3 md:mb-5'>
							<label className='md:text-[18px] text-[16px] font-medium'>
								رقم الهاتف<span className='text-red-500'>*</span>
							</label>
							<div
								className='flex flex-row items-center gap-[1px] w-full md:h-14 h-[45px] px-4 mt-3 rounded bg-[#ffffff]'
								style={{ border: "none", direction: "ltr" }}>
								<span>+966</span>
								<input
									className='w-full h-full outline-none'
									style={{ border: "none", direction: "ltr" }}
									type='tel'
									placeholder='515131515'
									maxLength={9}
									onPaste={(e) => {
										handlePastePhoneNumber(e);
									}}
									{...register("phone", {
										required: "حقل الهاتف مطلوب",
										pattern: {
											value: /^(5\d{8})$/,
											message:
												"تأكد ان رقم الجوال يبدأ برقم 5 ولا يقل عن 9 أرقام",
										},
									})}
								/>
							</div>
							<span className='text-red-500 text-[16px]'>
								{DataError?.phone}
								{errors?.phone && errors.phone.message}
							</span>
						</div>
						<div className='mb-3 md:mb-5'>
							<div className='flex flex-col gap-2'>
								<label className='md:text-[18px] text-[16px] font-medium'>
									الشعار
								</label>
								<span className='text-gray-400 font-light text-[14px]'>
									الحد الأقصى للصورة 1MB
								</span>
							</div>
							<div>
								<ImageUploading
									value={images}
									onChange={onChangeImage}
									maxNumber={2}
									dataURLKey='data_url'
									acceptType={["jpg", "png", "jpeg", "webp", "svg"]}>
									{({ onImageUpload, dragProps }) => (
										// write your building UI
										<div
											className='upload__image-wrapper relative  flex items-center overflow-hidden  outline-none w-full md:h-14 h-[45px] p-3 mt-3 rounded bg-white'
											style={{
												border: "none",
											}}
											{...dragProps}>
											<h2
												className='w-full p-4 outline-none cursor-pointer'
												style={{
													color: "#aaa",
												}}
												onClick={() => {
													onImageUpload();
												}}>
												{images[0]?.file?.name ||
													"قم برفع شعار للمنصة (المقاس الانسب 160 بكسل عرض 115 بكسل الارتفاع)"}
											</h2>
											<div
												className='flex items-center justify-center h-full'
												style={{
													width: "1.3rem",
												}}>
												<UploadIcon className='w-full' fill='#1dbbbe' />
											</div>
										</div>
									)}
								</ImageUploading>
								<span className='text-red-500 text-[16px]'>
									{DataError?.logo}
								</span>
							</div>
						</div>
					</div>
				)}
				{!loading && (
					<div className='flex gap-4 mt-8'>
						{permissions?.includes("admin.setting.update") && (
							<Button
								btnType={"submit"}
								className={"rounded h-14 sm:w-[180px] w-full"}
								style={{ backgroundColor: "#1DBBBE" }}
								fontSize={"text-xl"}
								type={"normal"}>
								تعديل
							</Button>
						)}
					</div>
				)}
			</div>
		</form>
	);
};
export default InputsComps;
