import React, { useState } from "react";

export const myAccountContext = React.createContext({});

const MyAccountInfoProvider = (props) => {
	const [myAccountInfo, setMyAccountInfo] = useState({});

	const context = {
		myAccountInfo,
		setMyAccountInfo,
	};

	return (
		<myAccountContext.Provider value={context}>
			{props.children}
		</myAccountContext.Provider>
	);
};

export default MyAccountInfoProvider;
