import React from "react";
import { SignOut } from "../../assets/Icons";
import { useLogOutMutation } from "../../RTK/apiSlices/logOutApi";
import { useNavigate } from "react-router-dom";

const LogoutBtn = ({ closeMenu }) => {
	const navigate = useNavigate();

	// handle logout from admin
	const [logout] = useLogOutMutation();
	const handleLogout = async () => {
		// close profile menu
		closeMenu();
		// make request...
		try {
			const response = await logout();

			if (
				response.data?.success === true &&
				response.data?.data?.status === 200
			) {
				localStorage.clear();

				// handle timeout to reload page and navigate user to login page
				window.location.reload();

				setTimeout(() => {
					navigate("/login");
				}, 1000);
			}
		} catch (error) {
			console.error("Error changing logout:", error);
		}
	};
	return (
		<button
			className='flex flex-row gap-4 px-6 cursor-pointer'
			onClick={handleLogout}>
			<SignOut />
			<span className='whitespace-nowrap'>تسجيل الخروج</span>
		</button>
	);
};

export default LogoutBtn;
