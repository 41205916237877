import { useState, useContext } from "react";

/* Third party */
import { useNavigate } from "react-router-dom";

/* Context */
import Context from "../../../store/context";
import { DeleteContext } from "../../../store/DeleteProvider";
import { NotificationContext } from "../../../store/NotificationProvider";
import { RolePermissionsContext } from "../../../store/RolePermissionsProvider";

/* Components */
import ProductDetails from "./ProductDetails/ProductDetails";
import CircularLoading from "../../../UI/CircularLoading/CircularLoading";

import DeleteModal from "../../DeleteModal/DeleteModal";
import DeleteOneModal from "../../DeleteOneModal/DeleteOneModal";

/* Custom Button */
import Button from "../../../UI/Button/Button";

/* MUI */
import Checkbox from "@mui/material/Checkbox";
import Switch from "@mui/material/Switch";
import { Button as MenuButton } from "@mui/material";
import Menu from "@mui/material/Menu";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import MenuItem from "@mui/material/MenuItem";

import PlaceholderImage from "../../../assets/images/placeholderImage.png";

/* Icons */
import {
	Currency,
	StaticsIcon,
	Delete,
	CheckedSquare,
	StarIcon,
} from "../../../assets/Icons/index";
import { BiLinkAlt } from "react-icons/bi";
import { HiOutlineDocumentDuplicate } from "react-icons/hi";
import NewPagination from "../../PaginationComp/NewPagination";
import {
	useEtlobhaChangeSatusAllMutation,
	useEtlobhaChangeSpecialMutation,
	useEtlobhaDeleteAllMutation,
	useEtlbhaDeleteProductMutation,
} from "../../../RTK/apiSlices/etlobhaApi";
import { toast } from "react-toastify";

const ItemCategory = (props) => {
	const {
		id,
		name,
		cover,
		purchasing_price,
		stock,
		admin_special,
		category,
		subcategory,
		handleProductDetails,
		handleSelect,
	} = props;
	const item = props.item;
	const [menuButton, setMenuButton] = useState(null);
	const navigate = useNavigate();

	const contextStore = useContext(Context);
	const { setEndActionTitle } = contextStore;

	// we use this context to handle delete items
	const DeleteStore = useContext(DeleteContext);
	const { setItemId, setActionDelete, actionDelete } = DeleteStore;

	const RolePermissionsStore = useContext(RolePermissionsContext);
	const { permissions } = RolePermissionsStore;
	const open = Boolean(menuButton);

	// Functions for menu
	const handleClick = (event) => {
		setMenuButton(event.currentTarget);
	};

	const handleClose = () => {
		setMenuButton(null);
	};

	// handle copy link of product
	const copyLink = (link) => {
		navigator.clipboard.writeText(link);
	};

	// handle change special status of product
	const [etlobhaChangeSpecial] = useEtlobhaChangeSpecialMutation();
	const onChangeHandler = async (id) => {
		try {
			await etlobhaChangeSpecial({ id })
				.unwrap()
				.then((data) => {
					if (data.success) {
						setEndActionTitle(data.message.ar);
					}
				});
		} catch (err) {
			console.error("Failed to delete the category", err);
		}
	};

	// handle delete item
	const [etlbhaDeleteProduct] = useEtlbhaDeleteProductMutation();
	const handleDeleteSingleItem = async (id) => {
		try {
			await etlbhaDeleteProduct({ id })
				.unwrap()
				.then((data) => {
					if (!data?.success) {
						toast.error(data?.message?.ar, {
							theme: "light",
						});
					} else {
						setEndActionTitle(data?.message?.ar);
					}
				});
		} catch (err) {
			console.error("Failed to delete the etlbha Product", err);
		}
	};

	return (
		<>
			<li
				className='flex flex-col justify-between gap-4 mb-6 rounded-md lg:flex-row'
				style={{
					backgroundColor: "#fff",
					padding: "1rem 0.5rem",
					border: "1px solid #ECECEC",
				}}>
				<div className='flex'>
					<div className='flex flex-col md:flex-row gap-y-2'>
						<div className='flex flex-row items-center gap-8 px-3 md:flex-col'>
							<Checkbox
								checked={props.isItemSelected}
								checkedIcon={<CheckedSquare />}
								sx={{ display: "inline", padding: "0" }}
								onClick={(event) => handleSelect(event, id)}
							/>
							<StarIcon
								className={`${
									admin_special === "مميز" ? "opacity-100" : "opacity-0"
								}`}
							/>
						</div>
						<div>
							<img
								className='object-contain h-36 w-36'
								src={cover ? cover : PlaceholderImage}
								alt='product-img'
								loading='lazy'
							/>
						</div>
					</div>
					<div className='flex flex-col justify-between mr-4 lg:w-[356px] w-full overflow-hidden'>
						<div
							className='flex items-center mb-2 h-11 text-ellipsis md:mb-0'
							style={{ border: "1px solid #ccc", padding: "0.25rem 0.75rem" }}>
							<h2 className='w-full text-ellipsis'>{name}</h2>
						</div>
						<div className='flex flex-col gap-2 md:flex-row md:gap-4'>
							<div className='flex-1'>
								<h2>سعر الشراء</h2>
								<div
									className='flex items-center mt-2 h-11'
									style={{ border: "1px solid #ccc" }}>
									<div className='flex items-center flex-1 px-3 py-1'>
										<img
											className='ml-2 opacity-50'
											src={Currency}
											alt=''
											loading='lazy'
										/>
										<h2>{purchasing_price}</h2>
									</div>

									<div
										className='flex items-center justify-center w-12 h-full'
										style={{ backgroundColor: "#FAFAFA" }}>
										ر.س
									</div>
								</div>
							</div>
							<div className='flex-1'>
								<h2>الكمية في المخزن</h2>
								<div
									className='flex items-center mt-2 h-11'
									style={{ border: "1px solid #ccc" }}>
									<div className='flex items-center justify-center flex-1 px-3 py-1'>
										<h2>{stock}</h2>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className='flex flex-col justify-between w-full gap-6 lg:w-80 md:gap-12'>
					<div className='flex flex-1 gap-4'>
						<div
							className='flex font-normal flex-1  truncate px-2 justify-center items-center rounded-sm min-h-[30px] max-h[48px] text-center'
							style={{
								backgroundColor: "#FFEEEE",
								border: "1px solid #ECECEC",
							}}>
							{category?.name}
						</div>
						{subcategory?.length !== 0 ? (
							<div
								className='flex font-normal flex-1 truncate px-2 justify-center items-center rounded-sm min-h-[30px] max-h[48px] text-center'
								style={{
									backgroundColor: "#F4F5F7 ",
									border: "1px solid #ECECEC",
								}}>
								<span className='truncate'>{subcategory[0]?.name}</span>
							</div>
						) : (
							<div
								className='flex font-normal truncate flex-1 justify-center items-center rounded-sm min-h-[30px] text-center'
								style={{
									backgroundColor: "#F4F5F7 ",
									border: "1px solid #ECECEC",
								}}>
								لايوجد بيانات
							</div>
						)}
					</div>
					<div className='flex flex-1 gap-4'>
						{permissions?.includes("admin.etlobha.update") && (
							<div className='flex items-center justify-center flex-1'>
								<Button
									onClick={() => {
										navigate(`/souq_atlbha/edit-product/${item?.id}`);
									}}
									className={" w-full rounded-none font-medium"}
									fontSize={"text-xs"}
									type={"outline"}
									style={{
										height: "48px",
									}}>
									تعديل المنتج
								</Button>
							</div>
						)}
						<div className='flex items-center justify-center flex-1'>
							<MenuButton
								id='demo-customized-button'
								aria-controls={open ? "demo-customized-menu" : undefined}
								aria-haspopup='true'
								aria-expanded={open ? "true" : undefined}
								variant='contained'
								disableElevation
								onClick={handleClick}
								className={" w-full rounded-none font-medium"}
								style={{
									color: "#1DBBBE",
									backgroundColor: "#FAFAFA",
									border: "1px solid #1DBBBE",
									height: "48px",
								}}
								endIcon={
									<KeyboardArrowDownIcon fill={"#1DBBBE"} className='mr-3' />
								}>
								المزيد
							</MenuButton>
							<Menu
								id='basic-menu'
								anchorEl={menuButton}
								open={open}
								onClose={handleClose}
								MenuListProps={{
									"aria-labelledby": "basic-button",
								}}
								sx={{
									"& ul.MuiList-root": {
										backgroundColor: "#FAFAFA",
										width: "240px",
									},
									"& ul.MuiList-root li": {
										display: "flex",
										gap: "0.75rem",
									},
									"& ul.MuiList-root li svg": {
										height: "1rem",
										width: "1rem",
									},
								}}>
								<MenuItem
									onClick={() => {
										copyLink(
											item?.is_service
												? `https://atlbha-store.com/services/${
														item?.id
												  }/${encodeURIComponent(
														item?.name
															.replace(/[^a-zA-Z0-9\u0621-\u064A]+/g, "-")
															.toLowerCase()
												  )}`
												: `https://atlbha-store.com/products/${encodeURIComponent(
														item?.name
															.replace(/[^a-zA-Z0-9\u0621-\u064A]+/g, "-")
															.toLowerCase()
												  )}/${item?.id}`
										);
										handleClose();
										setEndActionTitle("تم نسخ الرابط بنجاح");
									}}>
									<BiLinkAlt />
									نسخ رابط المنتج
								</MenuItem>
								{permissions?.includes("admin.etlobha.store") && (
									<MenuItem
										onClick={() => {
											navigate(`/souq_atlbha/duplicate-product/${item?.id}`);
											handleClose();
										}}>
										<HiOutlineDocumentDuplicate></HiOutlineDocumentDuplicate>
										تكرار المنتج
									</MenuItem>
								)}
								{permissions?.includes("admin.etlobha.statistics") && (
									<MenuItem
										onClick={() => {
											handleClose();
											handleProductDetails(item);
										}}>
										<StaticsIcon></StaticsIcon>
										إحصائيات المنتج
									</MenuItem>
								)}
								{permissions?.includes("admin.etlobha.specialStatus") && (
									<MenuItem
										onClick={() => {
											handleClose();
											onChangeHandler(id);
										}}>
										<div className=' w-11 m-[-10px]'>
											<Switch
												checked={admin_special === "مميز" ? true : false}
												sx={{
													width: "100%",
													"& .MuiSwitch-track": {
														width: 22,
														height: 12,
														opacity: 1,
														backgroundColor: "rgba(0,0,0,.25)",
														boxSizing: "border-box",
													},
													"& .MuiSwitch-thumb": {
														boxShadow: "none",
														width: 8,
														height: 8,
														borderRadius: 4,
														transform: "translate(5px,6px)",
													},
													"&:hover": {
														"& .MuiSwitch-thumb": {
															boxShadow: "none",
														},
													},

													"& .MuiSwitch-switchBase": {
														padding: 1,
														"&.Mui-checked": {
															transform: "translateX(9px)",
															color: "#fff",
															"& + .MuiSwitch-track": {
																opacity: 1,
																backgroundColor: "#3AE374",
															},
														},
													},
												}}
											/>
										</div>
										<div className='flex-1 mr-[-6px]'> منتج مميز</div>
									</MenuItem>
								)}
								{permissions?.includes("admin.etlobha.deleteall") && (
									<MenuItem
										onClick={() => {
											setActionDelete(
												"سيتم حذف المنتج وهذة الخطوة غير قابلة للرجوع"
											);
											handleClose();
											setItemId(item.id);
										}}>
										<Delete />
										حذف المنتج
									</MenuItem>
								)}
							</Menu>
						</div>
					</div>
				</div>
			</li>

			{actionDelete && (
				<DeleteOneModal handleDeleteSingleItem={handleDeleteSingleItem} />
			)}
		</>
	);
};

const ProductsTable = ({
	data,
	loading,
	pageNumber,
	setPageNumber,
	currentPage,
	setCurrentPage,
	page_count,
}) => {
	const [showProductDetails, setShowProductDetails] = useState(false);
	const [productDetails, setProductDetails] = useState(null);
	const [selected, setSelected] = useState([]);
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);

	const NotificationStore = useContext(NotificationContext);
	const { setItems, notificationTitle, setNotificationTitle, setActionType } =
		NotificationStore;

	const RolePermissionsStore = useContext(RolePermissionsContext);
	const { permissions } = RolePermissionsStore;
	const contextStore = useContext(Context);
	const { setEndActionTitle } = contextStore;

	// handle select all
	const isSelected = (name) => selected.indexOf(name) !== -1;

	/** handle select all products  */
	const handleSelect = (event, name) => {
		const selectedIndex = selected.indexOf(name);
		let newSelected = [];

		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selected, name);
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(selected.slice(1));
		} else if (selectedIndex === selected.length - 1) {
			newSelected = newSelected.concat(selected.slice(0, -1));
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(
				selected.slice(0, selectedIndex),
				selected.slice(selectedIndex + 1)
			);
		}

		setSelected(newSelected);
	};
	const handleSelectAllClick = (event) => {
		if (event.target.checked) {
			const newSelected = data?.map((n) => n.id);
			setSelected(newSelected);
			return;
		}
		setSelected([]);
	};

	const handleProductDetails = (item) => {
		setShowProductDetails(true);
		setProductDetails(item);
	};

	// handle delete all
	const [etlobhaDeleteAll] = useEtlobhaDeleteAllMutation();
	const handleDeleteAllItems = async (selected) => {
		const queryParams = selected?.map((id) => `id[]=${id}`).join("&");
		try {
			await etlobhaDeleteAll({ queryParams })
				.unwrap()
				.then((data) => {
					if (!data?.success) {
						toast.error(data?.message?.ar, {
							theme: "light",
						});
					} else {
						setEndActionTitle(data?.message?.ar);
					}
				});
		} catch (err) {
			console.error("Failed to delete the etlobhaDeleteAll", err);
		}
	};

	// handle change status for all items
	const [etlobhaChangeSatusAll] = useEtlobhaChangeSatusAllMutation();
	const handleChangeAllItemsStatus = async (selected) => {
		const queryParams = selected?.map((id) => `id[]=${id}`).join("&");
		try {
			await etlobhaChangeSatusAll({ queryParams })
				.unwrap()
				.then((data) => {
					if (!data?.success) {
						toast.error(data?.message?.ar, {
							theme: "light",
						});
					} else {
						setEndActionTitle(data?.message?.ar);
					}
				});
		} catch (err) {
			console.error("Failed to change status for all products", err);
		}
	};
	return (
		<>
			<div dir='rtl'>
				{showProductDetails && (
					<ProductDetails
						items={productDetails}
						closeDetails={() => {
							setShowProductDetails(false);
						}}
					/>
				)}
				{data?.length > 0 && (
					<header className='flex items-center gap-4 mb-2'>
						<Checkbox
							checkedIcon={<CheckedSquare />}
							sx={{
								pr: "0",
								color: "#011723",
								"& .MuiSvgIcon-root": {
									color: "#011723",
								},
							}}
							indeterminate={
								selected.length > 0 && selected.length < data?.length
							}
							checked={data?.length > 0 && selected.length === data?.length}
							onChange={handleSelectAllClick}
							inputProps={{
								"aria-label": "select all desserts",
							}}
						/>
						<label style={{ color: "#011723", fontSize: "18px" }} htmlFor='all'>
							تحديد الكل
						</label>
						<div className='flex flex-row items-center justify-center gap-2'>
							{selected.length > 0 && (
								<>
									{permissions?.includes("admin.etlobha.deleteall") && (
										<div
											className='flex flex-row items-center justify-center  cursor-pointer'
											style={{
												width: "130px",
												height: "40px",
												backgroundColor: "#FF38381A",
												borderRadius: "20px",
											}}
											onClick={() => {
												setNotificationTitle(
													"سيتم حذف جميع المنتجات التي قمت بتحديدها"
												);

												setItems(selected);

												setActionType("deleteAll");
											}}>
											<Delete />
											<h6
												style={{ fontSize: "18px", color: "#FF3838" }}
												className='font-medium'>
												حذف الكل
											</h6>
										</div>
									)}
									{permissions?.includes("admin.etlobha.changeStatusall") && (
										<div
											className='md:w-[126px] w-[100px] md:h-[40px] h-[30px] flex flex-row items-center justify-center md:gap-3 gap-1 cursor-pointer'
											style={{
												backgroundColor: "#FF9F1A0A",
												borderRadius: "20px",
											}}
											onClick={() => {
												setNotificationTitle(
													"سيتم تغيير حالة جميع المنتجات التي قمت بتحديدها"
												);
												setItems(selected);
												setActionType("ChangeStatus");
											}}>
											<h6
												style={{ color: "#FF9F1A" }}
												className='font-medium md:text-[18px] text-[15px]'>
												تعطيل
											</h6>
											<Switch
												onChange={() => {}}
												className=''
												sx={{
													width: "50px",
													"& .MuiSwitch-thumb": {
														width: "11px",
														height: "11px",
													},
													"& .MuiSwitch-switchBase": {
														padding: "6px",
														top: "9px",
														left: "9px",
													},
													"& .MuiSwitch-switchBase.Mui-checked": {
														left: "-1px",
													},
													"& .Mui-checked .MuiSwitch-thumb": {
														backgroundColor: "#FFFFFF",
													},
													"& .MuiSwitch-track": {
														height: "16px",
														borderRadius: "20px",
													},
													"&.MuiSwitch-root .Mui-checked+.MuiSwitch-track": {
														backgroundColor: "#FF9F1A",

														opacity: 1,
													},
												}}
												checked={true}
											/>
										</div>
									)}
								</>
							)}
						</div>
					</header>
				)}
				{loading ? (
					<CircularLoading />
				) : data?.length > 0 ? (
					<>
						<ul>
							{data?.map((item, key) => {
								const isItemSelected = isSelected(item.id);
								return (
									<ItemCategory
										{...item}
										item={item}
										key={key}
										isItemSelected={isItemSelected}
										handleSelect={handleSelect}
										handleProductDetails={handleProductDetails}
									/>
								);
							})}
						</ul>

						<NewPagination
							pageNumber={pageNumber}
							setPageNumber={setPageNumber}
							currentPage={currentPage}
							setCurrentPage={setCurrentPage}
							page_count={page_count}
						/>
					</>
				) : (
					<div className='flex flex-col items-center justify-center mt-20 text-lg'>
						لاتوجد بيانات
					</div>
				)}
			</div>
			{notificationTitle && (
				<DeleteModal
					handleDeleteAllItems={handleDeleteAllItems}
					handleChangeAllItemsStatus={handleChangeAllItemsStatus}
				/>
			)}
		</>
	);
};

export default ProductsTable;
