import React, { useState, useContext } from "react";
import styles from "./TraderAlert.module.css";

/* Context */
import { LoadingContext } from "../../../store/LoadingProvider";
import { TextEditorContext } from "../../../store/TextEditorProvider";
/* Components */
import { TextEditor } from "../../TextEditor";
/* Custom Button */
import Button from "../../../UI/Button/Button";
/* MUI */
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

/* Icons */
import { FiSend } from "react-icons/fi";
import { IoIosArrowDown } from "react-icons/io";
import { IoMdCloseCircleOutline } from "react-icons/io";
import { useAddToAlertMutation } from "../../../RTK/apiSlices/subscriptionsApi";
import { toast } from "react-toastify";

const packagesOptions = ["تجديد الإشتراك", "إلغاء الإشتراك"];

const BackDrop = ({ onClick }) => {
	return (
		<div
			onClick={onClick}
			className='fixed top-0 left-0 z-10 w-full h-full opacity-50 back_drop bg-slate-900'></div>
	);
};

const TraderAlert = ({ cancel, traderPackageDetails }) => {
	const LoadingStore = useContext(LoadingContext);
	const { setLoadingTitle } = LoadingStore;
	const editorContent = useContext(TextEditorContext);
	const { editorValue, setEditorValue } = editorContent;

	const [packageOption, setPackageOption] = useState("");

	const handleCategory = (event) => {
		setPackageOption(event.target.value);
	};

	// handle send alert to trader
	const [addToAlert] = useAddToAlertMutation();
	const addToAlertHandel = async (data) => {
		try {
			const res = await addToAlert(data);
			if (res?.data?.success === true && res?.data?.data?.status === 200) {
				setLoadingTitle(null);
				cancel();
				setEditorValue("");
			} else {
				setLoadingTitle(null);
				toast.error(res?.data?.message?.en?.message[0], {
					theme: "colored",
				});
			}
		} catch (error) {
			console.error(error);
		}
	};

	const addStoreNote = () => {
		setLoadingTitle("جاري إرسال تنبيه للتاجر ");
		const data = {
			store_id: traderPackageDetails?.id,
			type: "now",
			subject: "subject",
			message: editorValue,
		};
		addToAlertHandel(data);
	};

	return (
		<>
			<BackDrop onClick={cancel} />
			<div className=' w-[51.25rem] max-w-[90%] max-h-[600px] fixed trader_alert flex flex-col top-[400px] translate-x-2/4 -translate-y-2/4 right-2/4 z-20 rounded-lg md:overflow-hidden overflow-auto'>
				<div className=' bg-[#1DBBBE] flex items-center justify-center w-full h-16 px-4 py-4 trader_alert'>
					<h2 className='flex-1 text-slate-50 text-center md:text-[22px] text-[18px] font-medium'>
						ارسال تنبيه للتاجر{" "}
					</h2>
					<IoMdCloseCircleOutline
						size={"1.25rem"}
						color={"#fff"}
						className={" flex cursor-pointer mr-auto"}
						onClick={cancel}
					/>
				</div>
				<div className='bg-[rgb(246,246,246)]  overflow-y-auto max-h-[520px]  flex-1 p-4 pb-8 md:pt-10 bg-'>
					<div className='flex md:flex-row flex-col md:gap-24 gap-[18px]'>
						<div>
							<h2 className='font-normal md:text-[18px] text-[16px] h-8'>
								نوع التنبيه <span className='text-[#FF0000]'>*</span>
							</h2>
							<Select
								displayEmpty
								value={packageOption}
								onChange={handleCategory}
								IconComponent={IoIosArrowDown}
								className='text-[#1DBBBE] md:w-[11rem] w-full'
								renderValue={(selected) => {
									if (packageOption === "") {
										return <h2 className='text-[#1DBBBE]'>تجديد اشتراك</h2>;
									}
									return selected;
								}}
								sx={{
									height: "3rem",
									backgroundColor: "#fff",
									border: "1px solid rgba(29, 187, 190, 1)",
									"& .MuiOutlinedInput-notchedOutline": {
										border: "none",
									},
									"& .MuiSelect-icon": {
										right: "89%",
										color: "#1DBBBE",
									},
								}}>
								{packagesOptions?.map((item, index) => {
									return (
										<MenuItem
											key={index}
											className='souq_storge_category_filter_items'
											sx={{
												backgroundColor: "rgba(211, 211, 211, 1)",
												height: "3rem",
												"&:hover": {
													backgroundColor: "#1DBBBE",
												},
											}}
											value={`${item}`}>
											{item}
										</MenuItem>
									);
								})}
							</Select>
						</div>
					</div>
					<div className={`${styles.editor} md:mt-20 mt-4 rounded-lg`}>
						<TextEditor ToolBar={"TraderAlert"} placeholder={"نص الرسالة"} />
						<h2>
							صديقنا التاجر،
							<span style={{ color: "#FF9F1A" }}>
								باقي {traderPackageDetails.daysLeft} يوم على انتهاء اشتراكك
							</span>
						</h2>
						<h2>تواصل مع الدعم الفني للحصول على كود خصم لتجديد اشتراكك</h2>
					</div>
					<div className='flex justify-center gap-4'>
						<Button
							onClick={addStoreNote}
							type={"normal"}
							className={
								"text-center mt-12 w-[163px] md:h-16 h-[45px] text-xl font-normal"
							}
							style={{ backgroundColor: "#02466A" }}
							svg={<FiSend color={"#fff"} />}>
							ارسال
						</Button>
						<Button
							type={"outline"}
							className={
								"text-center  mt-12 w-[163px] md:h-16 h-[45px] text-xl font-normal"
							}
							style={{ borderColor: "#02466A" }}
							textStyle={{ color: "#02466A" }}
							onClick={cancel}>
							الغاء
						</Button>
					</div>
				</div>
			</div>
		</>
	);
};

export default TraderAlert;
