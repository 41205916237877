import React, { useState, useContext } from "react";
import styles from "./StorageFilter.module.css";
/* Third party */
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import ImageUploading from "react-images-uploading";
/* Custom Hook */

/* Context */
import { RolePermissionsContext } from "../../../store/RolePermissionsProvider";
/* Custom Button */
import Button from "../../../UI/Button/Button";
/* MUI */
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
/* Icons */
import { AiOutlineSearch } from "react-icons/ai";
import { IoIosArrowDown } from "react-icons/io";
import { Filter } from "../../../assets/Icons";
import { toast } from "react-toastify";
import { useUploadImportProductsMutation } from "../../../RTK/apiSlices/stockApi";
import { useStoreCategoryDataQuery } from "../../../RTK/apiSlices/etlobhaApi";

const StorageFilter = ({
	getSortSelector,
	products,
	search,
	setSearch,

	setFilterResults,
}) => {
	const RolePermissionsStore = useContext(RolePermissionsContext);
	const { permissions } = RolePermissionsStore;
	const { data: categories } = useStoreCategoryDataQuery();

	const [sortSelector, setSortSelector] = useState("");
	const fileType =
		"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
	const fileExtension = ".xlsx";

	// to get the file
	const [images, setImages] = useState([]);
	const maxNumber = 2;
	const onChange = (imageList, addUpdateIndex) => {
		setImages(imageList);
	};
	const exportToCSV = () => {
		const ws = XLSX.utils.json_to_sheet(
			products?.map((item) => ({
				name: item?.name,
				description: item?.description,
				short_description: item?.short_description,
				purchasing_price: item?.purchasing_price,
				selling_price: item?.selling_price,
				category_id: item?.category?.name,
				subcategory_id: item?.subcategory?.map((sub) => sub?.name)?.toString(),
				stock: item?.stock,
				cover: item?.cover,
				seo: item?.SEOdescription?.map((seo) => seo)?.toString(),
				weight: item?.weight,
			}))
		);
		const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
		const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
		const data = new Blob([excelBuffer], { type: fileType });
		FileSaver.saveAs(data, "products" + fileExtension);
	};
	const [uploadImportProducts] = useUploadImportProductsMutation();
	const uploadImportProductsHandel = async (formData) => {
		try {
			const res = await uploadImportProducts({
				body: formData,
			});
			if (res?.data?.success === true && res?.data?.data?.status === 200) {
				setImages([]);
			} else {
				toast.error(res?.data?.message?.en?.file?.[0], {
					theme: "light",
				});
				setImages([]);
			}
		} catch (error) {
			console.error(error);
		}
	};
	const uploadFile = () => {
		let formData = new FormData();
		formData.append("file", images[0]?.file);
		uploadImportProductsHandel(formData);
	};

	return (
		<div className='md:my-8 my-7'>
			<div
				className='flex flex-col gap-4 px-4 py-4 bg-white rounded-lg xl:flex-row md:py-8'
				style={{ boxShadow: " 0px 3px 6px #0000001A" }}>
				<Select
					IconComponent={() => {
						return <IoIosArrowDown className='hidden' />;
					}}
					value={sortSelector}
					onChange={(e) => {
						setSortSelector(e.target.value);
						getSortSelector(e.target.value);
					}}
					displayEmpty
					renderValue={(selected) => {
						if (sortSelector === "") {
							return (
								<>
									<Filter color='#fff' fontSize={"1.5rem"}></Filter>
									<h2 className='font-medium' style={{ color: "#1DBBBE" }}>
										فلتر
									</h2>
								</>
							);
						}
						const result =
							categories?.categories?.filter(
								(item) => item?.id === parseInt(selected)
							) || "";
						return result[0]?.name;
					}}
					className={
						"xl:w-[150px] w-auto rounded-lg justify-center flex gap-3 items-center cursor-pointer md:h-[56px] h-[45px]"
					}
					style={{ border: "1px solid #1DBBBE" }}
					sx={{
						backgroundColor: "#fff",
						width: "100%",
						"& .MuiSelect-select": {
							display: "flex",
							flexDirection: "row",
							alignItems: "center",
							gap: "10px",
						},
					}}>
					<MenuItem
						className='xl:w-[150px] w-full'
						onClick={() => setFilterResults(null)}
						value={""}
						sx={{
							backgroundColor: "#67747B33",
							"ul:has(&)": {
								p: 0,
							},
							"ul:has(&) li:hover": {
								backgroundColor: "#C6E1F0",
							},
						}}>
						الكل
					</MenuItem>
					{categories?.categories?.map((category, index) => {
						return (
							<MenuItem
								className='xl:w-[150px] w-full'
								value={category?.id}
								key={index}
								sx={{
									backgroundColor: "#67747B33",
									"ul:has(&)": {
										p: 0,
									},
									"ul:has(&) li:hover": {
										backgroundColor: "#C6E1F0",
									},
								}}>
								{category?.name}
							</MenuItem>
						);
					})}
				</Select>
				<label className={`md:h-[56px] h-[45px] w-full flex-1 relative `}>
					<input
						className='w-full md:h-[56px] h-[45px] md:text-[18px] text-[16px] outline-0 pr-12 rounded-lg '
						placeholder=' للبحث قم بإدخال رقم أو اسم المنتج'
						type='text'
						name='name'
						style={{ border: "1px solid rgba(29, 187, 190, 1)" }}
						value={search}
						onChange={(e) => setSearch(e.target.value)}
					/>
					<div className={`absolute top-1/2 right-4 -translate-y-2/4`}>
						<AiOutlineSearch color='#1DBBBE' size={"20px"}></AiOutlineSearch>
					</div>
				</label>
				<div className='flex flex-col items-center gap-4 xl:flex-row'>
					<div className='w-full'>
						<ImageUploading
							value={images}
							onChange={onChange}
							maxNumber={maxNumber}
							dataURLKey='data_url'
							acceptType={["xlsx"]}
							allowNonImageType={true}>
							{({ onImageUpload, dragProps }) => (
								// write your building UI
								<div>
									<div
										onClick={() => {
											onImageUpload();
										}}
										{...dragProps}>
										<div className='w-full cursor-pointer image-item'>
											{!images[0] && (
												<span
													className={`${styles.upload_file} w-full md:h-[56px] h-[45px]`}>
													Drop CSV file
												</span>
											)}
											{images[0] && (
												<span
													className={`${styles.upload_file} w-full md:h-[56px] h-[45px]`}>
													{images[0]?.file?.name}
												</span>
											)}
										</div>
									</div>
								</div>
							)}
						</ImageUploading>
					</div>
					<div className='flex flex-row items-center w-full gap-4'>
						<Button
							onClick={uploadFile}
							disabled={images?.length === 0}
							className={
								"w-full md:h-[56px] h-[45px] md:text-[18px] text-[16px]"
							}
							type='normal'>
							رفع ملف
						</Button>
						{permissions?.includes("admin.stock.importStockProducts") && (
							<Button
								onClick={exportToCSV}
								style={{ backgroundColor: "rgb(180,237,238)" }}
								textStyle={{ color: "#03787A" }}
								className={
									"w-full md:h-[56px] h-[45px] md:text-[18px] text-[16px]"
								}
								type='normal'>
								تصدير ملف
							</Button>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default StorageFilter;
