import React, { useEffect, useContext, useState } from "react";
import styles from "./FaqsTable.module.css";
import PropTypes from "prop-types";

/* Third party */
import { useNavigate } from "react-router-dom";

/* Contexts */
import Context from "../../../store/context";
import { DeleteContext } from "../../../store/DeleteProvider";
import { NotificationContext } from "../../../store/NotificationProvider";
import { RolePermissionsContext } from "../../../store/RolePermissionsProvider";

/* Components */
import { toast } from "react-toastify";
import DeleteModalComp from "../../DeleteModal/DeleteModal";
import NewPagination from "../../PaginationComp/NewPagination";
import DeleteOneModalComp from "../../DeleteOneModal/DeleteOneModal";
import CircularLoading from "../../../UI/CircularLoading/CircularLoading";

/* MUI */
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import Paper from "@mui/material/Paper";
import Toolbar from "@mui/material/Toolbar";
import { alpha } from "@mui/material/styles";
import TableRow from "@mui/material/TableRow";
import Checkbox from "@mui/material/Checkbox";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import IconButton from "@mui/material/IconButton";
import TableContainer from "@mui/material/TableContainer";

/* Icons */
import { Delete, EditButton, CheckedSquare } from "../../../assets/Icons/index";
import { AiOutlineSearch } from "react-icons/ai";
import {
	useCommonQuestionsDeleteAllMutation,
	useCommonQuestionsDeleteItemMutation,
	useSearchInCommonQuestionsMutation,
} from "../../../RTK/apiSlices/commonQuestionsApi";

const headCells = [
	{
		id: "situation",
		disablePadding: false,
		label: "الإجراء",
		width: "5rem",
	},
	{
		id: "answer",
		align: "center",
		disablePadding: false,
		label: "الجواب",
	},
	{
		id: "question",
		align: "right",
		disablePadding: false,
		label: "السؤال",
	},

	{
		id: "number",
		align: "right",
		disablePadding: false,
		label: "م",
	},
];

function EnhancedTableHead(props) {
	return (
		<TableHead sx={{ backgroundColor: "#ebebebd9" }}>
			<TableRow>
				{headCells?.map((headCell, index) => (
					<TableCell
						className='font-medium md:text-[18px] text-[16px]'
						key={index}
						align={`${headCell.align ?? "inherit"}`}
						padding={headCell.disablePadding ? "none" : "normal"}
						sx={{
							width: headCell.width ? headCell.width : "auto",
							color: "#011723",
							whiteSpace: "nowrap",
						}}>
						{headCell.label}
					</TableCell>
				))}
				<TableCell padding={"none"}></TableCell>
			</TableRow>
		</TableHead>
	);
}

EnhancedTableHead.propTypes = {
	numSelected: PropTypes.number.isRequired,
	onSelectAllClick: PropTypes.func.isRequired,
};

function EnhancedTableToolbar(props) {
	const {
		numSelected,
		rowCount,
		onSelectAllClick,
		search,
		setSearch,

		itemsSelected,
	} = props;
	const NotificationStore = useContext(NotificationContext);
	const RolePermissionsStore = useContext(RolePermissionsContext);
	const { permissions } = RolePermissionsStore;
	const { setNotificationTitle, setActionType, setItems } = NotificationStore;

	return (
		<Toolbar
			className='flex flex-row flex-wrap items-center justify-end gap-2 p-0 px-2 pt-2 mb-0 md:mb-4 lg:pt-0'
			sx={{
				pl: { sm: 2 },
				pr: { xs: 1, sm: 1 },
				...(numSelected > 0 && {
					bgcolor: (theme) =>
						alpha(
							theme.palette.primary.main,
							theme.palette.action.activatedOpacity
						),
				}),
			}}>
			<div className={styles.search}>
				<input
					value={search}
					onChange={(e) => setSearch(e.target.value)}
					placeholder='يمكنك البحث عن طريق السؤال'
				/>
				<AiOutlineSearch color='#898989' size={"18px"}></AiOutlineSearch>
			</div>
			<div className='flex flex-col-reverse flex-wrap items-end justify-end flex-1 gap-1 p-0 mb-2 sm:flex-row md:gap-4 md:flex-nowrap'>
				<div className='flex items-center gap-2'>
					{permissions?.includes("admin.service.deleteall") &&
						numSelected > 0 && (
							<div
								className='rounded-full fcc'
								style={{
									width: "130px",
									backgroundColor: "rgba(255, 56, 56, 0.1)",
									cursor: "pointer",
								}}
								onClick={() => {
									setNotificationTitle(
										"سيتم حذف جميع الاسئلة التي قمت بتحديدها"
									);
									setItems(itemsSelected);
									setActionType("deleteAll");
								}}>
								<h2
									className={
										"font-medium md:text-[18px] text-[16px] whitespace-nowrap"
									}
									style={{ color: "#FF3838" }}>
									حذف الكل
								</h2>
								<IconButton>
									<Delete
										style={{
											cursor: "pointer",
											color: "red",
											fontSize: "0.8rem",
										}}></Delete>
								</IconButton>
							</div>
						)}
				</div>
				<div className='flex items-center'>
					<h2 className='font-medium md:text-[18px] text-[16px] whitespace-nowrap'>
						تحديد الكل
					</h2>
					<Checkbox
						checkedIcon={<CheckedSquare />}
						sx={{
							pr: "0",
							color: "#011723",
							"& .MuiSvgIcon-root": {
								color: "#011723",
							},
						}}
						indeterminate={numSelected > 0 && numSelected < rowCount}
						checked={rowCount > 0 && numSelected === rowCount}
						onChange={onSelectAllClick}
						inputProps={{
							"aria-label": "select all desserts",
						}}
					/>
				</div>
			</div>
		</Toolbar>
	);
}

EnhancedTableToolbar.propTypes = {
	numSelected: PropTypes.number.isRequired,
};

export default function EnhancedTable({
	fetchedData,
	loading,
	pageNumber,
	setPageNumber,
	currentPage,
	setCurrentPage,
	page_count,
}) {
	const NotificationStore = useContext(NotificationContext);
	const DeleteStore = useContext(DeleteContext);
	const RolePermissionsStore = useContext(RolePermissionsContext);
	const { permissions } = RolePermissionsStore;
	const contextStore = useContext(Context);
	const { setEndActionTitle } = contextStore;
	const navigate = useNavigate();

	const { setItemId, setActionDelete, actionDelete } = DeleteStore;
	const { notificationTitle } = NotificationStore;

	const [selected, setSelected] = React.useState([]);
	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(10);

	const [search, setSearch] = useState("");
	const [searchResults, setSearchResults] = useState([]);
	let faqs = fetchedData?.commonQuestions || [];

	if (search !== "") {
		faqs = searchResults?.questions;
	} else {
		faqs = fetchedData?.commonQuestions;
	}

	const handleSelectAllClick = (event) => {
		if (event.target.checked) {
			const newSelected = faqs?.map((n) => n.id);
			setSelected(newSelected);
			return;
		}
		setSelected([]);
	};

	const [commonQuestionsDeleteAll] = useCommonQuestionsDeleteAllMutation();

	const commonQuestionsDeleteAllHandel = async (selected) => {
		const queryParams = selected?.map((id) => `id[]=${id}`).join("&");
		try {
			await commonQuestionsDeleteAll({ queryParams })
				.unwrap()
				.then((data) => {
					if (data.success) {
						setEndActionTitle(data.message.ar);
					}
				});
		} catch (error) {
			console.error(error);
		}
	};

	const [commonQuestionsDeleteItem] = useCommonQuestionsDeleteItemMutation();
	// delete item
	const handleDeleteSingleItem = async (id) => {
		try {
			await commonQuestionsDeleteItem({ id })
				.unwrap()
				.then((data) => {
					if (!data?.success) {
						toast.error(data?.message?.ar, {
							theme: "light",
						});
					} else {
						setEndActionTitle(data?.message?.ar);
					}
				});
		} catch (err) {
			console.error("Failed to delete the etlbha Product", err);
		}
	};

	const handleClick = (event, id) => {
		const selectedIndex = selected.indexOf(id);
		let newSelected = [];

		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selected, id);
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(selected.slice(1));
		} else if (selectedIndex === selected.length - 1) {
			newSelected = newSelected.concat(selected.slice(0, -1));
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(
				selected.slice(0, selectedIndex),
				selected.slice(selectedIndex + 1)
			);
		}

		setSelected(newSelected);
	};

	const isSelected = (id) => selected.indexOf(id) !== -1;

	// Avoid a layout jump when reaching the last page with empty rows.
	const emptyRows =
		page > 0 ? Math.max(0, (1 + page) * rowsPerPage - faqs?.length) : 0;

	const [searchInCommonQuestions, { isLoading: searchIsLoading }] =
		useSearchInCommonQuestionsMutation();

	useEffect(() => {
		const debounce = setTimeout(() => {
			if (search) {
				const fetchData = async () => {
					try {
						const response = await searchInCommonQuestions({
							query: search,
						});

						setSearchResults(response.data.data);
					} catch (error) {
						console.error("Error fetching orders:", error);
					}
				};

				fetchData();
			} else {
				setSearchResults(faqs);
			}
		}, 500);
		return () => {
			clearTimeout(debounce);
		};
	}, [search]);

	return (
		<Box sx={{ width: "100%" }}>
			<Paper sx={{ width: "100%", mb: 2 }}>
				<EnhancedTableToolbar
					itemsSelected={selected}
					numSelected={selected?.length || 0}
					rowCount={faqs?.length || 0}
					onSelectAllClick={handleSelectAllClick}
					search={search}
					setSearch={setSearch}
					searchResults={searchResults}
					setSearchResults={setSearchResults}
				/>
				<TableContainer>
					<Table size={"medium"}>
						<EnhancedTableHead
							numSelected={selected?.length || 0}
							onSelectAllClick={handleSelectAllClick}
						/>
						<TableBody>
							{loading || searchIsLoading ? (
								<TableRow>
									<TableCell colSpan={4}>
										<CircularLoading />
									</TableCell>
								</TableRow>
							) : (
								<>
									{faqs?.length === 0 ? (
										<TableRow>
											<TableCell colSpan={4}>
												<p className='text-center'>لاتوجد بيانات</p>
											</TableCell>
										</TableRow>
									) : (
										faqs?.map((row, index) => {
											const isItemSelected = isSelected(row.id);
											const labelId = `enhanced-table-checkbox-${index}`;

											return (
												<TableRow
													hover
													role='checkbox'
													aria-checked={isItemSelected}
													tabIndex={-1}
													key={row.id}
													selected={isItemSelected}>
													<TableCell component='th' id={labelId} scope='row'>
														<div className='flex items-center gap-2'>
															{permissions?.includes(
																"admin.commonquestion.deleteall"
															) && (
																<Delete
																	title='حذف'
																	onClick={() => {
																		setActionDelete(
																			"سيتم حذف السؤال وهذة الخطوة غير قابلة للرجوع"
																		);
																		setItemId(row?.id);
																	}}
																	style={{
																		cursor: "pointer",
																		color: "red",
																		fontSize: "1rem",
																	}}
																/>
															)}
															{permissions?.includes(
																"admin.commonquestion.update"
															) && (
																<EditButton
																	title='تعديل'
																	className={styles.info_icon}
																	onClick={() => {
																		navigate(`/faqs/edit-question/${row.id}`);
																	}}
																/>
															)}
														</div>
													</TableCell>
													<TableCell align='center'>
														<div className=''>
															<h2
																className={`${styles.OverflowText} font-normal md:text-[18px] text-[16px] whitespace-nowra`}
																dangerouslySetInnerHTML={{
																	__html: row?.answer,
																}}
															/>
														</div>
													</TableCell>

													<TableCell align='right'>
														<div className='flex flex-row items-center justify-end gap-3'>
															<h2
																className={`${styles.OverflowText} font-normal md:text-[18px] text-[16px] whitespace-nowra`}>
																{row?.question}
															</h2>
														</div>
													</TableCell>
													<TableCell
														className='font-normal md:text-[18px] text-[16px] whitespace-nowrap'
														align='right'>
														{(index + 1).toLocaleString("en-US", {
															minimumIntegerDigits: 2,
															useGrouping: false,
														})}
													</TableCell>
													<TableCell padding='none' align={"right"}>
														<Checkbox
															checkedIcon={<CheckedSquare />}
															sx={{
																color: "#011723",
																"& .MuiSvgIcon-root": {
																	color: "#011723",
																},
															}}
															checked={isItemSelected}
															onClick={(event) => handleClick(event, row.id)}
															inputProps={{
																"aria-labelledby": labelId,
															}}
														/>
													</TableCell>
												</TableRow>
											);
										})
									)}
								</>
							)}
							{emptyRows > 0 && (
								<TableRow
									style={{
										height: 53 * emptyRows,
									}}>
									<TableCell colSpan={4} />
								</TableRow>
							)}
						</TableBody>
					</Table>
				</TableContainer>
			</Paper>

			{!search && (
				<>
					<NewPagination
						pageNumber={pageNumber}
						setPageNumber={setPageNumber}
						currentPage={currentPage}
						setCurrentPage={setCurrentPage}
						page_count={page_count}
					/>
				</>
			)}
			{actionDelete && (
				<DeleteOneModalComp handleDeleteSingleItem={handleDeleteSingleItem} />
			)}
			{notificationTitle && (
				<DeleteModalComp
					handleDeleteAllItems={commonQuestionsDeleteAllHandel}
				/>
			)}
		</Box>
	);
}
