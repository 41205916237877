import React, { useContext, useState } from "react";
import styles from "./AddNewUser.module.css";
/* Third party */
import { Helmet } from "react-helmet";
import { useForm, Controller } from "react-hook-form";
import ImageUploading from "react-images-uploading";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
/* Custom Hook */

/* Contexts */
import { LoadingContext } from "../../../store/LoadingProvider";
/* Custom Button */
import Button from "../../../UI/Button/Button";
/* MUI */
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
/* Icons */
import { GoArrowRight } from "react-icons/go";
import { IoIosArrowDown } from "react-icons/io";
import { BiLinkAlt } from "react-icons/bi";
import { AiFillStar } from "react-icons/ai";
import { PasswordClose, PasswordOpen } from "../../../assets/Icons";
import { useAdminRolesQuery } from "../../../RTK/apiSlices/selectorApi";
import { useAddNewUserMutation } from "../../../RTK/apiSlices/usersApi";

const AddNewUser = () => {
	const navigate = useNavigate();
	const { data: roleList } = useAdminRolesQuery();

	const LoadingStore = useContext(LoadingContext);
	const { setLoadingTitle } = LoadingStore;
	const [showPassword, setShowPassword] = React.useState(false);
	const [showPasswordConfirm, setShowPasswordConfirm] = React.useState(false);
	const {
		register,
		handleSubmit,
		control,
		formState: { errors },
	} = useForm({
		mode: "onBlur",
		defaultValues: {
			name: "",
			user_name: "",
			email: "",
			phonenumber: "",
			password: "",
			password_confirm: "",
			role: "",
		},
	});
	const [DataError, setDataError] = useState({
		name: "",
		user_name: "",
		email: "",
		phonenumber: "",
		role: "",
		password: "",
		password_confirm: "",
		image: "",
	});
	const resetData = () => {
		setDataError({
			name: "",
			user_name: "",
			email: "",
			phonenumber: "",
			role: "",
			password: "",
			password_confirm: "",
			image: "",
		});
	};
	// handle images size
	const maxFileSize = 1 * 1024 * 1024; // 1 MB;
	const [images, setImages] = useState([]);
	const onChangeImage = (imageList, addUpdateIndex) => {
		// Check image size before updating state
		const isSizeValid = imageList.every(
			(image) => image.file.size <= maxFileSize
		);

		if (!isSizeValid) {
			setDataError({
				...DataError,
				image: " حجم الصورة يجب أن لا يزيد عن 1 ميجابايت.",
			});
			toast.warning(" حجم الصورة يجب أن لا يزيد عن 1 ميجابايت.", {
				theme: "light",
			});
		} else {
			setImages(imageList);
			setDataError({ ...DataError, image: null });
		}
	};
	const [addNewUser] = useAddNewUserMutation();
	const addNewUserHandel = async (formData) => {
		try {
			const res = await addNewUser({
				body: formData,
			});
			if (res?.data?.success === true && res?.data?.data?.status === 200) {
				setLoadingTitle(null);
				navigate("/users");
			} else {
				setLoadingTitle(null);

				setDataError({
					name: res?.data?.message?.en?.name?.[0],
					user_name: res?.data?.message?.en?.user_name?.[0],
					email: res?.data?.message?.en?.email?.[0],
					phonenumber: res?.data?.message?.en?.phonenumber?.[0],
					role: res?.data?.message?.en?.role?.[0],
					password: res?.data?.message?.en?.password?.[0],
					password_confirm: res?.data?.message?.en?.password_confirm?.[0],
					image: res?.data?.message?.en?.image?.[0],
				});
				toast.error(res?.data?.message?.en?.name?.[0], { theme: "light" });
				toast.error(res?.data?.message?.en?.user_name?.[0], {
					theme: "light",
				});
				toast.error(res?.data?.message?.en?.email?.[0], { theme: "light" });
				toast.error(res?.data?.message?.en?.phonenumber?.[0], {
					theme: "light",
				});
				toast.error(res?.data?.message?.en?.role?.[0], { theme: "light" });
				toast.error(res?.data?.message?.en?.password?.[0], {
					theme: "light",
				});
				toast.error(res?.data?.message?.en?.password_confirm?.[0], {
					theme: "light",
				});
				toast.error(res?.data?.message?.en?.image?.[0], { theme: "light" });
			}
		} catch (error) {
			console.error(error);
		}
	};

	const handleAddUser = (data) => {
		setLoadingTitle("جاري حفظ المستخدم");
		resetData();
		let formData = new FormData();
		formData.append("name", data?.name);
		formData.append("user_name", data?.user_name);
		formData.append("email", data?.email);
		formData.append(
			"phonenumber",
			data?.phonenumber?.startsWith("+966") ||
				data?.phonenumber?.startsWith("00966")
				? data?.phonenumber
				: `+966${data?.phonenumber}`
		);
		formData.append("role", data?.role);
		formData.append("password", data?.password);
		formData.append("password_confirm", data?.password_confirm);

		if (images.length !== 0) {
			formData.append("image", images[0]?.file || null);
		}
		addNewUserHandel(formData);
	};

	// handle paste phone number to handle remove space
	const handlePastePhoneNumber = (e) => {
		e.preventDefault();
		let pastedData = (e.clipboardData || window.clipboardData).getData("text");
		pastedData = pastedData.replace(/\s+/g, "");
		e.target.value = pastedData;
	};
	return (
		<>
			<Helmet>
				<title>لوحة تحكم أطلبها | اضافة مستخدم</title>
			</Helmet>
			<div
				className={`${
					styles.add_user
				} ${"xl:h-auto mdl:h-auto"} w-full xs:h-auto lg:h-auto absolute  top-0 left-0 pt-4 pb-40 xs:pb-4 sm:pb-40 lg:pb-40 px-4  lg:pr-2 z-10 lg:pt-9   lg:bg-[#fafafa] bg-[#ffffff]`}>
				<div className='flex items-center justify-between mb-2'>
					<div className='flex items-center gap-2'>
						<div
							onClick={() => navigate("/users")}
							className={`flex cursor-pointer items-center gap-2 `}>
							<div className={`flex items-center gap-2  ${styles.arrow_con} `}>
								<GoArrowRight className='text-[#02466A] text-[1.2rem]' />
							</div>
							<h2 className='font-medium md:text-lg text-[16px] whitespace-nowrap ml-1 text-[#011723]'>
								جدول المستخدمين
							</h2>
						</div>
						<h3
							className='font-medium md:text-lg text-[16px] whitespace-nowrap'
							style={{ color: "#7C7C7C" }}>
							<span className='ml-1'>/</span> اضافة مستخدم جديد
						</h3>
					</div>
				</div>
				<div
					className={
						"bg-white mt-12 md:p-6 mx-1 md:shadow-[0px_3px_6px_#0000000F] pb-[1rem]"
					}>
					<form onSubmit={handleSubmit(handleAddUser)}>
						<h2 className='font-medium md:text-2xl text-[20px] text-[#0077FF]'>
							<AiFillStar className='inline-block ml-4 text-red-500' />
							بيانات المستخدم
						</h2>
						<div className='flex flex-row flex-wrap max-w-full'>
							<div className='flex-1 max-w-full md:px-8'>
								<div className='max-w-full mt-6'>
									<h2 className='font-medium mb-2 md:text-lg text-[16px] text-[#011723]'>
										الدور الوظيفى<span className='text-red-500'>*</span>
									</h2>
									<div className='flex flex-col max-w-full gap-2'>
										<Controller
											name={"role"}
											control={control}
											rules={{ required: "حقل الدور مطلوب" }}
											render={({ field: { onChange, value } }) => (
												<Select
													className='rounded-lg h-14 pl-2 text-lg font-normal xs:min-w-[375px] min-w-full '
													value={value}
													onChange={onChange}
													IconComponent={() => {
														return <IoIosArrowDown size={"1.5rem"} />;
													}}
													displayEmpty
													inputProps={{ "aria-label": "Without label" }}
													renderValue={(selected) => {
														if (!selected) {
															return (
																<h2 className='md:text-lg text-[16px] font-normal'>
																	اختر نوع الدور الوظيفي
																</h2>
															);
														}
														const result = roleList?.roles?.filter(
															(item) => item?.name === selected
														);
														return result[0]?.name;
													}}
													sx={{
														width: "100%",
														maxWidth: "100%",
														border: "none",
														backgroundColor: "#B4EDEE",
														"& .MuiOutlinedInput-notchedOutline": {
															border: "none",
															paddingRight: "10px",
														},
													}}>
													{roleList?.roles?.map((item, index) => {
														return (
															<MenuItem
																key={index}
																className='souq_storge_category_filter_items'
																sx={{
																	backgroundColor: "rgba(211, 211, 211, 1)",
																	height: "3rem",
																	"&:hover": {},
																}}
																value={`${item?.name}`}>
																{item?.name}
															</MenuItem>
														);
													})}
												</Select>
											)}
										/>
										<span className='text-red-500 text-[16px]'>
											{DataError?.role}
											{errors?.role && errors.role.message}
										</span>
									</div>
								</div>
								<div className='mt-6'>
									<h2 className='font-medium mb-2 md:text-lg text-[16px] text-[#011723]'>
										الاسم<span className='text-red-500'>*</span>
									</h2>
									<div className='flex flex-col w-full gap-2'>
										<input
											className='xs:min-w-[375px]  border-none min-w-full max-w-full bg-[#eff9ff]  outline-none p-4 rounded-lg h-14'
											type='text'
											placeholder='ادخل حروف فقط'
											name='name'
											{...register("name", {
												required: "حقل الاسم مطلوب",
												pattern: {
													value: /^[^-\s][\u0600-\u06FF-A-Za-z ]+$/i,
													message: "يجب ان يتكون الاسم من أحرف",
												},
											})}
										/>
										<span className='text-red-500 text-[16px]'>
											{DataError?.name}
											{errors?.name && errors.name.message}
										</span>
									</div>
								</div>
								<div className='mt-6'>
									<h2 className='font-medium mb-2 md:text-lg text-[16px] text-[#011723]'>
										اسم المستخدم<span className='text-red-500'>*</span>
									</h2>
									<div className='flex flex-col w-full gap-2'>
										<input
											className='xs:min-w-[375px]  border-none min-w-full max-w-full bg-[#eff9ff]  outline-none p-4 rounded-lg h-14'
											type='text'
											placeholder='ادخل حروف فقط'
											name='user_name'
											{...register("user_name", {
												required: "حقل اسم المُستخدم مطلوب",
												pattern: {
													value: /^[^-\s][a-zA-Z0-9_]+$/,
													message:
														"يجب ان يتكون اسم المُستخدم من أحرف انجليزية",
												},
											})}
										/>
										<span className='text-red-500 text-[16px]'>
											{DataError?.user_name}
											{errors?.user_name && errors.user_name.message}
										</span>
									</div>
								</div>
								<div className='mt-6 '>
									<h2 className='font-medium mb-2 md:text-lg text-[16px] text-[#011723]'>
										البريد الالكترونى<span className='text-red-500'>*</span>
									</h2>
									<div className='flex flex-col w-full gap-2'>
										<input
											className='xs:min-w-[375px]  border-none min-w-full max-w-full bg-[#eff9ff]  outline-none p-4 rounded-lg h-14'
											type='email'
											placeholder='sample@sa.com'
											name='email'
											{...register("email", {
												required: "حقل البريد الالكتروني مطلوب",
												pattern: {
													value: /\S+@\S+\.\S+/,
													message:
														"يجب أن يكون البريد الالكتروني عنوان بريد إلكتروني صحيح البُنية",
												},
											})}
										/>
										<span className='text-red-500 text-[16px]'>
											{DataError?.email}
											{errors?.email && errors.email.message}
										</span>
									</div>
								</div>
								<div className='mt-6'>
									<h2 className='font-medium mb-0 md:text-lg text-[16px] text-[#011723]'>
										رقم الهاتف<span className='text-red-500'>*</span>
									</h2>
									<div className='flex flex-col w-full gap-2'>
										<div
											className='xs:min-w-[375px] border-none min-w-full max-w-full bg-[#eff9ff]  flex flex-row items-center gap-[1px] h-14 p-4 mt-3 rounded-lg'
											style={{
												direction: "ltr",
											}}>
											<span>+966</span>
											<input
												className='w-full h-full outline-none  border-none min-w-full max-w-full bg-transparent'
												style={{
													direction: "ltr",
												}}
												type='tel'
												placeholder='515131515'
												maxLength={9}
												name='phonenumber'
												onPaste={(e) => {
													handlePastePhoneNumber(e);
												}}
												{...register("phonenumber", {
													required: "حقل  رقم الجوال مطلوب",
													pattern: {
														value: /^(5\d{8})$/,
														message:
															"تأكد ان رقم الجوال يبدأ برقم 5 ولا يقل عن 9 أرقام",
													},
												})}
											/>
										</div>
										<span className='text-red-500 text-[16px]'>
											{DataError?.phonenumber}
											{errors?.phonenumber && errors.phonenumber.message}
										</span>
									</div>
								</div>
							</div>
							<div className='flex-1 max-w-full md:px-8'>
								<div className='mt-6 '>
									<h2 className='font-medium mb-2 md:text-lg text-[16px] text-[#011723]'>
										كلمة المرور<span className='text-red-500'>*</span>
									</h2>
									<div className='flex flex-col w-full gap-2'>
										<div className='flex flex-row overflow-hidden border-none max-w-full bg-[#EFF9FF] items-center justify-between xs:min-w-[375px] min-w-full  p-4 h-14'>
											<input
												className='w-full h-full rounded-lg outline-none max-w-full border-none bg-[#EFF9FF] pl-2 -mb-[2px]'
												style={{
													direction: "ltr",
												}}
												type={showPassword ? "text" : "password"}
												placeholder='00000000'
												name='password'
												{...register("password", {
													required: "حقل كلمة المرور مطلوب",
													minLength: {
														value: 6,
														message:
															"حقل كلمة المرور يجب ان يتكون على الأقل من ستة أحرف",
													},
												})}
											/>
											{showPassword ? (
												<PasswordOpen
													className={styles.password}
													onClick={() => setShowPassword(!showPassword)}
												/>
											) : (
												<PasswordClose
													className={styles.password}
													onClick={() => setShowPassword(!showPassword)}
												/>
											)}
										</div>
										<p className='text-[#ADB5B9] font-normal md:text-lg text-[16px]'>
											أدخل أرقام وحروف ورموز
										</p>
										<span className='text-red-500 text-[16px]'>
											{DataError?.password}
											{errors?.password && errors.password.message}
										</span>
									</div>
								</div>
								<div className='mt-6 '>
									<h2 className='font-medium md:text-lg text-[16px] mb-2'>
										تأكيد كلمة المرور<span className='text-red-500'>*</span>
									</h2>
									<div className='flex flex-col w-full gap-2'>
										<div className='flex flex-row overflow-hidden border-none max-w-full bg-[#EFF9FF] items-center justify-between xs:min-w-[375px] min-w-full  p-4 h-14'>
											<input
												className='w-full h-full rounded-lg outline-none max-w-full border-none bg-[#EFF9FF] pl-2 -mb-[2px]'
												style={{
													direction: "ltr",
												}}
												type={showPasswordConfirm ? "text" : "password"}
												placeholder='00000000'
												name='password_confirm'
												{...register("password_confirm", {
													required: "حقل تأكيد كلمة المرور مطلوب",
													minLength: {
														value: 6,
														message:
															"حقل تأكيد كلمة المرور يجب ان يتكون على الأقل من ستة أحرف",
													},
												})}
											/>
											{showPasswordConfirm ? (
												<PasswordOpen
													className={styles.password}
													onClick={() =>
														setShowPasswordConfirm(!showPasswordConfirm)
													}
												/>
											) : (
												<PasswordClose
													className={styles.password}
													onClick={() =>
														setShowPasswordConfirm(!showPasswordConfirm)
													}
												/>
											)}
										</div>
										<p className='text-[#ADB5B9] font-normal md:text-lg text-[16px]'>
											أدخل أرقام وحروف ورموز
										</p>
										<span className='text-red-500 text-[16px]'>
											{DataError?.password_confirm}
											{errors?.password_confirm &&
												errors.password_confirm.message}
										</span>
									</div>
								</div>
								<div className='mt-6 '>
									<div className='flex flex-col mb-2'>
										<h2 className='font-medium md:text-lg text-[16px] text-[#011723]'>
											الصورة الشخصية
										</h2>
										<span className='text-gray-400 font-light text-[14px]'>
											(الحد الأقصى للصورة 1MB)
										</span>
									</div>
									<div className='flex flex-col w-full gap-2'>
										<ImageUploading
											value={images}
											onChange={onChangeImage}
											maxNumber={2}
											dataURLKey='data_url'
											acceptType={["jpg", "png", "jpeg", "webp", "svg"]}>
											{({ onImageUpload, dragProps }) => (
												// write your building UI
												<div
													className='max-w-full upload__image-wrapper  xs:min-w-[375px] min-w-full  relative h-14 flex items-center overflow-hidden rounded-lg'
													style={{ border: "none", backgroundColor: "#EFF9FF" }}
													{...dragProps}>
													<h2
														className='w-full p-4 outline-none cursor-pointer'
														style={{
															maxWidth: "100%",
															backgroundColor: "#EFF9FF",
															color: "#aaa",
														}}
														onClick={() => {
															onImageUpload();
														}}>
														{images[0]?.file?.name || "أدخل الصورة الشخصية"}
													</h2>
													<div
														className='flex items-center justify-center h-full'
														style={{
															width: "3.5rem",
															backgroundColor: "#02466A",
														}}>
														<BiLinkAlt color='#fff' />
													</div>
													{/* <div className="w-full h-full cursor-pointer image-item"> */}
													{/* <button
                          style={isDragging ? { color: "red" } : null}
                          onClick={onImageUpload}
                          {...dragProps}
                        >
                          Click or Drop here
                        </button> */}
												</div>
											)}
										</ImageUploading>
										<span className='text-red-500 text-[16px]'>
											{DataError?.image}
										</span>
									</div>
								</div>
							</div>
						</div>
						<div className='max-w-full mt-8 md:mt-10'>
							<Button
								btnType={"submit"}
								className='md:h-14 h-[45px] w-[186px] text-xl'
								style={{ backgroundColor: "#02466A", margin: "0 auto" }}
								type={"normal"}>
								حفظ واعتماد
							</Button>
						</div>
					</form>
				</div>
			</div>
		</>
	);
};

export default AddNewUser;
