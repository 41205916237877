import React, { useContext, useEffect, Fragment } from "react";

import styles from "./SouqAtlbhaOrdersTable.module.css";
import PropTypes from "prop-types";
import moment from "moment";

import PlaceholderImage from "../../../assets/images/placeholderImage.png";

import { useNavigate } from "react-router-dom";
/* Context */
import Context from "../../../store/context";
import { NotificationContext } from "../../../store/NotificationProvider";
import { RolePermissionsContext } from "../../../store/RolePermissionsProvider";
/* Components */
import CircularLoading from "../../../UI/CircularLoading/CircularLoading";

/* MUI */
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Paper from "@mui/material/Paper";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import Checkbox from "@mui/material/Checkbox";
import { visuallyHidden } from "@mui/utils";
/* Icons */
import {
	InfoIcon,
	SortIcon,
	Delete,
	CheckedSquare,
} from "../../../assets/Icons";
import { AiOutlineSearch } from "react-icons/ai";
import {
	useImportOrdersDeleteAllMutation,
	useSearchInImportOrdersMutation,
} from "../../../RTK/apiSlices/talabatEtlobhaApi";
import NewPagination from "../../PaginationComp/NewPagination";
import { useShippingCitiesDataQuery } from "../../../RTK/apiSlices/selectorApi";
import DeleteModalComp from "../../DeleteModal/DeleteModal";
// ---------------------------------------------------

function descendingComparator(a, b, orderBy) {
	if (b[orderBy] < a[orderBy]) {
		return -1;
	}
	if (b[orderBy] > a[orderBy]) {
		return 1;
	}
	return 0;
}

function getComparator(order, orderBy) {
	return order === "desc"
		? (a, b) => descendingComparator(a, b, orderBy)
		: (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
	const stabilizedThis = array?.map((el, index) => [el, index]);
	stabilizedThis?.sort((a, b) => {
		const order = comparator(a[0], b[0]);
		if (order !== 0) {
			return order;
		}
		return a[1] - b[1];
	});
	return stabilizedThis?.map((el) => el[0]);
}

// Table Header
const headCells = [
	{
		id: "situation",
		numeric: false,
		disablePadding: false,
		label: "الإجراء",
		width: "5rem",
	},
	{
		sort: true,
		numeric: false,
		disablePadding: false,
		id: "order_date",
		label: "تاريخ الطلب",
		sortLabel: "created_at",
	},
	{
		sort: true,
		numeric: false,
		disablePadding: false,
		id: "total_price",
		label: "اجمالي الطلب",
		sortLabel: "total_price",
	},
	{
		id: "quantity",
		numeric: false,
		disablePadding: false,
		label: "الكمية",
		width: "5rem",
	},
	{
		id: "status",
		sortLabel: "status",
		sort: true,
		numeric: false,
		disablePadding: false,
		label: "حالة الطلب",
	},
	{
		id: "store",
		numeric: true,
		disablePadding: false,
		label: "المدينة",
	},
	{
		id: "store",
		numeric: true,
		disablePadding: false,
		label: "اسم المتجر",
	},
	{
		id: "orderNumber",
		numeric: false,
		disablePadding: false,
		label: "رقم الطلب",
	},

	{
		id: "name",
		numeric: false,
		disablePadding: false,
		label: "م",
	},
];

function EnhancedTableHead(props) {
	const { order, orderBy, onRequestSort } = props;
	const createSortHandler = (property) => (event) => {
		onRequestSort(event, property);
	};

	return (
		<TableHead sx={{ backgroundColor: "#0000000F" }}>
			<TableRow>
				{headCells?.map((headCell, index) => (
					<TableCell
						className='md:text-[18px] text-[14px] font-medium'
						key={index}
						align={headCell?.numeric ? "right" : "center"}
						padding={headCell?.disablePadding ? "none" : "normal"}
						sortDirection={orderBy === headCell?.id ? order : false}
						sx={{
							width: headCell?.width ? headCell?.width : "auto",
							color: "#826fa2",
							whiteSpace: "nowrap",
						}}>
						{!headCell?.sort && headCell?.label}
						{headCell?.sort && (
							<TableSortLabel
								sx={{
									display: "flex",
									flexDirection: "row-reverse",
									justifyContent: "center",
								}}
								IconComponent={() => {
									return <SortIcon />;
								}}
								active={orderBy === headCell?.id}
								direction={orderBy === headCell?.id ? order : "asc"}
								onClick={createSortHandler(headCell?.sortLabel)}>
								{!orderBy === headCell?.id ? (
									<Box component='span' sx={visuallyHidden}>
										{order === "desc"
											? "sorted descending"
											: "sorted ascending"}
									</Box>
								) : null}

								{headCell?.label}
							</TableSortLabel>
						)}
					</TableCell>
				))}
				<TableCell padding={"none"}></TableCell>
			</TableRow>
		</TableHead>
	);
}

EnhancedTableHead.propTypes = {
	onRequestSort: PropTypes.func.isRequired,

	order: PropTypes.oneOf(["asc", "desc"]).isRequired,
	orderBy: PropTypes.string.isRequired,
};

function EnhancedTableToolbar(props) {
	const {
		numSelected,
		search,
		setSearch,
		itemsSelected,
		rowCount,
		onSelectAllClick,
	} = props;
	const NotificationStore = useContext(NotificationContext);
	const { setNotificationTitle, setActionType, setItems } = NotificationStore;
	const RolePermissionsStore = useContext(RolePermissionsContext);
	const { permissions } = RolePermissionsStore;

	return (
		<Toolbar
			className='flex flex-row-reverse flex-wrap items-center justify-between gap-2 p-0 px-2 mb-4 md:mb-0'
			sx={{
				pl: { sm: 2 },
				pr: { xs: 1, sm: 1 },
				...(numSelected > 0 && {
					bgcolor: (theme) =>
						alpha(
							theme.palette.primary.contrastText,
							theme.palette.action.activatedOpacity
						),
				}),
			}}>
			<div className='flex flex-row items-center gap-4'>
				{permissions?.includes("admin.adminOrder.deleteall") && (
					<div className='flex items-center gap-2'>
						{numSelected > 0 && (
							<Tooltip
								onClick={() => {
									setNotificationTitle(
										"سيتم حذف جميع الطلبات التي قمت بتحديدها"
									);

									setItems(itemsSelected);
									setActionType("deleteAll");
								}}
								title='Delete'>
								<div
									className='px-4 rounded-full fcc'
									style={{ backgroundColor: "#FF38381A" }}>
									<IconButton>
										<Delete
											style={{
												cursor: "pointer",
												color: "red",
												fontSize: "0.6rem",
											}}></Delete>
									</IconButton>
									<h2
										className={"font-medium  text-[16px]"}
										style={{ color: "#FF3838" }}>
										حذف الكل
									</h2>
								</div>
							</Tooltip>
						)}
					</div>
				)}

				<div className='flex items-center'>
					<h2 className='font-medium md:text-[18px] text-[16px]'>تحديد الكل</h2>
					<Checkbox
						checkedIcon={<CheckedSquare />}
						sx={{
							pr: "0",
							color: "#011723",
							"& .MuiSvgIcon-root": {
								color: "#011723",
							},
						}}
						indeterminate={numSelected > 0 && numSelected < rowCount}
						checked={rowCount > 0 && numSelected === rowCount}
						onChange={onSelectAllClick}
						inputProps={{
							"aria-label": "select all desserts",
						}}
					/>
				</div>
			</div>
			<div className={styles.search}>
				<input
					value={search}
					onChange={(e) => setSearch(e.target.value)}
					placeholder='يمكنك البحث عن طريق اسم المتجر أو رقم الطلب'
				/>
				<AiOutlineSearch color='#898989' size={"18px"}></AiOutlineSearch>
			</div>
		</Toolbar>
	);
}

EnhancedTableToolbar.propTypes = {
	numSelected: PropTypes.number,
};

export default function SouqAtlbhaOrdersTable({
	fetchedData,
	loading,
	search,
	setSearch,
	searchResults,
	setSearchResults,
	pageNumber,
	setPageNumber,
	currentPage,
	setCurrentPage,
	page_count,
}) {
	const navigate = useNavigate();
	const { data: shippingCities } = useShippingCitiesDataQuery();
	const NotificationStore = useContext(NotificationContext);
	const { notificationTitle } = NotificationStore;

	const RolePermissionsStore = useContext(RolePermissionsContext);
	const contextStore = useContext(Context);
	const { setEndActionTitle } = contextStore;
	const { permissions } = RolePermissionsStore;
	const [order, setOrder] = React.useState("asc");
	const [orderBy, setOrderBy] = React.useState("calories");
	const [selected, setSelected] = React.useState([]);
	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(10);

	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === "asc";
		setOrder(isAsc ? "desc" : "asc");
		setOrderBy(property);
	};

	const handleSelectAllClick = (event) => {
		if (event.target.checked) {
			const newSelected = fetchedData?.map((n) => n.id);
			setSelected(newSelected);
			return;
		}
		setSelected([]);
	};

	const handleClick = (event, id) => {
		const selectedIndex = selected.indexOf(id);
		let newSelected = [];

		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selected, id);
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(selected.slice(1));
		} else if (selectedIndex === selected.length - 1) {
			newSelected = newSelected.concat(selected.slice(0, -1));
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(
				selected.slice(0, selectedIndex),
				selected.slice(selectedIndex + 1)
			);
		}

		setSelected(newSelected);
	};

	const isSelected = (id) => selected?.indexOf(id) !== -1;

	// Avoid a layout jump when reaching the last page with empty rows.
	const emptyRows =
		page > 0 ? Math.max(0, (1 + page) * rowsPerPage - fetchedData?.length) : 0;

	const [searchInImportOrders, { isLoading: searchIsLoading }] =
		useSearchInImportOrdersMutation();

	useEffect(() => {
		const debounce = setTimeout(() => {
			if (search) {
				const fetchData = async () => {
					try {
						const response = await searchInImportOrders({
							query: search,
						});

						setSearchResults(response.data.data);
					} catch (error) {
						console.error("Error fetching orders:", error);
					}
				};

				fetchData();
			} else {
				setSearchResults(fetchedData);
			}
		}, 500);
		return () => {
			clearTimeout(debounce);
		};
	}, [search]);

	function translateCityName(name) {
		const unique = shippingCities?.cities?.find((obj) => obj?.name_en === name);
		return unique?.name || name;
	}

	// delete all selected orders
	const [importOrdersDeleteAll] = useImportOrdersDeleteAllMutation();
	const importOrdersDeleteAllHandel = async (selected) => {
		const queryParams = selected?.map((id) => `id[]=${id}`).join("&");
		try {
			await importOrdersDeleteAll({ queryParams })
				.unwrap()
				.then((data) => {
					if (data.success) {
						setEndActionTitle(data.message.ar);
					}
				});
		} catch (error) {
			console.error(error);
		}
	};
	return (
		<Box sx={{ width: "100%" }}>
			<Paper
				sx={{
					backgroundColor: "#fff",
					width: "100%",
					mb: 2,
					boxShadow: "0 0",
				}}>
				<EnhancedTableToolbar
					itemsSelected={selected}
					numSelected={selected?.length || 0}
					rowCount={fetchedData?.length || 0}
					onSelectAllClick={handleSelectAllClick}
					search={search}
					setSearch={setSearch}
					searchResults={searchResults}
					setSearchResults={setSearchResults}
				/>
				<TableContainer>
					<Table
						sx={{
							minWidth: 750,
							backgroundColor: "#ffffff",
							marginBottom: "3rem",
						}}
						aria-labelledby='tableTitle'
						size={"medium"}>
						<EnhancedTableHead
							order={order}
							orderBy={orderBy}
							onRequestSort={handleRequestSort}
						/>
						<TableBody>
							{loading || searchIsLoading ? (
								<TableRow>
									<TableCell colSpan={9}>
										<CircularLoading />
									</TableCell>
								</TableRow>
							) : (
								<Fragment>
									{fetchedData?.length === 0 ? (
										<TableRow>
											<TableCell colSpan={9}>
												<p className='text-center'>لاتوجد بيانات</p>
											</TableCell>
										</TableRow>
									) : (
										stableSort(fetchedData, getComparator(order, orderBy))?.map(
											(row, index) => {
												const isItemSelected = isSelected(row?.id);
												const labelId = `enhanced-table-checkbox-${index}`;

												return (
													<TableRow
														hover
														role='checkbox'
														aria-checked={isItemSelected}
														tabIndex={-1}
														key={index}
														selected={isItemSelected}>
														<TableCell component='th' scope='row'>
															<div className='flex items-center '>
																{permissions?.includes(
																	"admin.adminOrder.show"
																) && (
																	<InfoIcon
																		title='تفاصيل الطلب'
																		className='w-6 h-6 cursor-pointer'
																		onClick={() => {
																			navigate(
																				`/talabat_souq_atlbha/show-details/${row?.id}`
																			);
																		}}
																	/>
																)}
															</div>
														</TableCell>

														<TableCell align='center'>
															<h2
																className='md:text-[18px] text-[16px] font-normal'
																style={{ color: "#4D4F5C", margin: "0 auto" }}>
																{moment(row?.created_at).format("YYYY-MM-DD")}
															</h2>
														</TableCell>

														<TableCell align='center'>
															<h2
																className='md:text-[18px] text-[16px] font-normal'
																style={{ color: "#4D4F5C", margin: "0 auto" }}>
																{row?.total_price}
															</h2>
														</TableCell>

														<TableCell align='center'>
															<h2
																className='md:text-[18px] text-[16px] font-normal'
																style={{ color: "#4D4F5C", margin: "0 auto" }}>
																{row?.quantity}
															</h2>
														</TableCell>

														<TableCell align='center'>
															<div
																className='w-[13rem] h-full py-[2px] rounded-2xl'
																style={{
																	backgroundColor:
																		row?.status === "جديد"
																			? "#ffd08fa6"
																			: row?.status === "مكتمل"
																			? "#30dd6b29"
																			: row?.status === "ملغي"
																			? "#ff00002b"
																			: row?.status ===
																			  "طلب مندوب لتسليم الشحنة"
																			? "#a8c0f2"
																			: row?.status === "قيد التجهيز"
																			? "#ededed"
																			: null,
																	margin: "0 auto",
																}}>
																<h2
																	className='md:text-[16px] text-[14px]'
																	style={{
																		color:
																			row?.status === "جديد"
																				? "#ff9f1a"
																				: row?.status === "مكتمل"
																				? "#3ae374"
																				: row?.status === "ملغي"
																				? "red"
																				: row?.status ===
																				  "طلب مندوب لتسليم الشحنة"
																				? "#826fa2"
																				: row?.status === "قيد التجهيز"
																				? "#ff9f1a"
																				: null,
																	}}>
																	{row?.status}
																</h2>
															</div>
														</TableCell>

														<TableCell align='center'>
															<h2
																className='md:text-[18px] text-[16px] font-normal'
																style={{ color: "#4D4F5C", margin: "0 auto" }}>
																{translateCityName(row?.OrderAddress?.city)}
															</h2>
														</TableCell>

														<TableCell align='right' className='max-w-[240px]'>
															<div className='flex flex-row items-center justify-end gap-3'>
																<h2
																	className='w-full md:text-[18px] text-[16px] font-normal overflow-hidden whitespace-nowrap'
																	style={{
																		color: "#4D4F5C",

																		textOverflow: "ellipsis",
																		direction: "rtl",
																	}}>
																	{row?.store?.store_name}
																</h2>

																<div
																	className='img_icons'
																	style={{
																		minWidth: "35px",
																		minHeight: "35px",
																		maxWidth: "35px",
																		maxHeight: "35px",
																	}}>
																	<img
																		src={
																			row?.store?.logo
																				? row?.store?.logo
																				: PlaceholderImage
																		}
																		alt={""}
																		loading='lazy'
																	/>
																</div>
															</div>
														</TableCell>

														<TableCell align='center'>
															<h2
																className='md:text-[18px] text-[16px] font-normal'
																style={{ color: "#4D4F5C", margin: "0 auto" }}>
																{row?.order_number}
															</h2>
														</TableCell>
														<TableCell
															className='md:text-[18px] text-[16px]'
															align='center'
															style={{ color: "#4D4F5C" }}>
															{(index + 1).toLocaleString("en-US", {
																minimumIntegerDigits: 2,
																useGrouping: false,
															})}
														</TableCell>
														<TableCell padding='none' align={"right"}>
															<Checkbox
																checkedIcon={<CheckedSquare />}
																sx={{
																	color: "#011723",
																	"& .MuiSvgIcon-root": {
																		color: "#011723",
																	},
																}}
																checked={isItemSelected}
																onClick={(event) => handleClick(event, row.id)}
																inputProps={{
																	"aria-labelledby": labelId,
																}}
															/>
														</TableCell>
													</TableRow>
												);
											}
										)
									)}
									{emptyRows > 0 && (
										<TableRow
											style={{
												height: 53 * emptyRows,
											}}>
											<TableCell colSpan={6} />
										</TableRow>
									)}
								</Fragment>
							)}
						</TableBody>
					</Table>
				</TableContainer>
			</Paper>
			{notificationTitle && (
				<DeleteModalComp handleDeleteAllItems={importOrdersDeleteAllHandel} />
			)}

			<NewPagination
				pageNumber={pageNumber}
				setPageNumber={setPageNumber}
				currentPage={currentPage}
				setCurrentPage={setCurrentPage}
				page_count={page_count}
				search={search}
			/>
		</Box>
	);
}
