import React, { useContext, useState } from "react";
import styles from "./AddVariety.module.css";
/* Third party */
import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";
import ImageUploading from "react-images-uploading";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
/* Contexts */
import Context from "../../../../store/context";
import { LoadingContext } from "../../../../store/LoadingProvider";
/* Custom Button */
import Button from "../../../../UI/Button/Button";
/* Icons */
import { AiFillStar } from "react-icons/ai";
import { IoMdCloudUpload } from "react-icons/io";
import { ActionAdd, Delete, DeleteIconCircle } from "../../../../assets/Icons";
import AddSubVariety from "../AddSubVariety/AddSubVariety";
import { useAddNewCategoryMutation } from "../../../../RTK/apiSlices/categoriesApi";

const BackDrop = ({ onClick }) => {
	return (
		<div
			onClick={onClick}
			className={`fixed back_drop bottom-0 left-0  w-full bg-slate-900  z-10 ${styles.back_drop}`}
			style={{ height: "calc(100% - 4rem)" }}></div>
	);
};

const AddVariety = () => {
	const navigate = useNavigate();

	const contextStore = useContext(Context);
	const {
		subCategories,
		setSubCategories,
		showAddSubVariety,
		setShowAddSubVariety,
	} = contextStore;
	const LoadingStore = useContext(LoadingContext);
	const { setLoadingTitle } = LoadingStore;

	// upload new image
	const [images, setImages] = useState([]);
	const maxNumber = 2;
	// handle images size
	const maxFileSize = 1 * 1024 * 1024; // 1 MB;
	const onChange = (imageList, addUpdateIndex) => {
		// Check image size before updating state
		const isSizeValid = imageList.every(
			(image) => image.file.size <= maxFileSize
		);

		if (!isSizeValid) {
			setDataError({
				...DataError,
				icon: " حجم الصورة يجب أن لا يزيد عن 1 ميجابايت.",
			});
			toast.warning(" حجم الصورة يجب أن لا يزيد عن 1 ميجابايت.", {
				theme: "light",
			});
		} else {
			setImages(imageList);
			setDataError({ ...DataError, icon: null });
		}
	};

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm({
		mode: "onBlur",
		defaultValues: {
			name: "",
		},
	});

	const [DataError, setDataError] = useState({
		name: "",
		icon: "",
	});
	const resetData = () => {
		setDataError({
			name: "",
			icon: "",
		});
	};

	const [addNewCategory] = useAddNewCategoryMutation();

	const addNewCategoryHandel = async (formData) => {
		try {
			const res = await addNewCategory({
				body: formData,
			});
			if (res?.data?.success === true && res?.data?.data?.status === 200) {
				setLoadingTitle(null);

				setSubCategories([]);
				navigate("/categories_activities");
			} else {
				setLoadingTitle(null);

				setDataError({
					name: res?.data?.message?.en?.name?.[0],
					icon: res?.data?.message?.en?.icon?.[0],
				});
				toast.error(res?.data?.message?.en?.name?.[0], { theme: "light" });
				toast.error(res?.data?.message?.en?.icon?.[0], { theme: "light" });
			}
		} catch (error) {
			console.error(error);
		}
	};

	const handleVariety = (dataVariety) => {
		resetData();
		let formData = new FormData();
		formData.append("name", dataVariety?.name);
		if (images.length !== 0) {
			formData.append("icon", images[0]?.file || null);
		}

		// to select all subcategories names
		for (let i = 0; i < subCategories?.length; i++) {
			if (subCategories[i]?.name !== "") {
				formData.append([`data[${i}][name]`], subCategories[i]?.name);
				formData.append([`data[${i}][id]`], subCategories[i]?.id || "");
			}
		}
		setLoadingTitle("جاري حفظ  الأنشطة");
		addNewCategoryHandel(formData);
	};

	const updateSubCatChanged = (e, index) => {
		const newArray = subCategories?.map((item, i) => {
			if (index === i) {
				return { ...item, name: e.target.value };
			} else {
				return item;
			}
		});
		setSubCategories(newArray);
	};

	return (
		<>
			<Helmet>
				<title>لوحة تحكم أطلبها | اضافة نشاط أو تصنيف</title>
			</Helmet>
			<BackDrop
				onClick={() => {
					navigate("/categories_activities");
					setSubCategories([]);
				}}></BackDrop>
			{showAddSubVariety && (
				<AddSubVariety
					cancel={() => {
						setShowAddSubVariety(false);
					}}
				/>
			)}
			<div
				className={`fixed bottom-0 left-0 bg-slate-50 z-30 otlobha_new_product ${styles.container}`}
				style={{
					width: "1104px",
					maxWidth: "100%",
					height: "calc(100% - 4rem)",
				}}>
				<form onSubmit={handleSubmit(handleVariety)} className='h-full'>
					<div className='flex flex-col justify-between h-full'>
						<div
							className='flex flex-col md:p-[30px] px-5 py-7 gap-[10px] pt-24'
							style={{
								backgroundColor: "rgba(235, 235, 235, 1)",
							}}>
							<h2
								style={{ color: "#011723" }}
								className='md:text-[24px] text-[20px] font-bold'>
								اضافة نشاط أو تصنيف
							</h2>
							<p
								style={{ color: "#011723" }}
								className='md:text-[20px] text-[16px] font-normal'>
								أضف نشاط أو تصنيف جديد
							</p>
						</div>
						<div
							style={{ backgroundColor: "#F6F6F6" }}
							className={`flex-1 flex flex-col gap-8 overflow-y-scroll md:py-[40px] md:pr-[30px] md:pl-40 p-5 ${styles.content}`}>
							<div className='flex md:flex-row flex-col items-start gap-y-[10px]'>
								<h2
									style={{ color: "#011723" }}
									className='md:text-[20px] text-[18px] w-96 max-w-full font-medium whitespace-nowrap'>
									رمز النشاط<span className='text-red-500'>*</span>
								</h2>
								<div className='flex flex-col w-full gap-2 md:w-auto'>
									<div className='flex flex-col'>
										<ImageUploading
											value={images}
											onChange={onChange}
											maxNumber={maxNumber}
											dataURLKey='data_url'
											acceptType={["jpg", "png", "jpeg", "webp"]}>
											{({ onImageUpload, dragProps }) => (
												// write your building UI
												<div
													className='md:w-[555px] w-full md:h-[170px] h-[100px] upload__image-wrapper relative overflow-hidden'
													style={{
														width: "555px",
														height: "170px",
														border: images[0] ? "none" : "3px dashed #ccc",
														borderRadius: "10px",
													}}
													onClick={() => {
														onImageUpload();
													}}
													{...dragProps}>
													<div className='w-full h-full cursor-pointer image-item'>
														{!images[0] && (
															<div className='flex flex-col items-center justify-center w-full h-full gap-2'>
																<IoMdCloudUpload size={"2em"}></IoMdCloudUpload>
																<h2 className='font-semibold'>
																	اسحب الصورة هنا
																</h2>
																<div className='flex flex-col items-center gap-1'>
																	<h2 className='mb-0'>
																		(سيتم قبول الصور png & jpg & jpeg & webp)
																	</h2>
																	<span className='text-gray-400 font-light text-[14px]'>
																		(الحد الأقصى للصورة 1MB)
																	</span>
																</div>
															</div>
														)}
														{images[0] && (
															<img
																src={images[0]?.data_url}
																alt=''
																className='object-contain w-full h-full'
																loading='lazy'
															/>
														)}
													</div>
												</div>
											)}
										</ImageUploading>
										<span className='text-red-500 text-[16px]'>
											{DataError?.icon}
										</span>
									</div>
									{images[0] && (
										<div className='md:w-[85px] w-[55px] md:h-[80px] h-[50px]flex flex-col relative'>
											<img
												className='w-full h-full'
												src={images[0]?.data_url}
												alt='img'
												loading='lazy'
											/>
											<DeleteIconCircle className='md:w-[24px] w-[20px] absolute top-1 left-1 cursor-pointer' />
										</div>
									)}
								</div>
							</div>
							<div className='flex md:flex-row flex-col items-center gap-y-[10px]'>
								<div className='flex flex-row items-center w-full gap-3 md:w-auto'>
									<AiFillStar
										style={{
											display: "inline-block",
											marginLeft: "0.5rem",
											color: "red",
										}}></AiFillStar>
									<label
										style={{ color: "#011723" }}
										className='md:text-[20px]  text-[18px] w-80 max-w-full font-medium whitespace-nowrap'>
										رقم النشاط
									</label>
								</div>
								<input
									className={`${styles.variety_number} w-full rounded-md px-5 py-4 outline-none`}
									style={{
										color: "#7C7C7C",
										backgroundColor: "#02466A00",
										border: "1px solid #A7A7A7",
									}}
									placeholder='تلقائي'
									type='text'
									disabled
									value={"تلقائي"}
								/>
							</div>
							<div className='flex md:flex-row flex-col items-center gap-y-[10px]'>
								<div className='flex flex-row items-center w-full gap-3 md:w-auto'>
									<AiFillStar
										style={{
											display: "inline-block",
											marginLeft: "0.5rem",
											color: "red",
										}}></AiFillStar>
									<label
										style={{ color: "#011723" }}
										className='md:text-[20px] text-[18px] w-80 max-w-full font-medium whitespace-nowrap'>
										النشاط الرئيسي
									</label>
								</div>
								<div className='flex flex-col w-full'>
									<input
										className='w-full px-5 py-4 rounded-md outline-none'
										style={{
											color: "#011723",
											backgroundColor: "#02466A00",
											border: "1px solid #A7A7A7",
										}}
										placeholder='ادخل اسم النشاط الرئيسي'
										type='text'
										name='name'
										{...register("name", {
											required: "حقل الاسم مطلوب",
											pattern: {
												value: /^[^-\s][\u0600-\u06FF-A-Za-z ]+$/i,
												message: "يجب ان يتكون الاسم من أحرف",
											},
										})}
									/>
									<span className='text-red-500 text-[16px]'>
										{DataError?.name}
										{errors?.name && errors.name.message}
									</span>
								</div>
							</div>
							{subCategories &&
								subCategories?.map(
									(subCategory, index) =>
										subCategory?.name && (
											<div
												className='flex md:flex-row flex-col md:items-center items-start gap-y-[10px]'
												key={index}>
												<div className='flex flex-row items-center md:mr-10'>
													<label
														style={{ color: "#1DBBBE" }}
														className='md:text-[20px] text-[18px] w-80 max-w-full font-medium whitespace-nowrap'>
														فرعي رقم {index + 1}
													</label>
												</div>
												<div className='flex flex-row items-center w-full gap-4'>
													<input
														type='text'
														className='w-full px-5 py-4 rounded-md outline-none'
														style={{
															color: "#1DBBBE",
															backgroundColor: "#02466A00",
															border: "1px solid #1DBBBE",
														}}
														value={subCategory?.name}
														onChange={(e) => updateSubCatChanged(e, index)}
													/>

													<Delete
														onClick={() => {
															setSubCategories((subCategories) => [
																...subCategories.filter(
																	(sub) => sub?.name !== subCategory?.name
																),
															]);
														}}
														className='cursor-pointer'
														fill='#FF3838'
													/>
												</div>
											</div>
										)
								)}
							<div className='flex flex-row items-center justify-end'>
								<div
									className='flex flex-row items-center justify-center gap-4 px-5 py-4 rounded-md outline-none cursor-pointer'
									style={{
										width: "555px",
										backgroundColor: "#FFFFFF00",
										border: "1px dashed #A7A7A7",
									}}
									onClick={() => setShowAddSubVariety(true)}>
									<ActionAdd fill='#67747B' />
									<span style={{ color: "#67747B", fontSize: "16px" }}>
										اضافة نشاط أو تصنيف فرعي جديد
									</span>
								</div>
							</div>
						</div>
						<div className='md:p-8 p-5 md:h-[135px] md-[110px] md:bg-[#ebebeb] bg-[#F6F6F6] flex justify-center gap-4'>
							<Button
								btnType={"submit"}
								style={{
									backgroundColor: `rgba(2, 70, 106, 1)`,
									width: "280px",
									height: "56px",
								}}
								textStyle={{ color: "#EFF9FF", fontSize: "20px" }}
								type={"normal"}>
								حفظ
							</Button>
							<Button
								onClick={() => {
									navigate("/categories_activities");
									setSubCategories([]);
								}}
								style={{
									borderColor: `rgba(2, 70, 106, 1)`,
									width: "280px",
									height: "56px",
								}}
								textStyle={{ color: "rgba(2, 70, 106, 1)", fontSize: "20px" }}
								type={"outline"}>
								إلغاء
							</Button>
						</div>
					</div>
				</form>
			</div>
		</>
	);
};

export default AddVariety;
