import { TabList } from "@mui/lab";
import { Box, Tab } from "@mui/material";
import React from "react";

const tabStyles = {
	"& .MuiButtonBase-root": {
		minWidth: "180px",

		height: "56px",
		backgroundColor: "rgb(236, 236, 236)",
		ml: "1rem",
		borderRadius: "0.25rem",
		color: "rgb(1, 23, 35)",
		boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
		transition: "0.1s",
		whiteSpace: "nowrap",
		"@media(max-width:992px) ": {
			minWidth: "150px",
		},
	},
	"& .MuiButtonBase-root.Mui-selected": {
		backgroundColor: "rgb(94, 191, 242)",
		color: "rgb(1, 23, 35)",
	},
	"& .MuiTabs-indicator": {
		display: "none",
	},
	"& .MuiTabs-flexContainer": {
		overflow: "auto",
	},
};

const PartitionsTabs = ({ onChangeTab, sections }) => {
	return (
		<Box
			sx={{
				"& .MuiTabs-flexContainer": {
					pb: "0.5rem",
				},
				borderBottom: 1,
				borderColor: "divider",
				pb: "0.4rem",
			}}>
			<TabList sx={tabStyles} onChange={onChangeTab}>
				{sections?.map((section, index) => (
					<Tab
						key={index}
						disableRipple={true}
						className='text-lg font-medium'
						label={section?.name}
						value={index.toString()}
					/>
				))}
			</TabList>
		</Box>
	);
};

export default PartitionsTabs;
