import React, { useContext, useEffect, useState, useRef } from "react";

// Third party

import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
// TO print this page
import ReactToPrint from "react-to-print";

// Components
import OrderInfo from "./OrderInfo";
import ClientInfo from "./ClientInfo";
import AddStoreAddress from "./AddStoreAddress";
import OrderProductsInfo from "./OrderProductsInfo";
import CircularLoading from "../../../../UI/CircularLoading/CircularLoading";

// Icons
import { GoArrowRight } from "react-icons/go";
import { IoMdPrint } from "react-icons/io";

// Context
import { LoadingContext } from "../../../../store/LoadingProvider";

// RTK Query
import { useShippingCitiesDataQuery } from "../../../../RTK/apiSlices/selectorApi";
import {
	useShowImportOrderDataQuery,
	useUpdateImportOrderMutation,
} from "../../../../RTK/apiSlices/talabatEtlobhaApi";
import ChangeOrderStatus from "./ChangeOrderStatus";
/** ---------------------------------------------------------------- */

const SouqAtlbhaOrdersDetails = () => {
	const componentRef = useRef();
	const navigate = useNavigate();
	const { id: souqAtlbhaOrderId } = useParams();
	const { data: fetchedData, isLoading: loading } = useShowImportOrderDataQuery(
		{ souqAtlbhaOrderId }
	);

	// Handle print sticker Function
	const printSticker = () => {
		window.open(fetchedData?.orders?.shipping?.sticker, "_blank");
	};

	// to handle set date rang of shipping
	const [value, setValue] = useState(null);
	const { data: shippingCities } = useShippingCitiesDataQuery();
	// --------------------------------------------------------------------

	const LoadingStore = useContext(LoadingContext);
	const { setLoadingTitle } = LoadingStore;

	// sipping details
	const [shipping, setShipping] = useState({
		city: "",
		district: "",
		street_address: "",
	});

	// handleOnChangeShipping function
	const handleOnChangeShipping = (e) => {
		const { name, value } = e.target;
		setShipping((prevState) => {
			return { ...prevState, [name]: value };
		});
	};
	// -------------------------------------------------------------

	// Shipping Errors
	const [shippingErrors, setShippingErrors] = useState({
		city: "",
		district: "",
		street_address: "",
		status: "",
		pickup_date: "",
	});

	const resetShippingError = () => {
		setShippingErrors({
			city: "",
			district: "",
			street_address: "",
			pickup_date: "",
		});
	};

	function removeDuplicates(arr) {
		const unique = arr?.filter((obj, index) => {
			return (
				index ===
				arr?.findIndex((o) => obj?.region?.name_en === o?.region?.name_en)
			);
		});
		return unique;
	}
	/** ----------------------------------------------------------- */

	// To handle the shipping information
	useEffect(() => {
		if (fetchedData?.orders) {
			setShipping({
				...shipping,
				district: fetchedData?.orders?.shipping?.district,
				city: fetchedData?.orders?.shipping?.city,
				address: fetchedData?.orders?.shipping?.street_address,
				status: fetchedData?.orders?.status,
			});
		}
	}, [fetchedData?.orders]);

	// ----------------------------------------------------------
	function translateCityName(name) {
		const unique = shippingCities?.cities?.find((obj) => obj?.name_en === name);
		return unique?.name || name;
	}

	function translateProvinceName(name) {
		const unique = shippingCities?.cities?.find((obj) => {
			return obj?.region?.name_en === name;
		});

		return unique?.region?.name || name;
	}

	const getCityFromProvince =
		shippingCities?.cities?.filter(
			(obj) => obj?.region?.name_en === shipping?.district
		) || [];
	// ---------------------------------------------------

	// To handle update order Status
	const [updateImportOrder] = useUpdateImportOrderMutation();
	const updateImportOrderHandel = async (formData) => {
		try {
			const res = await updateImportOrder({
				souqAtlbhaOrderId,
				body: formData,
			});
			if (res?.data?.success === true && res?.data?.data?.status === 200) {
				setLoadingTitle("");

				navigate("/talabat_souq_atlbha/");
			} else {
				setLoadingTitle("");

				setShippingErrors({
					status: res?.data?.message?.en?.status?.[0],
					pickup_date: res?.data?.message?.en?.pickup_date?.[0],
				});
				toast.error(res?.data?.message?.ar, {
					theme: "light",
				});

				toast.error(res?.data?.message?.en?.status?.[0], {
					theme: "light",
				});
				toast.error(res?.data?.message?.en?.pickup_date?.[0], {
					theme: "light",
				});
			}
		} catch (error) {
			console.error(error);
		}
	};

	const updateOrderStatus = () => {
		setLoadingTitle("جاري تعديل حالة الطلب");
		resetShippingError();

		// Format the date as a timestamp with timezone offset
		const formattedDate = value ? formatDateAsTimestamp(value) : null;

		let formData = new FormData();
		formData.append("_method", "PUT");
		formData.append("city", shipping?.city);
		formData.append("status", shipping?.status);
		formData.append("street_address", shipping?.address);

		if (
			shipping?.status !== "completed" &&
			shipping?.status !== "canceled" &&
			fetchedData?.orders?.shippingtypes?.name !== "اخرى"
		) {
			formData.append("pickup_date", formattedDate);
		}
		updateImportOrderHandel(formData);
	};

	// Add this helper function
	const formatDateAsTimestamp = (date) => {
		const timestamp = date.getTime();
		const offset = date.getTimezoneOffset();
		const offsetHours = Math.abs(Math.floor(offset / 60))
			.toString()
			.padStart(2, "0");
		const offsetMinutes = Math.abs(offset % 60)
			.toString()
			.padStart(2, "0");
		const offsetSign = offset > 0 ? "-" : "+";

		return `${timestamp}${offsetSign}${offsetHours}${offsetMinutes}`;
	};

	return (
		<>
			<Helmet>
				<title>لوحة تحكم أطلبها | تفاصيل الطلب</title>
			</Helmet>

			{loading ? (
				<div className='flex items-center justify-center w-full h-screen'>
					<CircularLoading />
				</div>
			) : (
				<div className='w-full' ref={componentRef}>
					<div className='flex flex-col justify-between h-full'>
						<div className='px-5 mb-5 md:pt-12 md:p-8'>
							<div className='flex flex-wrap items-center justify-between '>
								<div className='mb-4 md:mb-0'>
									<h2 className='font-bold md:text-[22px] text-[20px] text-right mb-3'>
										تفاصيل الطلب
									</h2>
									<div className='flex flex-row items-center'>
										<div
											onClick={() => {
												navigate("/talabat_souq_atlbha");
											}}
											className={
												"flex items-center gap-2 cursor-pointer  hide-me-in-print-media"
											}>
											<div
												className='flex flex-col items-center justify-center'
												style={{
													width: "30px",
													height: "30px",
													borderRadius: "50%",
													backgroundColor: "#02466A1A",
												}}>
												<GoArrowRight
													style={{ color: "#02466A", fontSize: "1.2rem" }}
												/>
											</div>
											<h2 className='ml-4 md:text-[18px] text-[16px] '>
												{" "}
												طلبات سوق اطلبها{" "}
											</h2>
										</div>
										<h3
											className='md:text-[18px] text-[16px]'
											style={{ color: "#67747B" }}>
											/ طلب #{fetchedData?.orders?.order_number}
										</h3>
									</div>
								</div>
							</div>
						</div>

						<section className='flex-1'>
							{/* order details */}
							<OrderInfo order={fetchedData?.orders} />
							{/* products details */}
							<OrderProductsInfo order={fetchedData?.orders} />
							{/* clint-details */}
							<ClientInfo
								order={fetchedData?.orders}
								translateCityName={translateCityName}
								getCityFromProvince={getCityFromProvince}
								translateProvinceName={translateProvinceName}
							/>
							{/* add Store address */}
							<div className='mb-4 mb-md-5'>
								<AddStoreAddress
									shipping={shipping}
									value={value}
									setValue={setValue}
									error={shippingErrors}
									resetError={resetShippingError}
									setShipping={setShipping}
									currentOrder={fetchedData}
									removeDuplicates={removeDuplicates}
									shippingCitiesData={shippingCities}
									getCityFromProvince={getCityFromProvince}
									translateProvinceName={translateProvinceName}
								/>
							</div>

							<div className='p-4 mb-8 bg-white shadow-sm rounded-xl box-card hide-me-in-print-media'>
								<div className='flex flex-col items-center justify-start w-full gap-2 md:flex-row md:items-end wrapper mb-4'>
									{fetchedData?.orders?.status === "جديد" ||
									fetchedData?.orders?.status === "قيد التجهيز" ||
									fetchedData?.orders?.status === "طلب مندوب لتسليم الشحنة" ? (
										<ChangeOrderStatus
											shipping={shipping}
											order={fetchedData?.orders}
											shippingErrors={shippingErrors}
											updateOrderStatus={updateOrderStatus}
											handleOnChangeShipping={handleOnChangeShipping}
										/>
									) : null}
								</div>
								<div className='flex flex-col items-center justify-start w-full gap-2 md:flex-row md:items-end wrapper'>
									{/* print order docs  */}
									<div className='flex-1 w-full'>
										<ReactToPrint
											trigger={() => {
												return (
													<button className=' cursor-pointer flex justify-center items-center gap-2 text-lg font-medium  w-full h-10 md:h-14  text-white bg-[#1DBBBE] py-2 p-3 rounded-md '>
														<span>طباعة الطلب</span>
														<IoMdPrint className='w-6 h-6 text-white' />
													</button>
												);
											}}
											content={() => componentRef.current}
											documentTitle='order-details-report'
											bodyClass='souq-order-details'
										/>
									</div>

									{fetchedData?.orders?.shipping?.sticker &&
										fetchedData?.orders?.status !== "ملغي" && (
											<div className='flex-1 w-full'>
												<div className=' text-sm text-start text-red-500'>
													اقصى مدة لطباعة البوليصه 24 ساعة في شركة ارامكس
												</div>
												<button
													onClick={() => printSticker()}
													className=' cursor-pointer flex justify-center items-center gap-2 text-lg font-medium  w-full h-10 md:h-14  text-white bg-[#1DBBBE] py-2 p-3 rounded-md '>
													<span>طباعة بوليصة الشحن</span>
													<IoMdPrint className='w-6 h-6 text-white' />
												</button>
											</div>
										)}
								</div>
							</div>
						</section>
					</div>
				</div>
			)}
		</>
	);
};

export default SouqAtlbhaOrdersDetails;
