import { MenuItem, Select } from "@mui/material";
import React from "react";
import { IoIosArrowDown } from "react-icons/io";

const selectStyles = {
	height: "54px",

	backgroundColor: "#fff",
	border: "1px solid #e2e2e2",
	color: "#000",
	width: "100%",
	pl: "1rem",
	"& .MuiOutlinedInput-notchedOutline": {
		border: "none",
	},
	"& .MuiOutlinedInput-input": {
		paddingRight: "0.7rem !important",
	},

	"&.Mui-focused .MuiOutlinedInput-notchedOutline": {
		border: "none",
	},
};

const selectItemStyles = {
	backgroundColor: "#fff",
	color: "#000",
	height: "3rem",

	"&:hover": {
		backgroundColor: "#dedede",
	},
	"ul:has(&)": {
		padding: "0",
	},
};

const SelectPartitionCategory = ({
	currPartition,
	onChangeTab,
	partitions,
	sectionCategoriesSelect,
}) => {
	const usedCategories = partitions
		.filter((partition) => partition.id !== currPartition.id)
		.map((partition) => partition.category_id);

	return (
		<div className='mb-5'>
			<h2 className='mb-1 text-lg font-normal' style={{ color: "#000" }}>
				اختر النشاط
			</h2>

			<Select
				displayEmpty
				sx={selectStyles}
				name='category_id'
				className={"w-full px-3 h-14 rounded outline-none"}
				value={currPartition?.category_id || ""}
				onChange={(e) =>
					onChangeTab({
						...currPartition,
						category_id: e.target.value,
					})
				}
				IconComponent={() => {
					return <IoIosArrowDown size={"1rem"} className='text-[#000]' />;
				}}
				renderValue={(selected) => {
					if (!selected || selected === "" || selected === undefined) {
						return <h2 className='text-[#000]'> اختر النشاط</h2>;
					}
					const result = sectionCategoriesSelect?.find(
						(item) => item?.id === parseInt(selected)
					);

					return result?.name;
				}}>
				{sectionCategoriesSelect?.map((item, index) => {
					const isUsed = usedCategories.includes(item.id);
					const isCurrentPartition = item.id === currPartition?.category_id;

					return (
						<MenuItem
							disabled={isUsed && !isCurrentPartition}
							key={index}
							sx={selectItemStyles}
							value={item?.id}>
							{item?.name}
						</MenuItem>
					);
				})}
			</Select>
		</div>
	);
};

export default SelectPartitionCategory;
